<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" :content="AddorEdit">
            </el-page-header>
        </div>
        <div class="center-content">
            <div class="iq-card-body">
                <div class="from-box">
                    <el-form ref="videosForm" :model="videosForm" :rules="rules" label-width="150px">
                        <el-form-item label="视频名称" prop="name">
                            <el-input type="textarea" v-model="videosForm.name" maxlength="50" show-word-limit>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="视频来源" prop="source">
                            <el-input type="text" v-model="videosForm.source">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="视频分类">
                            <el-cascader v-model="videosForm.categoryId" :options="videosCategory"
                                :props="{ checkStrictly: true, emitPath: false, value: 'id', label: 'name', children: 'children' }">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="视频类型">
                            <el-radio-group v-model="videosForm.isCharge">
                                <el-radio :label="false">免费</el-radio>
                                <el-radio :label="true">收费</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="会员价" v-if="videosForm.isCharge == true" prop="vipPrice">
                            <el-col :span="11">
                                <el-input type="number" class="w-18" v-model="videosForm.vipPrice" placeholder="会员价">
                                </el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="非会员价" v-if="videosForm.isCharge == true" prop="userPrice">
                            <el-col :span="11">
                                <el-input type="number" class="w-18" v-model="videosForm.userPrice" placeholder="非会员价">
                                </el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="权限设置">
                            <el-radio-group v-model="videosForm.limits">
                                <el-radio :label="0">完全公开</el-radio>
                                <el-radio :label="1">内部用户开放</el-radio>
                                <el-radio :label="2">私密发布</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="视频介绍">
                            <el-input type="textarea" v-model="videosForm.introduce"></el-input>
                        </el-form-item>
                        <el-form-item label="视频封面">
                            <el-upload :headers="headers" :action="FileUploadUrl" class="avatar-uploader" :show-file-list="false"
                                :on-success="handleAvatarSuccess" accept=".png,.jpg,.jpeg,.gif">
                                <img v-if="videosForm.cover" :src="videosForm.cover" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <div class="el-upload__tip" slot="tip">如果封面不上传，就默认视频的界面</div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="上传视频" style="margin-top:20px" prop="url">
                            <el-upload :headers="headers" class="avatar-uploader el-upload--text" action="" :show-file-list="false"
                                accept=".mp4" :before-upload="beforeUploadVideo">
                                <i v-if="videosForm.url == '' && percentage == 0"
                                    class="el-icon-plus avatar-uploader-icon"></i>
                                <el-progress v-if="percentage < 100 && percentage > 0" type="circle"
                                    :percentage="percentage"></el-progress>
                                <video v-if="videosForm.url && percentage == 100" :src="videosForm.url"
                                    class="avatar video-avatar" controls="controls"></video>

                            </el-upload>
                            <p v-if="isShowUploadVideo" class="text">请保证视频格式正确，且不超过20M</p>
                        </el-form-item>
                        <el-form-item class="btn-bottom">
                            <el-button @click="cancelBtn('videosForm')">取消</el-button>
                            <el-button type="primary" @click="saveBtn('videosForm')">保存</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Videos } from "../../../components/HospitalDomain/Videos/Videos";
import '../../../utils/aliyun-upload-sdk-1.5.3.min.js'
import '../../../utils/es6-promise.min.js'
import OSS from '../../../utils/aliyun-oss-sdk-6.17.1.min.js'
window.OSS = OSS;
export default {
    name: "AddVideos",
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + JSON.parse(sessionStorage.getItem(this.ClientId + "Token")).access_token,
      };
    },
  },
    data() {
        var videos = new Videos(this.TokenClient, this.Services.Healthy)
        return {
            VideosDomain: videos,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            userId: JSON.parse(sessionStorage.getItem('userInfo')).userId,
            AddorEdit: this.$route.query.title,
            id: this.$route.query.id, //列表页id
            collectionId: this.$route.query.collectionId,
            videosForm: {
                name: '',
                categoryId: 1,
                isCharge: false,
                vipPrice: 0,
                userPrice: 0,
                limits: 0,
                introduce: '',
                cover: '',
                url: '',
                source: "",
                organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
                videoType: 2,// 1个人 2机构  3总后台
            },
            FileUploadUrl: this.CommonFilePath,
            videosCategory: [],
            percentage: 0,
            //视频
            uploadUrl: "",//你要上传视频到你后台的地址
            isShowUploadVideo: false, //显示上传按钮
            videoPath: "", //视频地址
            rules: {
                name: [
                    { required: true, message: '请输入视频名称', trigger: 'blur' },
                ],
                vipPrice: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入正确的金额', trigger: 'blur' },
                ],
                userPrice: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入正确的金额', trigger: 'blur' },
                ],
                url: [
                    { required: true, message: '请上传视频地址', trigger: 'blur' },
                ],
            },
            uploader: null
        }
    },
    mounted() {
        this.LoadCategory();
        if (this.id) {
            this.LoadFrom()
        }
    },
    methods: {
        //视频
        //上传前回调
        beforeUploadVideo(file) {
            console.log(file)
            //上传文件的大小
            const isLt20M = file.size / 1024 / 1024 < 200;
            //'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb','video/mp4' 可支持上传的视频类型
            if (['video/mp4'].indexOf(file.type) == -1) {
                this.$message.error('请上传正确的视频格式');
                return false;
            }
            if (!isLt20M) {
                this.$message.error('上传视频大小不能超过200MB哦!');
                return false;
            }
            this.isShowUploadVideo = false;
            this.uploader = this.createUploader()
            var userData = '{"Vod":{}}'
            this.uploader.addFile(file, null, null, null, userData)
            this.uploader.startUpload()
        },
        createUploader() {
            let _this = this;
            var uploader = new AliyunUpload.Vod({
                userId: "1922508017966810",
                //上传到点播的地域，默认值为'cn-shanghai'，//eu-central-1，ap-southeast-1，更多信息请参见存储说明
                region: "cn-shanghai",
                //开始上传
                addFileSuccess: function (uploadInfo) {
                    console.log(uploadInfo)
                },
                onUploadstarted: function (uploadInfo) {
                    var that = this
                    let params = {
                        title: '视频名称',
                        fileName: uploadInfo.file.name
                    }
                    _this.VideosDomain.GetVideoCertificate(params, function (data) {
                        uploader.setUploadAuthAndAddress(uploadInfo, data.data.uploadAuth, data.data.uploadAddress, data.data.videoId);
                        _this.videoId = data.data.videoId
                    }, function (error) {
                        _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                    })
                    // }
                },
                //文件上传成功
                onUploadSucceed: function (uploadInfo) {
                    _this.videosForm.url = "http://video.qyyl365.com/" + uploadInfo.object
                    _this.$refs['videosForm'].clearValidate(['url'])
                },
                //文件上传失败
                onUploadFailed: function (uploadInfo, code, message) {
                    uploader.stopUpload();
                },
                //文件上传进度，单位：字节
                onUploadProgress: function (uploadInfo, totalSize, loadedPercent) {
                    _this.percentage = parseInt((loadedPercent * 100).toFixed(2))
                },
                //上传凭证过期
                onUploadTokenExpired: function (uploadInfo) {
                    console.log("1111111")
                },
                //全部文件上传结束
                onUploadEnd: function (uploadInfo) {

                }
            })
            return uploader;
        },
        goBack() {
            this.$router.go(-1);
        },
        handleAvatarSuccess(res, file) {
            this.videosForm.cover = `${res[0].fullUrl}`;
        },
        cancelBtn(formName) {
            this.$refs[formName].resetFields();
            this.$router.go(-1);
        },
        LoadCategory(success, error) {
            var _this = this;
            _this.VideosDomain.Categories(
                function (data) {
                    _this.videosCategory = data.data;
                    _this.videosForm.categoryId = _this.videosCategory[0].id
                },
                function (err) {
                    console.log(err);
                });
        },
        LoadFrom() {
            var _this = this;
            _this.VideosDomain.VideoDetail(_this.id,
                function (data) {
                    _this.videosForm = data.data;
                    _this.percentage = 100

                }, function (err) { console.log(err); });
        },
        editV() {
            var _this = this;
            _this.VideosDomain.EditVideos(_this.videosForm,
                function (data) {
                    _this.$confirm('视频修改成功', '提示', {
                        confirmButtonText: '再发一条',
                        cancelButtonText: '返回列表',
                        type: 'success'
                    }).then(() => {
                        _this.$refs[formName].resetFields();
                        _this.videosForm = {
                            name: '',
                            categoryId: 1,
                            isCharge: false,
                            vipPrice: 0,
                            userPrice: 0,
                            limits: 0,
                            introduce: '',
                            cover: '',
                            url: '',
                            source: "",
                            videoType: 2,
                            organizationId: _this.organizationId,
                        }
                        _this.videosForm.categoryId = _this.videosCategory[0].id
                        _this.percentage = 0
                    }).catch(() => {
                        _this.$router.go(-1);
                    });

                },
                function (err) {
                    console.log(err);
                });
        },
        addV() {
            var _this = this;
            _this.VideosDomain.AddVideos(_this.videosForm,
                function (data) {
                    _this.$confirm('视频提交成功，请耐心等待审核', '提示', {
                        confirmButtonText: '再发一条',
                        cancelButtonText: '返回列表',
                        type: 'success'
                    }).then(() => {
                        _this.$refs[formName].resetFields();
                        _this.videosForm = {
                            name: '',
                            categoryId: 1,
                            isCharge: false,
                            vipPrice: 0,
                            userPrice: 0,
                            limits: 0,
                            introduce: '',
                            cover: '',
                            url: '',
                            source: "",
                            videoType: 2,
                            organizationId: _this.organizationId,
                        }
                        _this.videosForm.categoryId = _this.videosCategory[0].id
                        _this.percentage = 0
                    }).catch(() => {
                        _this.$router.go(-1);
                    });
                },
                function (err) {
                    console.log(err);
                });
        },
        saveBtn(formName) {
            var _this = this;
            _this.videosForm.operationUserName = JSON.parse(sessionStorage.getItem('userInfo')).name
            _this.videosForm.organizationName = JSON.parse(sessionStorage.getItem('userInfo')).organizationName
            console.log(this.videosForm)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.collectionId) {
                        _this.videosForm.collectionId = _this.collectionId
                    }
                    if (_this.videosForm.id) {
                        if (_this.videoId) {
                            // 获取阿里云视频信息
                            _this.VideosDomain.GetVideoInfo(_this.videoId,
                                function (data) {
                                    _this.videosForm.aliyunVideoId = data.data.videoId;
                                    _this.videosForm.aliyunCoverUrl = data.data.coverURL ? data.data.coverURL : '';
                                    _this.videosForm.size = data.data.size;
                                    _this.videosForm.duration = data.data.duration;
                                    _this.videosForm.storageLocation = data.data.storageLocation;
                                    _this.videoId = ""
                                    _this.editV()
                                },
                                function (err) {
                                    console.log(err);
                                });
                        } else {
                            _this.editV()
                        }
                    } else {
                        if (_this.videoId) {
                            // 获取阿里云视频信息
                            _this.VideosDomain.GetVideoInfo(_this.videoId,
                                function (data) {
                                    _this.videosForm.aliyunVideoId = data.data.videoId;
                                    _this.videosForm.aliyunCoverUrl = data.data.coverURL ? data.data.coverURL : '';
                                    _this.videosForm.size = data.data.size;
                                    _this.videosForm.duration = data.data.duration;
                                    _this.videosForm.storageLocation = data.data.storageLocation;
                                    _this.videoId = ""
                                    _this.addV()
                                },
                                function (err) {
                                    console.log(err);
                                });
                        }

                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
    }
}
</script>

<style scoped>

.el-page-header {
    line-height: 73px;
    padding-left: 20px;
}

/*content*/


.from-box {
    position: relative;
    margin-top: 20px;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
    /*box-shadow:0px 10px 5px -10px #999;*/
}

.from-box .el-form {
    max-width: 800px;
    width: 80%;
}

.form-item {
    padding-top: 30px;

}

.btn-bottom {
    bottom: 0;
}
</style>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.video-avatar {
    width: 100%;
    min-height: 60vh;
}
</style>
