
let Message = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
* 获取组织机构消息通知
* @param {any} success
* @param {any} error
*/
    this.getAllMessage = function (pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/Message/Page';
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        if (keyWord) {
            url += `/${keyWord}`
        } else {
            url += '/%20'
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
                // analysisToken(TokenClient.Token().AccessToken);
            }, error);
    }
    this.getAllOrgMessage = function (pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/Message/Org';
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        if (keyWord) {
            url += `/${keyWord}`
        } else {
            url += '/%20'
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
                // analysisToken(TokenClient.Token().AccessToken);
            }, error);
    }
    /**
    * 删除消息通知
    * @param {any} PlatformId
    * @param {any} success
    * @param {any} error
    */
    this.DeleteMessage = function (PlatformId, success, error) {
        var url = ServiceBaseAddress + '/api/Message/' + PlatformId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
   * 添加所有消息通知
   *@param {any} title 标题
   *@param {any} content 内容
   *@param {any} organizationId 医院id
   *@param {any} createUserId 用户Id
   *@param {any} categoryId 分类：1：系统，2医院，3第三方
   *@param {any} publishes 用户s
   *@param {any} success
   *@param {any} error
   */
    this.AddMessage = function (title, content, atta, organizationId, createUserId, categoryId, publishes, success, error) {
        var url = ServiceBaseAddress + '/api/Message';
        var param = {
            title: title,
            content: content,
            atta: atta,
            organizationId: organizationId,
            createUserId: createUserId,
            categoryId: categoryId,
            publishes: publishes,
        };
        console.log(param);
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取指定组织机构的普通用户
     * @param {any} success
     * @param {any} error
     */
    this.GetOrganization = function (success, error) {
        var url = ServiceBaseAddress + '/api/User/Key/Users';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getAllUsers = function (organizationId, departmentId, isBusiness, success, error) {
        var url = ServiceBaseAddress + "/api/User/Key/Users/"
        if (organizationId) {
            url += organizationId
        } else {
            url += "0"
        }
        if (departmentId) {
            url += "/" + departmentId
        } else {
            url += "/" + "0"
        }
        url += "/" + isBusiness
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    this.getAllUsersByIds = function (orgs, success, error) {
        var url = ServiceBaseAddress + `/api/User/SubOrgUser/${orgs}`
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 消息详情
     * @param {any} messageId
     * @param {any} success
     * @param {any} error
     */
    this.GetMessageDetail = function (messageId, success, error) {
        var url = ServiceBaseAddress + '/api/Message/' + messageId;
        TokenClient.Get(url, false, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


}


export { Message }
