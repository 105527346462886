<template>
  <div class="height1">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="素材图片"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form action="#" class="searchbox" @submit.native.prevent>
              <input type="text" class="text search-input" v-model="Keyword" placeholder="输入关键词搜索..." @keydown="SearchInputKeydown" />
              <a class="search-link" href="javascript:;" @click="Search"><i class="el-icon-search"></i></a>
            </el-form>
          </div>
          <el-button type="primary" size="small" @click="addBtn()" plain>上传图片</el-button>
          <el-button type="primary" size="small" @click="deleteBtn()" plain>批量删除</el-button>
        </div>
        <div class="clearfix list-box">
          <el-checkbox-group v-model="checked" class="checkGroup">
            <div class="list" v-for="(item, index) in LibraryData" :key="index">
              <!--这个是可以勾选照片，以便后面删除功能的实现-->
              <div>
                <el-checkbox :key="item.id" :label="item.id"></el-checkbox>
                <div class="list-right-div">
                  <el-button type="text" @click="delImage(item.id)">删除图片</el-button>
                </div>
              </div>
              <!--关键代码，elementui中的组件-->
              <el-image :src="http + item.filePath" :preview-src-list="[http + item.filePath]"> </el-image>
              <div class="name">{{ item.fileName }}</div>
            </div>
          </el-checkbox-group>
        </div>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog title="上传图片" :visible.sync="dialogUpload">
      <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card" :limit="5" multiple :on-preview="handleImagePreview" :on-remove="handleImageRemove" :on-success="handleImageUpload" :file-list="ImageContentList">
        <i class="el-icon-plus"></i>
        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多上传5张，单张大小不超过20M</div>
      </el-upload>
      <el-dialog :visible.sync="Image.dialogVisible">
        <img width="100%" :src="Image.dialogImageUrl" alt="" />
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUpload = false">取 消</el-button>
        <el-button type="primary" @click="uploadBtn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { MaterialLibrary } from '../../../components/HospitalDomain/ShopMall/MaterialLibrary'

export default {
  name: 'AlbumDetail',
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + JSON.parse(sessionStorage.getItem(this.ClientId + "Token")).access_token,
      };
    },
  },
  data() {
    var materialLibrary = new MaterialLibrary(this.TokenClient, this.Services.Shopping)
    return {
      MaterialLibrary: materialLibrary,
      id: this.$route.query.id, //列表页id
      organizationId: this.$route.query.organizationId, //列表页id

      Keyword: '',
      checked: [],
      LibraryData: [],
      PageIndex: 1,
      PageTotal: 0,
      PageSize: 0,
      DataTotal: 0,
      http: this.CommonPath,
      dialogUpload: false,
      FileUploadUrl: this.CommonFilePath,
      Image: {
        //单击图片上传控件中的图片时，放大显示
        dialogVisible: false,
        dialogImageUrl: '',
      },
      ImageContent: [], //图片列表
      ImageContentList: [], //图片上传控件加载的数据
      fileManage: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    Search() {
      this.PageIndex = 1
      this.getList()
    },
    SearchInputKeydown(event) {
      if (event.keyCode == 13) {
        this.PageIndex = 1
        this.getList()
      }
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      _this.LibraryData = []
      _this.MaterialLibrary.LibraryFiles(
        _this.organizationId,
        _this.id,
        _this.PageIndex,
        _this.Keyword,
        function (data) {
          _this.LibraryData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    delImage(item) {
      var _this = this
      var params = item
      _this
        .$confirm('是否删除此张图片?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => {
          _this.MaterialLibrary.Remove(
            params,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.getList()
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => {})
    },
    handleImageUpload(response, file, fileList) {
      var _this = this
      response.forEach(function (file) {
        var imgarr = {
          filePath: file.completeFilePath,
          fileName: file.fileName,
          type: 0,
          materialLibraryId: _this.id,
          organizationId: _this.organizationId,
        }

        _this.ImageContent.push(imgarr)
      })
      _this.fileManage = _this.ImageContent
    },
    handleImagePreview(file) {
      this.Image.dialogImageUrl = file.url
      this.Image.dialogVisible = true
    },
    handleImageRemove(file, fileList) {
      var _this = this
      const filePath = file.response[0].completeFilePath
      //2.从pics数组中找到图片对应的索引值
      //形参‘x’，是数组pic里的每一项
      const i = _this.ImageContent.findIndex((x) => x.filePath == filePath)
      //调用数组的splice方法，把图片的信息对象从pics数组中移除
      _this.ImageContent.splice(i, 1)
      _this.fileManage = _this.ImageContent
    },
    addBtn() {
      this.dialogUpload = true
    },
    uploadBtn() {
      var _this = this
      _this.MaterialLibrary.UploadImg(
        _this.fileManage,
        function (data) {
          _this.$message({
            type: 'success',
            message: '添加成功!',
          })
          _this.dialogUpload = false
          _this.getList()
        },
        function (err) {}
      )
    },
    deleteBtn() {
      console.log(this.checked)
      var _this = this
      var params = this.checked
      if (params.length > 0) {
        _this
          .$confirm('确定批量删除选中图片?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
          .then(() => {
            _this.MaterialLibrary.Remove(
              params,
              function (data) {
                _this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
                _this.getList()
              },
              function (err) {
                console.log(err)
              }
            )
          })
          .catch(() => {})
      } else {
        _this.$message({
          type: 'error',
          message: '请选择需要删除的图片!',
        })
      }
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
/*content*/

.list-box {
  height: calc(100% - 90px);
  overflow: auto;
}
.list {
  width: 250px;
  float: left;
  margin: 20px;
}
.list .el-image {
  width: 250px;
  height: 250px;
  overflow: hidden;
}
.list-right-div {
  float: right;
}
.list-right-div .el-button--text {
  padding: 0 !important;
}

.name {
  margin-top: 8px;
  font-size: 14px;
}
</style>
<style>
.list .el-checkbox__label {
  display: none !important;
}
</style>
