<template>
  <div class="height1">
    <!--    头部    -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content flex-space-between">
      <div class="leftC">
        <div class="top1 flex-space-between">
          <div class="leftT">
            <div class="text-l">账户余额（元）：</div>
            <countTo :startVal="0.0" :endVal="indexData.balance" :duration="1000" :decimals="2"></countTo>
          </div>
          <div class="rightT flex-start">
            <div class="item1">
              <div class="num">{{ indexData.commodityNum }}</div>
              <div>商品数量</div>
            </div>
            <div class="item1">
              <div class="num">{{ indexData.backOrderNum }}</div>
              <div>待发货</div>
            </div>
            <div class="item1">
              <div class="num">0</div>
              <div>待开票数</div>
            </div>
          </div>
        </div>
        <div class="cTitle">店铺数据</div>
        <div class="top1 flex-start flex-wrap">
          <div class="item2">
            <div>成交金额</div>
            <div class="num">
              <countTo :startVal="0" :endVal="indexData.totalTransactionAmount" :duration="1000" :decimals="2"> </countTo>
            </div>
            <div>昨日 {{ indexData.yesterdayTransactionAmount }}</div>
          </div>
          <div class="item2">
            <div>成交订单数</div>
            <div class="num">
              <countTo :startVal="0" :endVal="indexData.totalOrdersNum" :duration="1000"> </countTo>
            </div>
            <div>昨日 {{ indexData.yesterdayOrdersNum }}</div>
          </div>
          <div class="item2">
            <div>买家数量</div>
            <div class="num">
              <countTo :startVal="0" :endVal="indexData.totalBuyNum" :duration="1000"> </countTo>
            </div>
            <div>昨日 {{ indexData.yesterdayBuyNum }}</div>
          </div>
          <div class="item2">
            <div>访问用户数</div>
            <div class="num">
              <countTo :startVal="0" :endVal="indexData.totalBuyNum" :duration="1000"> </countTo>
            </div>
            <div>昨日 {{ indexData.totalBuyNum }}</div>
          </div>
          <div class="item2">
            <div>店铺浏览量</div>
            <div class="num">
              <countTo :startVal="0" :endVal="indexData.totalBuyNum" :duration="1000"> </countTo>
            </div>
            <div>昨日 {{ indexData.totalBuyNum }}</div>
          </div>
          <div class="item2">
            <div>店铺收藏数</div>
            <div class="num">
              <countTo :startVal="0" :endVal="indexData.totalBuyNum" :duration="1000"> </countTo>
            </div>
            <div>昨日 {{ indexData.totalBuyNum }}</div>
          </div>
          <div class="item2">
            <div>店铺商品数</div>
            <div class="num">
              <countTo :startVal="0" :endVal="indexData.totalBuyNum" :duration="1000"> </countTo>
            </div>
            <div>昨日 {{ indexData.totalBuyNum }}</div>
          </div>
        </div>
        <div class="cTitle flex-space-between">
          <div>最新订单<i class="iconfont icon-shuaxin" style="margin-left: 5px" @click="LoadOrderList"></i></div>
          <div @click="toPage(2)">查看更多<i class="el-icon-arrow-right" style="margin-left: 5px"></i></div>
        </div>
        <div class="top1 flex-start flex-wrap">
          <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
            <el-table-column prop="sonOrderNumber" label="订单编号" width="210" fixed></el-table-column>
            <el-table-column label="商品图片" align="center" width="90">
              <template slot-scope="scope">
                <el-image hide-on-click-modal :src="httpContent + item.commodity.coverPicture[0].photoThumbnail" preview-teleported :zoom-rate="1.2" :preview-src-list="[httpContent + item.commodity.coverPicture[0].photoThumbnail]" v-for="(item, index) in scope.row.commodityOrder" :key="index" style="width: 50px; height: 50px; margin-right: 5px" fit="cover"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="orderprovos-box" v-for="(item, index) in scope.row.commodityOrder" :key="index">
                  {{ item.commodity.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="订单信息" width="130" align="center">
              <template slot-scope="scope">
                <div class="orderprovos-box" v-for="(item, index) in scope.row.commodityOrder" :key="index">￥{{ item.actualMoney }}*{{ item.commodityNum }}</div>
              </template>
            </el-table-column>
            <el-table-column label="快递费用" align="center" width="80px">
              <template slot-scope="scope">
                <div class="orderprovos-box" v-for="(item, index) in scope.row.commodityOrder" :key="index">
                  {{ item.freight }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="实付金额（元）" align="center">
              <template slot-scope="scope"> ￥{{ scope.row.payMoney }} </template>
            </el-table-column>
            <el-table-column prop="userName" label="下单用户" align="center"></el-table-column>
            <el-table-column prop="refundTime" label="下单时间" width="160px" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.createTime | timefilters }}</p>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" align="center" width="150">
              <template slot-scope="scope">
                <div class="orderprovos-box flex-space-between" v-for="(item, index) in scope.row.commodityOrder" :key="index">
                  <el-tag v-if="item.state == 0" type="warning" size="small">待付款</el-tag>
                  <el-tag v-if="item.state == 1" type="success" size="small">已完成</el-tag>
                  <el-tag v-if="item.state == 2" size="small">待发货</el-tag>
                  <el-tag v-if="item.state == 3" type="primary" size="small">已发货</el-tag>
                  <el-tag v-if="item.state == -1" type="info" size="small">已取消</el-tag>
                  <el-tag v-if="item.state == -10" type="danger" size="small">退款中</el-tag>
                  <el-tag v-if="item.state == -11" type="danger" size="small">退款完成</el-tag>
                  <el-tag v-if="item.state == -12" type="danger" size="small">取消退款</el-tag>
                  <!-- <el-tag type="success" v-if="item.state == 3 || item.state == 1" plain
                                    @click="showExpress(item)" size="small">查看物流</el-tag> -->
                  <template v-if="scope.row.expressType == 2">
                    <span class="warning optionBtn" v-if="item.state == 3 || item.state == 1" @click="showExpress(item, scope.row)">查看物流</span>
                  </template>
                  <template v-else>
                    <span class="warning optionBtn" v-if="item.state == 3 || item.state == 1" @click="showExpress(null, scope.row)">查看物流</span>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="rightC">
        <div class="item3" v-if="merchantInfo.state == 1">
          <div class="c1 flex-start">
            <img :src="MerchantData.cover" class="avatar" />
            <div class="rightTitle">
              <div class="title1 oneLine">{{ MerchantData.shopName }}</div>
              <div class="title2">ID:{{ MerchantData.shopNumber }}</div>
            </div>
          </div>
          <div class="customerPhone"><i class="icon-dianhua iconfont"></i>客服电话：{{ MerchantData.customerPhone || '暂无' }}</div>
          <div class="flex-space-between">
            <el-button type="warning" size="small" round class="setBtn" @click="setBtn" plain><i class="icon-shangdian iconfont"></i>店铺设置</el-button>
            <el-button type="info" size="small" round class="setBtn" @click="updateBtn" plain><i class="icon-shangdian iconfont"></i>变更资质</el-button>
          </div>
        </div>
        <div class="item3 flex-center" v-if="merchantInfo.state == 0">
          <el-button type="info" disabled size="small" round class="setBtn" @click="toPage(5)"><i class="icon-shangdian iconfont"></i>待审核</el-button>
        </div>
        <div class="item3 flex-center" v-if="merchantInfo.state == -1">
          <el-button type="success" size="small" round class="setBtn" @click="toPage(5)"><i class="icon-shangdian iconfont"></i>查看结果</el-button>
        </div>
        <div class="item3 flex-center" v-if="merchantInfo.state == null">
          <el-button type="primary" size="small" round class="setBtn" @click="toPage(5)"><i class="icon-shangdian iconfont"></i>店铺申请</el-button>
        </div>
        <div class="cTitle">常用功能</div>
        <div class="item4 flex-start flex-wrap">
          <div class="item text-c" @click="toPage(1)">
            <img src="@/assets/img/sg_icon01.png" />
            <div>商品管理</div>
          </div>
          <div class="item text-c" @click="toPage(2)">
            <img src="@/assets/img/sg_icon02.png" />
            <div>订单管理</div>
          </div>
          <div class="item text-c" @click="toPage(3)">
            <img src="@/assets/img/sg_icon03.png" />
            <div>运费模板</div>
          </div>
          <div class="item text-c" @click="toPage(1)">
            <img src="@/assets/img/sg_icon04.png" />
            <div>商品发布</div>
          </div>
          <div class="item text-c" @click="toPage(6)">
            <img src="@/assets/img/sg_icon04.png" />
            <div>订单评论</div>
          </div>
          <div class="item text-c" @click="toPage(4)">
            <img src="@/assets/img/sg_icon05.png" />
            <div>素材管理</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
import { Order } from '../../../components/HospitalDomain/ShopMall/Order'
import { Merchant } from '../../../components/HospitalDomain/ShopMall/Merchant'
import { Index } from '../../../components/HospitalDomain/Index'
import { MarketIndex } from '../../../components/HospitalDomain/marketIndex'
import bread from '../../../components/common/breadcrumb'

export default {
  name: 'ShopIndex',
  components: {
    bread,
    countTo,
  },
  data() {
    var order = new Order(this.TokenClient, this.Services.Shopping)
    var merchant = new Merchant(this.TokenClient, this.Services.Shopping)
    var index = new Index(this.TokenClient, this.Services.Authorization)
    var marketIndex = new MarketIndex(this.TokenClient, this.Services.Shopping)
    return {
      OrderDomain: order,
      MerchantDomain: merchant,
      IndexDomain: index,
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      MarketIndex: marketIndex,
      indexData: {
        balance: 0,
        commodityNum: 0,
        backOrderNum: 0,
        totalTransactionAmount: 0,
        yesterdayTransactionAmount: 0,
        totalOrdersNum: 0,
        yesterdayOrdersNum: 0,
        totalBuyNum: 0,
        yesterdayBuyNum: 0,
      },
      tableData: [],
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      MerchantData: {},
      merchantInfo: {},
      httpContent: this.CommonPath,
    }
  },
  mounted() {
    this.getMerchantSet()
    this.getBlance()
    this.getCommodityInfo()
    this.LoadOrderList()
    this.getDetail()
  },
  methods: {
    getMerchantSet() {
      var _this = this
      _this.merchantInfo = {}
      _this.MerchantDomain.MerchantState(
        function (data) {
          if (data.code == '200') {
            _this.merchantInfo = data.data
          }
        },
        function (err) {}
      )
    },
    getDetail() {
      var _this = this
      _this.MerchantDomain.MerchantDetail(
        _this.organizationId,
        function (data) {
          _this.MerchantData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    toPage(num) {
      var path = ''
      switch (num) {
        case 1:
          path = './commodity'
          break
        case 2:
          path = './Order/OwnOrder'
          break
        case 3:
          path = './Freight'
          break
        case 4:
          path = './Album'
          break
        case 5:
          path = './Apply'
          break
        case 6:
          path = '../Evaluate/Order'
          break
      }
      this.$router.push(path)
    },
    setBtn() {
      this.$router.push('./settings')
    },
    updateBtn() {
      this.$router.push('./changeApply')
    },
    LoadOrderList() {
      var _this = this
      _this.tableData = []
      _this.OrderDomain.OrderList(
        _this.organizationId,
        1,
        '',
        '',
        1,
        function (data) {
          _this.tableData = data.data.results
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getOrderState(val) {
      var res = ''
      val.forEach((item, i) => {
        switch (item.state) {
          case 0:
            res += `【待付款】`
            break
          case 1:
            res += `【已完成】`
            break
          case 2:
            res += `【待发货】`
            break
          case 3:
            res += `【已发货】`
            break
          case -1:
            res += `【已取消】`
            break
          case -2:
            res += `【已删除】`
            break
          case -999:
            res += `【退款】`
            break
        }
      })
      return res
    },
    getBlance() {
      var _this = this
      _this.IndexDomain.IndexData(
        _this.organizationId,
        function (data) {
          _this.indexData.balance = data.data.balance
        },
        function (error) {
          _this.indexData.balance = 0
        }
      )
    },
    getCommodityInfo() {
      var _this = this
      _this.MarketIndex.MarketIndexData(
        _this.organizationId,
        function (data) {
          _this.indexData.commodityNum = data.data.commodityNum
          _this.indexData.backOrderNum = data.data.backOrderNum
          _this.indexData.totalTransactionAmount = data.data.totalTransactionAmount
          _this.indexData.yesterdayTransactionAmount = data.data.yesterdayTransactionAmount
          _this.indexData.totalOrdersNum = data.data.totalOrdersNum
          _this.indexData.yesterdayOrdersNum = data.data.yesterdayOrdersNum
          _this.indexData.totalBuyNum = data.data.totalBuyNum
          _this.indexData.yesterdayBuyNum = data.data.yesterdayBuyNum
        },
        function (error) {
          _this.indexData.commodityNum = 0
          _this.indexData.backOrderNum = 0
          _this.indexData.totalTransactionAmount = 0
          _this.indexData.yesterdayTransactionAmount = 0
          _this.indexData.totalOrdersNum = 0
          _this.indexData.yesterdayOrdersNum = 0
          _this.indexData.totalBuyNum = 0
          _this.indexData.yesterdayBuyNum = 0
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.leftC {
  width: 80%;
  height: calc(100vh - 120px);
  overflow-y: auto;

  .cTitle {
    margin: 15px 15px 10px 15px;
    font-weight: bold;

    div {
      cursor: pointer;
    }
  }

  .top1 {
    background-color: #fff;
    padding: 15px;
    box-shadow: 0px 6px 8px 0px rgba(219, 223, 230, 0.15);
    border-radius: 20px;

    .leftT {
      width: 30%;
      height: 100px;
      background: #3f75ff;
      border-radius: 12px;
      color: #fff;
      padding: 20px;
      text-align: center;

      span {
        font-size: 32px;
        font-weight: bold;
      }
    }

    .rightT {
      width: 60%;

      .item1 {
        width: 33%;

        .num {
          font-size: 30px;
          font-weight: bold;
          color: #333333;
        }
      }
    }

    .item2 {
      width: 24%;
      margin-right: 1.3%;
      background: #f5f6f7;
      border-radius: 8px;
      height: 100px;
      padding: 10px 20px;
      margin-bottom: 15px;

      .num {
        font-size: 20px;
        color: #000;
      }
    }

    .item2:nth-child(4n) {
      margin-right: 0;
    }

    .item2:nth-child(5),
    .item2:nth-child(6),
    .item2:nth-child(7) {
      margin-bottom: 0;
    }

    .orderprovos-box {
      border-bottom: 1px solid #ebeef5;
      padding: 10px 0;
    }

    .orderprovos-box:last-child {
      border-bottom: none;
    }
  }
}

.rightC {
  width: calc(20% - 15px);
  height: calc(100vh - 120px);
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 6px 8px 0px rgba(219, 223, 230, 0.15);
  border-radius: 20px;
  padding: 20px;

  .item3 {
    width: 100%;
    background: #f7f9fa;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 15px;

    .num {
      font-size: 28px;
      color: #000;
    }

    .avatar {
      width: 60px;
      height: 60px;
      background: #3f75ff;
      border-radius: 8px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .rightTitle {
      width: calc(100% - 60px);
    }

    .title1 {
      width: calc(100%);
      font-size: 24px;
      font-weight: 800;
      color: #333333;
    }

    .title2 {
      color: #666666;
    }

    .customerPhone {
      margin: 10px 0;

      i {
        margin-right: 5px;
      }
    }

    .setBtn {
      margin: 5px 0 0;

      i {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }

  .cTitle {
    margin: 15px 0 10px;
    font-weight: bold;

    div {
      cursor: pointer;
    }
  }

  .item4 {
    .item {
      width: 32%;
      margin-right: 1.5%;
      margin-bottom: 15px;
      cursor: pointer;
    }

    .item:nth-child(3n) {
      margin-right: 0;
    }

    .item:hover {
      box-shadow: 0px 6px 8px 0px #a0a5af26;
      border-radius: 10px;
    }
  }
}

.bg-1 {
  background: #8693f3;
}

.bg-2 {
  background: #bc8dee;
}

.bg-3 {
  background: #ffa897;
}

.bg-4 {
  background: #89c3f8;
}

.bg-1:hover {
  box-shadow: 2px 3px 6px rgba(134, 147, 243, 0.5);
}

.bg-2:hover {
  box-shadow: 2px 3px 6px rgba(188, 141, 238, 0.5);
}

.bg-3:hover {
  box-shadow: 2px 3px 6px rgba(255, 168, 151, 0.5);
}

.bg-4:hover {
  box-shadow: 2px 3px 6px rgba(137, 195, 248, 0.5);
}

.content2 {
  color: #000000;
}

.c-title {
  margin: 10px 0;
}

.box {
  height: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.el-row {
  margin-bottom: 20px;
}

.border1 {
  border: 2px dashed #8693f3;
  box-shadow: 2px 3px 6px rgba(134, 147, 243, 0.5);
}

.color1 {
  color: #8693f3;
}

.border2 {
  border: 2px dashed #bc8dee;
  box-shadow: 2px 3px 6px rgba(188, 141, 238, 0.5);
}

.color2 {
  color: #bc8dee;
}

.border3 {
  border: 2px dashed #ffa897;
  box-shadow: 2px 3px 6px rgba(255, 168, 151, 0.5);
}

.color3 {
  color: #ffa897;
}

.border4 {
  border: 2px dashed #89c3f8;
  box-shadow: 2px 3px 6px rgba(137, 195, 248, 0.5);
}

.color4 {
  color: #89c3f8;
}

.border5 {
  border: 2px dashed #f386e1;
  box-shadow: 2px 3px 6px rgba(243, 134, 225, 0.5);
}

.color5 {
  color: #f386e1;
}

.border6 {
  border: 2px dashed #86e6f3;
  box-shadow: 2px 3px 6px rgba(134, 230, 243, 0.5);
}

.color6 {
  color: #86e6f3;
}

.iq-card-body {
  border-radius: 15px;
  margin-bottom: 15px;
}

.iq-card-icon {
  margin-left: 15px;
}

.iq-card-icon img {
  margin-top: 15px;
}

.iq-card-mes {
  margin-left: 20px;
  color: #ffffff;
}

.iq-card-mes h4 {
  font-size: 18px;
  font-weight: normal;
}

.iq-card-mes p {
  font-size: 14px;
}

.iq-card-mes h3 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: normal;
}

.fast-nav {
  margin-top: 40px;
}

.bg-white {
  background: #ffffff;
}

.fast-nav .iq-card-body {
  text-align: center;
}

.fast-nav .iq-card-body:hover {
  cursor: pointer;
  box-shadow: 0px 3px 8px rgba(175, 173, 173, 0.3);
}

.fast-nav .iq-card-body p {
  font-size: 16px;
  color: #333333;
}

.statistics {
  margin-top: 40px;
}

.iq-card-header {
  padding: 0 20px;
  min-height: 4px;
  border-bottom: 1px solid #eeeeee;
}

.iq-card-header span {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
}

.el-col-12 .iq-card-body {
  height: 400px;
}

@media (max-width: 1699px) {
  .iq-search-bar .searchbox {
    width: 350px;
  }
}

.center-title {
  font-size: 25px;
}

.yuan {
  font-size: 12px;
  margin-left: 5px;
}

.desc {
  text-align: left;
  width: 100%;
}
</style>
