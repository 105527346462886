<template>
    <div style="height: 100%">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="$router.go(-1)" content="素材">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form action="#" class="searchbox" @submit.native.prevent>
                            <input type="text" class="text search-input" v-model="Keyword" placeholder="输入关键词搜索..." @keydown="SearchInputKeydown">
                            <a class="search-link" href="javascript:;" @click="Search"><i class="el-icon-search"></i></a>
                        </el-form>
                    </div>
                    <el-button type="primary" size="small" @click="addBtn()" plain>新增相册</el-button>
                </div>
                <div class="list-box clearfix">
                    <el-card class="box-card" v-for="(item,index) in AlbumData" :key="index">
                        <div slot="header" class="clearfix">
                            <span>{{item.name}}({{item.files.length}})</span>
                            <el-button style="float: right; padding: 3px 0" type="text" @click="editBtn(item)">编辑相册</el-button>
                        </div>
                        <div @click="toDetail(item)">
                            <el-image v-if="item.files.length > 0" :src="http+item.files[0].filePath"></el-image>
                            <el-image v-else :src="require('../../../assets/img/no-img.png')"></el-image>
                        </div>
                    </el-card>
                </div>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <!--    新增相册    -->
        <!-- 添加或修改菜单对话框 -->
        <el-dialog :title="title" :visible.sync="formDialog" width="600px" append-to-body @close="cancel('form')">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="相册名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入相册名称" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel('form')">取 消</el-button>
                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import bread from "../../../components/common/breadcrumb";
    import {MaterialLibrary} from "../../../components/HospitalDomain/ShopMall/MaterialLibrary";

    export default {
        name: "Album",
        computed: {
            headers() {
                return {
                    "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
                }
            }
        },
        components: {
            bread
        },
        data(){
            var materialLibrary = new MaterialLibrary(this.TokenClient, this.Services.Shopping);
            return{
                MaterialLibrary:materialLibrary,
                organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
                Keyword: '',
                AlbumData: [],
                PageIndex: 1,
                PageTotal: 0,
                PageSize: 0,
                DataTotal: 0,
                form:{
                    id: 0,
                    name: '',
                    organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
                },
                title: '',
                formDialog: false,
                http: this.CommonPath,
                rules:{
                    name:[
                        { required: true, message: '请输入相册名称', trigger: 'blur' },
                    ]
                }
            }
        },
        mounted() {
            this.getList();
        },
        methods:{
            Search(){
                this.PageIndex = 1;
                this.getList();
            },
            SearchInputKeydown(event) {
                if (event.keyCode == 13) {
                    this.PageIndex = 1;
                    this.getList();
                }
            },
            ChangePage(pageIndex){
                this.PageIndex = pageIndex;
                this.getList();
            },
            getList(){
                var _this = this;
                _this.AlbumData = [];
                _this.MaterialLibrary.OrganizaionList(_this.PageIndex,_this.Keyword,
                    function (data) {
                        _this.AlbumData = data.data.results;
                        _this.PageIndex = data.data.pageIndex;
                        _this.PageSize=data.data.pageSize;
                        _this.PageTotal = data.data.pageCount;
                        _this.DataTotal = data.data.dataTotal;
                    },
                    function (err) {
                        console.log(err);
                    });
            },
            addBtn(){
                this.formDialog = true;
            },
            editBtn(item){
                this.formDialog = true;
                this.form.name = item.name;
                this.form.id = item.id;
            },
            cancel(formName){
                this.formDialog = false;
                this.$refs[formName].resetFields();
            },
            submitForm(formName){
                var _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        _this.MaterialLibrary.EditAlbum(_this.form,
                            function (data) {
                                _this.$message({
                                    type: 'success',
                                    message: '添加成功!'
                                });
                                _this.formDialog = false;
                                _this.$refs[formName].resetFields();
                                _this.getList();
                            },function (err) {

                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            toDetail(item){
                var _this = this;
                _this.$router.push(
                    {
                        path:'/ShopMall/AlbumDetail',
                        query:{
                            id: item.id,
                            organizationId: item.organizationId
                        }
                    }
                );
            }
        }
    }
</script>

<style scoped>
    /*content*/
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .list-box{
        height: calc(100% - 90px);
        overflow: auto;
    }
    .el-card{
        width: 250px;
        float: left;
        margin: 20px;
    }
    .el-card__body .el-image{
        width: 208px;
        height: 208px;
    }
</style>
<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #089BAB;
    }
</style>
