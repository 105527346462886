<template>
    <div style="height: 100%">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :inline="true">
                            <el-form-item label="订单状态">
                                <el-select v-model="searchForm.state" placeholder="请选择支付状态" size="small"
                                    style="margin-right: 10px">
                                    <el-option v-for="item in statusOption" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..."
                                    @keyup.enter.native="search"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields"
                                    worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前页数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出{{DataCount}}条数据</el-button>
                                </download-excel>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" height="calc(100% - 80px)" border style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="sonOrderNumber" label="订单编号" width="210" fixed></el-table-column>
                    <el-table-column label="商品名称" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div class="orderprovos-box" v-for="(item, index ) in scope.row.commodityOrder"
                                :key="index">
                                {{ item.commodity.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单信息" width="130" align="center">
                        <template slot-scope="scope">
                            <div class="orderprovos-box" v-for="(item, index ) in scope.row.commodityOrder"
                                :key="index">
                                ￥{{ item.actualMoney }}*{{ item.commodityNum }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="快递费用" align="center" width="100px">
                        <template slot-scope="scope">
                            <div class="orderprovos-box" v-for="(item, index ) in scope.row.commodityOrder"
                                :key="index">
                                {{ item.freight }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="积分" prop="integral" align="center">
                    </el-table-column>
                    <el-table-column label="实付金额（元）" align="center">
                        <template slot-scope="scope">
                            ￥{{ scope.row.payMoney }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="下单用户" align="center"></el-table-column>
                    <el-table-column prop="addressee" label="收货人信息" width="150" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.addressee }}</p>
                            <p>【{{ scope.row.phone }}】</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态" align="center">
                        <template slot-scope="scope">
                            <div class="orderprovos-box" v-for="(item, index ) in scope.row.commodityOrder"
                                :key="index">
                                <el-tag v-if="item.state == 0" type="warning" size="small">待付款</el-tag>
                                <el-tag v-if="item.state == 1" type="success" size="small">已完成</el-tag>
                                <el-tag v-if="item.state == 2" size="small">待发货</el-tag>
                                <el-tag v-if="item.state == 3" type="primary" size="small">已发货</el-tag>
                                <el-tag v-if="item.state == -1" type="info" size="small">已取消</el-tag>
                                <el-tag v-if="item.state == -10" type="danger" size="small">退款中</el-tag>
                                <el-tag v-if="item.state == -11" type="danger" size="small">退款完成</el-tag>
                                <el-tag v-if="item.state == -12" type="danger" size="small">取消退款</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundTime" label="下单时间" width="160px" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.createTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="260px" align="center">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.state == 0 || scope.row.state == 2" type="warning" size="mini"
                                plain @click="ChangeAddress(scope.row)">修改地址</el-button>
                            <el-button v-if="scope.row.state == 0" type="primary" size="mini" plain
                                @click="ChangeFreight(scope.$index)">修改运费</el-button>
                            <!-- <el-button v-if="scope.row.state == 0" type="danger" size="mini" plain
                                @click="ChangeFreight(scope.$index)">修改订单</el-button> -->
                            <!---->
                            <el-button type="success" size="mini" v-if="scope.row.state == 2" plain
                                @click="DeliverGoods(scope.row)">发货</el-button>
                            <el-button type="success" size="mini" v-if="scope.row.state == 3 || scope.row.state == 1"
                                plain @click="showExpress(scope.row)">查看物流</el-button>
                            <!--v-if="scope.row.state == 2"-->
                            <!--<el-button type="success" size="mini" plain @click="DeliverGoods(scope.$index)">修改物流</el-button>-->
                            <el-button type="primary" size="mini" @click="openDetail(scope.$index)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <el-dialog title="修改收货地址" :visible.sync="dialogAddress">
            <el-form :model="Addressform">
                <el-form-item label="收货人" :label-width="formLabelWidth">
                    <el-input v-model="Addressform.addressee" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" :label-width="formLabelWidth">
                    <el-input v-model="Addressform.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所在区域" :label-width="formLabelWidth">
                    <el-cascader ref="cascaderAddr" v-model="Addressform.regionId" :options="RegionData" size="medium"
                        :placeholder="Addressform.regionFullName"
                        :props="{ emitPath: false, multiple: false, checkStrictly: true, lazy: true, lazyLoad: LasyLoadRegion, value: 'id', label: 'regionName', children: 'childrens' }"
                        @change="handleAddressFun" style="margin-right: 10px;width: 100%;margin-bottom: 22px;">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" :label-width="formLabelWidth">
                    <el-input type="textarea" v-model="Addressform.address" :placeholder="Addressform.address">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogAddress = false">取 消</el-button>
                <el-button type="primary" @click="savaAddress()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改运费" :visible.sync="dialogFreight" width="550px">
            <el-form :model="Freightform">
                <el-form-item :label-width="formLabelWidth">
                    <el-radio v-model="Freightform.freightType" :label="2">免运费</el-radio>
                    <el-radio v-model="Freightform.freightType" :label="1">固定运费</el-radio>
                    <el-radio v-model="Freightform.freightType" :label="3">运费模板</el-radio>
                </el-form-item>
                <template v-if="Freightform.freightType == 1">
                    <el-form-item label="原快递费" :label-width="formLabelWidth">
                        <el-input v-model="Freightform.freight" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="修改为" :label-width="formLabelWidth">
                        <el-input v-model="Freightform.freightMoney" autocomplete="off"></el-input>
                    </el-form-item>
                </template>
                <template v-if="Freightform.freightType == 3">
                    <el-form-item label="选择运费模板" :label-width="formLabelWidth">
                        <el-select v-model="Freightform.freightTemplateId" placeholder="请选择" @change="getFreightDetail">
                            <el-option v-for="item in freightTemplate" :label="item.templateName" :value="item.addQty">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="首费" :label-width="formLabelWidth">
                        <el-input v-model="freightDetail.firstPrice" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="续费" :label-width="formLabelWidth">
                        <el-input v-model="freightDetail.renewalPrice" autocomplete="off" disabled></el-input>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFreight = false">取 消</el-button>
                <el-button type="primary" @click="savaFreight()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="发货" :visible.sync="dialogDeliver" width="850px">
            <el-form :model="Deliverform">
                <el-form-item label="订单编号:" :label-width="formLabelWidth">
                    <p>{{ orderDetail.sonOrderNumber }}</p>
                </el-form-item>
                <el-form-item label="交易编号:" :label-width="formLabelWidth">
                    <p v-if="orderDetail.orderPayment">{{ orderDetail.orderPayment.transactionNumber }}</p>
                </el-form-item>
                <el-form-item label="下单时间:" :label-width="formLabelWidth">
                    <p>{{ orderDetail.createTime | timefilters }}</p>
                </el-form-item>
                <el-form-item label="发货状态:" :label-width="formLabelWidth">
                    <el-radio-group v-model="Deliverform.expressType" :disabled="disabled">
                        <el-radio-button :label="1">全部发货</el-radio-button>
                        <!-- <el-radio-button :label="2">部分发货</el-radio-button> -->
                    </el-radio-group>
                </el-form-item>
                <template v-if="Deliverform.expressType == 1">
                    <el-form-item label="快递信息:" :label-width="formLabelWidth">
                        <el-input v-model="Deliverform.expressCompany" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="快递编号:" :label-width="formLabelWidth">
                        <el-input v-model="Deliverform.expressCode" autocomplete="off"></el-input>
                    </el-form-item>
                </template>
                <!-- expressType2部分发货 -->
                <template v-if="Deliverform.expressType == 2">
                    <el-form-item :label-width="formLabelWidth">
                        <el-table :data="orderDetail.commodityOrder" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                            <el-table-column prop="commodity.name" label="商品名称">
                            </el-table-column>
                            <el-table-column label="快递公司">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.expressCompany"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="快递单号">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.expressCode"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogDeliver = false">取 消</el-button>
                <el-button type="primary" @click="savaDeliver()">确 定</el-button>
            </div>
        </el-dialog>
        <el-drawer title="物流" :visible.sync="dialogExpress" :direction="direction" wrapperClosable>
            <div class="drawer-box">
                <div class="detail-item">
                    <h5 class="detail-tit">订单信息</h5>
                    <p><span>订单编号：</span>{{ detailData.sonOrderNumber }}</p>
                    <p v-if="detailData.orderPayment"><span>交易编号：</span>{{ detailData.orderPayment.transactionNumber }}
                    </p>
                    <p><span>收货人：</span>{{ detailData.addressee }} {{ detailData.phone }}</p>
                    <p><span>收货地址：</span>{{ detailData.address }}</p>
                </div>
                <div class="detail-item">
                    <h5 class="detail-tit">物流信息</h5>
                    <div class="status">
                        <!-- 0快递收件(揽件)1.在途中 2.正在派件 3.已签收 4.派送失败 5.疑难件 6.退件签收 -->
                        <el-tag size="small" type="info" v-if="expressData.deliverystatus == 0">揽件中</el-tag>
                        <el-tag size="small" v-if="expressData.deliverystatus == 1">在途中</el-tag>
                        <el-tag size="small" v-if="expressData.deliverystatus == 2">正在派件</el-tag>
                        <el-tag size="small" type="success" v-if="expressData.deliverystatus == 3">已签收</el-tag>
                        <el-tag size="small" type="warning" v-if="expressData.deliverystatus == 4">派送失败</el-tag>
                        <el-tag size="small" type="warning" v-if="expressData.deliverystatus == 5">疑难件</el-tag>
                        <el-tag size="small" type="danger" v-if="expressData.deliverystatus == 6">退件签收</el-tag>
                    </div>
                    <p><span>快递公司名称：</span>{{ expressData.expName }}</p>
                    <p><span>快递单号：</span>{{ expressData.number }}</p>
                    <p><span>快递公司电话：</span>{{ expressData.expPhone }}</p>
                    <template v-if="expressData.deliverystatus >= 2">
                        <p><span>快递员：</span>{{ expressData.courier }}</p>
                        <p><span>快递员电话：</span>{{ expressData.courierPhone }}</p>
                    </template>
                </div>
                <div class="detail-item">
                    <el-timeline>
                        <el-timeline-item v-for="(item, index) in expressData.list" :key="index" color="#67C23A"
                            :timestamp="item.time | timefilters">
                            <span class="status-color">{{ item.status }}</span>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
        </el-drawer>
        <el-drawer title="订单明细" :visible.sync="detaildrawer" :direction="direction" wrapperClosable>
            <div class="drawer-box">
                <div class="detail-item">
                    <h5 class="detail-tit">订单信息</h5>
                    <p><span>订单编号：</span>{{ detailData.sonOrderNumber }}</p>
                    <p v-if="detailData.orderPayment"><span>交易编号：</span>{{ detailData.orderPayment.transactionNumber }}
                    </p>
                    <p><span>收货人：</span>{{ detailData.addressee }} {{ detailData.phone }}</p>
                    <p><span>收货地址：</span>{{ detailData.address }}</p>
                    <p><span>下单时间：</span>{{ detailData.createTime | timefilters }}</p>
                    <p v-if="detailData.orderPayment && detailData.orderPayment.payTime != '0001-01-01T00:00:00'">
                        <span>支付时间：</span><template
                            v-if="detailData.orderPayment && detailData.orderPayment.payTime != '0001-01-01T00:00:00'">{{
                                    detailData.orderPayment.payTime
                                    | timefilters
                            }}</template>
                    </p>
                </div>
                <div class="detail-item">
                    <!-- <h5 class="detail-tit">商品信息</h5> -->
                    <div class="pro-box" v-for="(order, index) in detailData.commodityOrder" :key="index">
                        <div class="status">
                            <el-tag v-if="order.state == 0" type="warning" size="small">待付款</el-tag>
                            <el-tag v-if="order.state == 1" type="success" size="small">已完成</el-tag>
                            <el-tag v-if="order.state == 2" size="small">待发货</el-tag>
                            <el-tag v-if="order.state == 3" type="primary" size="small">已发货</el-tag>
                            <el-tag v-if="order.state == -1" type="info" size="small">已取消</el-tag>
                            <el-tag v-if="order.state == -10" type="danger" size="small">退款中</el-tag>
                            <el-tag v-if="order.state == -11" type="danger" size="small">退款完成</el-tag>
                            <el-tag v-if="order.state == -12" type="danger" size="small">取消退款</el-tag>
                        </div>
                        <div class="search-item clearfix">
                            <div class="item-img">
                                <img v-if="order.commodity.coverPicture.length > 0"
                                    :src="CommonPath + order.commodity.coverPicture[0].filePath">
                                <img v-else src="../../../../assets/img/no-img.png" />
                            </div>
                            <div class="item-mes">
                                <p class="item-tit">
                                    {{ order.commodity.name }}
                                </p>
                                <p class="item-price mgt-2 text-red">￥{{ order.actualMoney }}</p>
                                <p class="text-gray shopcart">*{{ order.commodityNum }}</p>
                            </div>
                        </div>
                        <div class="clearfix mgt-2">
                            <p class="left">快递运费</p>
                            <p class="right text-red">￥{{ order.freight || 0 }}</p>
                        </div>
                        <div class="clearfix mgt-2">
                            <p class="left">已优惠</p>
                            <p class="right text-red">￥{{ order.preferentialMoney || 0 }}</p>
                        </div>
                        <div class="clearfix mgt-2">
                            <p class="left">实付</p>
                            <p class="right text-red">￥{{ order.payMoney || 0 }}</p>
                        </div>
                        <div class="clearfix mgt-2">
                            <p class="left">积分</p>
                            <p class="right text-red">￥{{ detailData.integral || 0 }}</p>
                        </div>
                        <div v-if="detailData.expressCompany">
                            <div class="clearfix mgt-2">
                                <p class="left">快递信息：</p>
                                <p class="text-red">{{ detailData.expressCompany }}</p>
                            </div>
                            <div class="clearfix mgt-2">
                                <p class="left">快递单号：</p>
                                <p class="text-red">{{ detailData.expressCode }}</p>
                            </div>
                            <div class="clearfix mgt-2" v-if="detailData.deliverTime">
                                <p class="left">发货时间：</p>
                                <p class="text-red">{{ detailData.deliverTime | timefilters }}</p>
                            </div>
                            <div class="clearfix mgt-2" v-if="detailData.completionTime">
                                <p class="left">收货时间：</p>
                                <p class="text-red">{{ detailData.completionTime | timefilters }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="pro-box">
                        <div class="clearfix mgt-2">
                            <p class="left">平台抽佣</p>
                            <p class="right text-red">￥{{ detailData.platformCommissionMoney || 0 }}</p>
                        </div>
                        <div class="clearfix mgt-2">
                            <p class="left">实到金额</p>
                            <p class="right text-red">￥{{ detailData.merchantActualMoney }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { Order } from "../../../../components/HospitalDomain/ShopMall/Order";
import bread from "../../../../components/common/breadcrumb";
import { Commodity } from "../../../../components/HospitalDomain/ShopMall/commodity";
import { Region } from "../../../../components/HospitalDomain/Region";

export default {
    name: "orderList",
    components: {
        bread
    },
    data() {
        var order = new Order(this.TokenClient, this.Services.Shopping);
        var commodity = new Commodity(this.TokenClient, this.Services.Shopping);
        var region = new Region(this.TokenClient, this.Services.Authorization);
        return {
            OrderDomain: order,
            CommodityDomain: commodity,
            Region: region,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            state: '',
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 0,
            DataTotal: 0,
            tableData: [],
            searchForm: {
                state: '',
                keyWord: '',
            },
            statusOption: [{
                value: '',
                label: '全部'
            }, {
                value: '0',
                label: '待付款'
            }, {
                value: '2',
                label: '待发货'
            }, {
                value: '3',
                label: '已发货'
            }, {
                value: '1',
                label: '已完成'
            }, {
                value: '-999',
                label: '退单'
            }, {
                value: '-1',
                label: '已取消'
            }, {
                value: '-2',
                label: '已删除'
            }],
            formLabelWidth: '120px',
            dialogAddress: false,
            dialogFreight: false,
            dialogDeliver: false,
            dialogExpress: false,
            expressData: {
                number: "432578454379043", //快递单号
                "type": "YUNDA", //快递公司简称
                "deliverystatus": "3", //快递状态 0快递收件 (揽件)1.在途中 2.正在派件 3.已签收 4.派送失败 5.疑难件 6.退件签收
                "issign": "1", //是否签收 可能不准
                "expName": "韵达快递", //快递公司名称
                "expSite": "www.yundaex.com", //快递公司官网
                "expPhone": "95546", //快递公司电话
                "courier": "", //投递员名称
                "courierPhone": "0371-61844349",// 快递员电话
                "updateTime": "2022-05-31T19:20:26", //快递轨迹信息最新时间
                "takeTime": "1天21小时19分", //发货到收货消耗时长 (截止最新轨迹)
                "logo": "https://img3.fegine.com/express/yd.jpg", //快递公司LOGO
                "list": [
                    //快递详情
                    {
                        "time": "2022-05-31T19:20:26",
                        "status": "【代收点】您的快件已签收，签收人在 郑州兴达锦绣园新街坊超市店(已签收签收人凭取货码签收) 领取，投诉电话: 0371 - 61844349"
                    },
                ]
            },
            RegionData: [],
            Addressform: {
                id: 0,
                addressee: '',
                regionId: 0,
                regionName: '',
                address: '',
                fullAddress: '',
                phone: '',
            },
            freightTemplate: [],
            freightDetail: {},
            Freightform: {
                freight: '',
                freightType: 0,
                freightTemplateId: null,
                freightMoney: '',
            },
            disabled: false,
            Deliverform: {
                orderNumber: 0,
                createTime: '',
                expressType: 1,
                expressCompany: '',
                expressCode: '',
                commodityExpresses: [
                    {
                        expressCompany: '',
                        expressCode: '',
                    }
                ]
            },
            commodityOrderData: [],
            detaildrawer: false,
            direction: 'rtl',
            detailData: {},
            http: '',
            orderDetail: {},
            title: '积分订单',
            DataCount: 0,
            json_fields: {
                "订单编号": {
                    filed: 'sonOrderNumber',
                    callback: val => {
                        return 'NO：' + val.sonOrderNumber
                    }
                },
                "商品名称": {
                    callback: (val) => {
                        return this.formatName(val.commodityOrder)
                    }
                },
                "订单信息": {
                    callback: val => {
                        return this.formatOrderInfo(val.commodityOrder)
                    }
                },
                "快递费用": {
                    callback: val => {
                        return this.formatFreight(val.commodityOrder)
                    }
                },
                "积分": 'integral',
                "实付金额": 'payMoney',
                "下单用户": 'userName',
                "收货人信息": {
                    callback: val => {
                        return `${val.addressee}-${val.phone}`
                    }
                },
                "订单状态": {
                    callback: val => {
                        return this.getOrderState(val.commodityOrder)
                    }
                },
                "下单时间": {
                    filed: 'createTime',
                    callback: (val) => {
                        var val = val.createTime
                        return this.$fliters.timefilters(val)
                    }
                },
            },
        }
    },
    methods: {
        getOrderState(val) {
            var res = ''
            val.forEach((item, i) => {
                switch (item.state) {
                    case 0:
                        res += `【待付款】`
                        break;
                    case 1:
                        res += `【已完成】`
                        break;
                    case 2:
                        res += `【待发货】`
                        break;
                    case 3:
                        res += `【已发货】`
                        break;
                    case -1:
                        res += `【已取消】`
                        break;
                    case -2:
                        res += `【已删除】`
                        break;
                    case -999:
                        res += `【退款】`
                        break;
                }
            })
            return res
        },
        formatOrderInfo(val) {
            var res = ''
            val.forEach((item, i) => {
                res += `【${item.actualMoney}*${item.commodityNum}】`
            })
            return res
        },
        formatName(val) {
            var res = ''
            val.forEach((item, i) => {
                res += `【${item.commodity.name}】`
            })
            return res
        },
        formatFreight(val) {
            var res = ''
            val.forEach((item, i) => {
                res += `【${item.freight}】`
            })
            return res
        },
        OrderState(row) {
            if (row.state == '0') {
                return '待付款'
            } else if (row.state == '1') {
                return '已完成';
            } else if (row.state == '2') {
                return '待发货';
            } else if (row.state == '3') {
                return '已发货';
            } else if (row.state == '-1') {
                return '已取消';
            } else if (row.state == '-2') {
                return '已删除';
            } else if (row.state == '-999') {
                return '退款';
            }
        },
        LoadOrderList() {
            var _this = this;
            _this.OrderDomain.IntegralOrderList(_this.organizationId, _this.PageIndex, _this.searchForm.state, _this.searchForm.keyWord, 10,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                        _this.DataCount = data.data.dataTotal;
                },
                function (err) {
                    console.log(err);
                });
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                _this.OrderDomain.OrderList(_this.organizationId, 1, _this.searchForm.state, _this.searchForm.keyWord, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (err) {
                        resolve('');
                        console.log(err);
                    });
            })

        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.LoadOrderList();
        },
        search() {
            this.PageIndex = 1;
            this.LoadOrderList()
        },
        InitialRegion() {
            var _this = this;
            _this.Region.Regions(null,
                function (data) { _this.RegionData = data.data; },
                function (err) { console.log(err); });
        },
        LasyLoadRegion(node, resolve) {
            var _this = this;
            _this.Region.Regions(node.value,
                function (data) {
                    for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                        data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 3;
                    }
                    resolve(data.data);
                },
                function (err) {
                    resolve([]);
                    console.log(err);
                });
        },
        handleAddressFun(e, form) {
            // thsAreaCode = this.form.areaCode    // 注意1：获取value值
            var thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels     //注意2： 获取label值
            this.Addressform.regionName = thsAreaCode.join('')
            this.$refs['cascaderAddr'].dropDownVisible = false
        },
        ChangeAddress(item) {
            var _this = this;
            this.InitialRegion();
            let editItem = {};
            _this.OrderDomain.OrderDetail(item.id,
                function (data) {
                    editItem = data.data
                    _this.Addressform.id = editItem.id;
                    _this.Addressform.addressee = editItem.addressee;
                    _this.Addressform.address = editItem.address;
                    _this.Addressform.phone = editItem.phone;
                    _this.Addressform.regionFullName = editItem.regionFullName;
                    // _this.Addressform.regionId = editItem.regionId;
                    _this.Addressform.regionId = []
                    _this.Addressform.regionId.push(editItem.regionId);
                    _this.dialogAddress = true;
                },
                function (err) {
                    console.log(err);
                });

        },
        savaAddress() {
            var _this = this;
            var item = this.Addressform
            _this.Addressform.fullAddress = _this.Addressform.regionName + _this.Addressform.address
            _this.Addressform.regionFullName = _this.Addressform.fullAddress
            // var addressStr = _this.Addressform.regionId.join(' ')
            // _this.Addressform.regionId = ""
            // _this.Addressform.regionId = addressStr
            _this.OrderDomain.changeAddress(item.id, item,
                function (data) {
                    _this.LoadOrderList();
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.dialogAddress = false;
                    _this.LoadOrderList()
                },
                function (err) {
                    console.log(err);
                });
        },
        LoadFreightTemplate() {
            var _this = this;
            _this.CommodityDomain.FreightTemplate(_this.organizationId,
                function (data) {
                    _this.freightTemplate = data.data;
                },
                function (err) {
                    console.log(err);
                });
        },
        ChangeFreight(item) {
            this.dialogFreight = true;
            var editItem = this.tableData[item];
            this.Freightform.id = editItem.id;
            if (editItem.freight == 0) {
                this.Freightform.freightType = 2
            }
            else {
                this.Freightform.freightType = 1;
            }
            this.Freightform.freight = editItem.freight;
            this.Freightform.freightMoney = '';
        },
        getFreightDetail(value) {
            var _this = this;
            _this.CommodityDomain.FreightDetail(value,
                function (data) {
                    _this.freightDetail = data.data;
                },
                function (err) {
                    console.log(err);
                });
        },
        savaFreight() {
            var _this = this;
            var item = this.Freightform;
            if (item.freightType == 2 || item.freightType == 3) {
                item.freightMoney = 0
            }
            var params = {
                "freightTemplateId": item.freightTemplateId,
                "freightType": item.freightType,
                "freightMoney": item.freightMoney
            }
            _this.OrderDomain.ChangeFreight(item.id, params,
                function (data) {
                    _this.LoadOrderList();
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.dialogFreight = false;
                },
                function (err) {
                    console.log(err);
                });
        },
        DeliverGoods(item) {
            var _this = this;
            _this.OrderDomain.OrderDetail(item.id,
                function (data) {
                    _this.dialogDeliver = true;
                    _this.orderDetail = data.data;
                    _this.Deliverform.orderRecordId = data.data.id;
                    _this.commodityOrderData = data.data.commodityOrder;
                    let firstQdr = _this.commodityOrderData[0].state;
                    //forEach之后点击的每条数据，和第一次点击的数据进行字段值qdr判断是否相等
                    if (_this.orderDetail.state == 2) {
                        _this.disabled = false;
                    } else {
                        _this.Deliverform.expressType = 2;
                        _this.disabled = true;
                        /*_this.commodityOrderData.forEach((item, index) =>{
                            if(item.state != firstQdr){
                                //没有在判断后进行后续操作，而是写this.isSame进行判断，是因为勾选的数据第一次判断相等后，第二次判断不相等也会跳转页面

                            }else {
                            }
                        })*/
                    }

                },
                function (err) {
                    console.log(err);
                });
        },
        /**
         * 查看物流
         * @param {*} item 
         */
        showExpress(item) {
            var _this = this;
            _this.detailData = item
            _this.OrderDomain.ExpressQuery(item.expressCode,
                function (data) {
                    _this.dialogExpress = true;
                    _this.expressData = data.data
                },
                function (err) {
                    _this.$message({
                        type: 'error',
                        message: '快递单号错误'
                    });
                    console.log(err);
                });
        },
        savaDeliver() {
            var _this = this;
            var item = this.Deliverform;
            if (item.expressType == 1) {
                if (!item.expressCompany || !item.expressCode) {
                    _this.$message({
                        type: 'warning',
                        message: '请完善快递信息!'
                    });
                    return
                }
                var params = {
                    "orderRecordId": item.orderRecordId,
                    "expressType": item.expressType,
                    "expressCompany": item.expressCompany,
                    "expressCode": item.expressCode,
                }
                console.log(params);
            }
            if (item.expressType == 2) {
                var list = []
                this.orderDetail.commodityOrder.forEach(function (index) {
                    if (index.expressCompany) {
                        var diff = {
                            "expressCompany": index.expressCompany,
                            "expressCode": index.expressCode,
                            "commodityId": index.commodityId,
                            "commoditySpecificationId": index.commoditySpecificationId,
                        }
                    } else {
                        return
                    }
                    list.push(diff);
                })
                var params = {
                    "orderRecordId": item.orderRecordId,
                    "expressType": item.expressType,
                    "commodityExpresses": list
                }
                console.log(params);
            }
            _this.OrderDomain.DeliverGoods(params,
                function (data) {
                    _this.LoadOrderList();
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.dialogDeliver = false;
                },
                function (err) {
                    console.log(err);
                });
        },
        openDetail(item) {
            var _this = this;
            _this.detaildrawer = true;
            var detail = _this.tableData[item];
            _this.OrderDomain.OrderDetail(detail.id,
                function (data) {
                    _this.detailData = data.data
                },
                function (err) {
                    console.log(err);
                });

        },
    },
    mounted() {
        this.LoadOrderList();
        this.LoadFreightTemplate()
        // this.http = this.Services.Shopping
    }
}
</script>

<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

.messages {
    margin-top: 13px;
    margin-right: 20px;
}

.messages .el-icon-bell {
    font-size: 26px;
}

/*content*/


.table-top {
    margin-bottom: -10px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.orderprovos-box {
    border-bottom: 1px solid #EBEEF5;
    padding: 20px;
}

.orderprovos-box:last-child {
    border-bottom: none;
}

.drawer-box {
    padding: 30px;
}

.pro-box {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f5f7fa;
}

.item-img {
    float: left;
    width: 118px;
    height: 118px;
    margin-right: 10px;
    overflow: hidden;
}

.item-img img {
    width: 100%;
}

.item-mes {
    width: calc(100% - 130px);
    float: left;
    position: relative;
}

div.mgt-2 {
    border-bottom: 1px dashed #ebebeb;
}

.item-tit {
    color: #454545;
    font-size: 14px;
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 57px;
}

.item-price {
    margin-top: 40px;
}

.shopcart {
    position: absolute;
    right: 0;
    bottom: 0;
}

.detail-item {
    position: relative;
    margin-bottom: 20px;
}

.detail-item .detail-tit {
    font-size: 18px;
}

.detail-item>p {
    margin: 5px 0;
}

.detail-item>p span {
    display: inline-block;
    width: 100px;
    text-align: right;
}

.detail-item .status {
    position: absolute;
    right: 0;
}

.detail-item .status .text-orange {
    color: orange;
}

.detail-item .status p.text-danger {
    color: red;
}

.detail-item .status p.text-succes {
    color: forestgreen;
}
</style>
<style>
.el-drawer {
    background-color: #ffffff !important;
    width: 550px !important;
}

.el-drawer__body {
    overflow: auto;
}
</style>
