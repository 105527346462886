<template>
  <div class="height1">
    <!--      头部      -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="审核状态">
                <el-select v-model="searchForm.state" placeholder="请选择审核状态" size="small" style="margin-right: 10px" @change="search">
                  <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px"> </el-date-picker>
              </el-form-item> -->
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="名称/账号" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50px"> </el-table-column>
          <el-table-column prop="expertUserName" label="预约专家">
            <template slot-scope="scope">
              <p>{{ scope.row.expertUserName }}</p>
              <p>{{ scope.row.expertUserPhone }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="userTitleName" label="专家职称" align="center"></el-table-column>
          <el-table-column prop="dates" label="预约日期" align="center">
            <template slot-scope="scope">
              <p v-for="(date,i) in scope.row.dates" :key="i" style="margin-bottom: 5px;">
                <el-tag  size="mini"> {{ date }}</el-tag>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="申请状态" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0" type="warning" size="small">待审核</el-tag>
              <el-tag v-if="scope.row.status == 1" type="success" size="small">审核成功</el-tag>
              <el-tag v-if="scope.row.status == 2" type="danger" size="small">拒绝</el-tag>
              <p v-if="scope.row.status == 2" style="font-size: 10px;">{{ scope.row.remark }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="申请时间" width="150px" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="auditUserName" label="审核人" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.auditUserName }}</p>
              <p>{{ scope.row.auditUserPhone }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="auditTime" label="审核时间" width="150px" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.auditTime | timefilters }}</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { ExpertAppointment } from '../../../components/HospitalDomain/ExpertAppointment'
import { TimeFormatDate } from '../../../utils/extend'
export default {
  name: 'MerchantAudit',
  data() {
    var expertAppointment = new ExpertAppointment(this.TokenClient, this.Services.Authorization)
    return {
      ExpertAppointment: expertAppointment,
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
        state: '-1',
      },
      statusOption: [
        {
          value: '-1',
          label: '全部',
        },
        {
          value: '0',
          label: '待审核',
        },
        {
          value: '1',
          label: '审核通过',
        },
        {
          value: '2',
          label: '已拒绝',
        },
      ],
      tableData: [],
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
    }
  },
  mounted() {
    this.GetList()
  },
  methods: {
    search() {
      this.PageIndex = 1
      this.GetList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.GetList()
    },
    GetList() {
      var _this = this
      _this.tableData = []
      _this.ExpertAppointment.GetAppointmentList(
        1,
        _this.searchForm.state,
        _this.searchForm.keyWord,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
          if (_this.tableData.length > 0) {
            _this.tableData.forEach((i) => {
              let dates = []
              i.appointmentDateList.forEach((item) => {
                dates.push(TimeFormatDate(item*1000))
              })
              _this.$set(i,"dates",dates)
            })
          }
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}

.dialog-form {
  width: 430px;
}

/**/
.addhospital {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.addhospital .el-form {
  flex: 1;
}

.addhospital .form-box {
  padding: 30px 50px 30px 30px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.demo-drawer__footer {
  display: flex;
  margin-left: 100px;
}

.el-drawer /deep/ .el-drawer {
  width: 100vw;
}

.headImg {
  position: relative;
  margin-bottom: 25px;
}

.headimgBox {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid rgba(12, 154, 154, 0.28);
}

.headimgBox img {
  width: 100%;
}

.headImg .upload-btn {
  position: absolute;
  left: 57%;
  bottom: 0px;
}

.pay-top {
  position: relative;
}

.payItem {
  line-height: 2;
}

.payItem p {
  float: left;
}

.payItem span {
  float: right;
}

.payNum {
  color: red;
  font-size: 24px;
}

.pay-bg {
  position: relative;
  margin: 20px auto;
  width: 350px;
  height: 436px;
  background: url('../../../assets/img/wx_pay.jpg') top center no-repeat;
  background-size: cover;
}

.pay-bg img {
  position: absolute;
  width: 160px;
  top: 140px;
  left: 100px;
}
.avatar {
  width: 148px;
  height: 146px;
}
.height1 .el-drawer {
  background-color: #eff7f8;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
  overflow: scroll !important;
}
.dialog-c {
  .note-logo {
    margin-right: 10px;
    width: 56px;
  }
  .note-name {
    font-weight: bold;
    margin-right: 10px;
  }
  .note-hos {
    margin-right: 10px;
  }

  .hos-desc {
    padding: 0 5px;
    border-radius: 4px;
    border: 0.1px solid #cad6f9;
    font-size: 12px;
    .note-l {
      margin-right: 2px;
    }
  }
  .note-2 {
    margin: 10px 0 15px 0;
    .note-desc {
      line-height: 1.2;
      position: relative;
      .desc {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #fff;
        color: var(--base--Color);
        cursor: pointer;
      }
    }
  }
  .note-3 {
    font-weight: bold;
    background: #f6f6f8;
    border-radius: 4px;
    padding: 5px 10px;
    height: 40px;
    .note3-img {
    }
    .note3-shenfang {
      margin: 0 15px 0 10px;
    }
  }
  .note-4 {
    margin: 15px 0 0;
    .note4-logo {
      color: #02bf6f;
      margin-left: 5px;
    }
  }
}
:deep() .el-dialog-box .el-dialog__body {
  padding-top: 0 !important;
}
</style>
