<template>
    <div style="height: 100%">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                    <el-input style="width:300px" v-model="searchForm.keyWord" type="text" size="small"
                                        placeholder="商品名称" @keyup.enter.native="search" clearable ></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size="small" @click="search()">搜索</el-button>
                                </el-form-item>
                            </el-form>
                        </div>

                    </div>
                    <el-table :data="tableData" height="calc(100% - 90px)" stripe style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                        <el-table-column prop="name" label="商品名称" width="220"></el-table-column>
                        <el-table-column prop="category.name" label="分类" align="center"></el-table-column>
                        <el-table-column prop="merchantName" label="店铺名称" align="center"></el-table-column>
                        <el-table-column prop="addTime" label="添加时间" align="center">
                            <template slot-scope="scope">{{ scope.row.addTime | timefilters }}</template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="150" align="center">
                            <template slot-scope="scope">
                                <span class="primary optionBtn" size="mini" @click="showInfo(scope.row)" plain>查看</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                        :total="CommodityTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                </div>
            </div>
            <el-dialog :visible.sync="Image.dialogVisible">
                <img width="100%" :src="Image.dialogImageUrl" alt="">
            </el-dialog>
            <el-dialog title="商品详情" :visible.sync="dialogVisible" width="900px">
                <el-form>
                    <el-form-item label="商品名称" :label-width="formLabelWidth">
                        <el-input class="w-18" v-model="commodityForm.name" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="商品封面" :label-width="formLabelWidth">
                        <el-upload :headers="headers" :action="FileUploadUrl" class="img-div" list-type="picture-card" :limit="5" multiple
                            :on-preview="handleImagePreview" :file-list="ImageContentList" disabled>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="购买须知" :label-width="formLabelWidth">
                        <i class="v-html" v-html="commodityForm.instructions"></i>
                    </el-form-item>
                    <el-form-item label="商品介绍" prop="introduce" :label-width="formLabelWidth">
                        <i class="v-html" v-html="commodityForm.introduce"></i>
                    </el-form-item>
                    <el-form-item label="售后服务" :label-width="formLabelWidth">
                    <i class="v-html" v-html="commodityForm.customerService"></i>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { Commodity } from "../../../components/HospitalDomain/ShopMall/commodity";
import bread from "../../../components/common/breadcrumb";
import { CommodityCategory } from "../../../components/HospitalDomain/ShopMall/CommodityCategory";

export default {
    name: "commodity",
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + JSON.parse(sessionStorage.getItem(this.ClientId + "Token")).access_token,
      };
    },
  },
    components: {
        bread
    },
    data() {
        var commodity = new Commodity(this.TokenClient, this.Services.Shopping);
        var commodityCategory = new CommodityCategory(this.TokenClient, this.Services.Shopping)
        return {
            CommodityCategory: commodityCategory,
            CommodityDomain: commodity,
            tableData: [],
            CategoryData: [],
            searchForm: {
                state: '0',
                categoryId: '',
                keyWord: '',
            },
            CategoryId: 0,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 0,
            CommodityTotal: 1,
            formLabelWidth: '100px',
            CommodityDialog: false,
            CommodityTitle: '新增商品',
            steps: 1,
            commodityForm: {
                commoditySpecifications: [],
            },
            editIndex: 0,
            commoditySpecifications: {
                name: '',
                guidancePrice: null,
                vip1Price: null,
                vip2Price: null,
                membershipPrice: 0,
                ordinaryPrice: null,
                stockNum: null,
                rank: 0
            },
            dialogVisible: false,
            commodityForm: {
                type: 1,
                attribute: 1,
                name: '',
                categoryId: null,
                commodityParameters: [],
                coverPicture: [],
                isShare: false,
                memberShare: 0,
                ordinaryShare: 0,
                commoditySpecifications: [
                    {
                        name: '',
                        guidancePrice: null,
                        membershipPrice: null,
                        ordinaryPrice: null,
                        stockNum: null,
                        rank: 0
                    }
                ],
                limitType: 0,
                limitNum: 0,
                cancellationOrder: '',
                completionOrder: '',
                isSend: false,
                freightType: 2,
                freightMoney: '0',
                freightTemplateId: '',
                isInvoice: false,
                chargebackType: 1,
                chargebackMoney: 0,
                introduce: '',
                instructions: '',
                customerService: '',
            },
            Image: {
                //单击图片上传控件中的图片时，放大显示
                dialogVisible: false,
                dialogImageUrl: '',
            },
            ImageContent: [],//图片列表
            ImageContentList: [],//图片上传控件加载的数据
            FileUploadUrl: this.CommonFilePath,
        }
    },
    mounted() {
        this.LoadCommoditys();
    },
    methods: {
        handleImagePreview(file) {
            this.Image.dialogImageUrl = file.url;
            this.Image.dialogVisible = true;
        },
        showInfo(item) {
            var _this = this
            _this.CommodityDomain.Detail(item.id,
                function (data) {
                    _this.commodityForm = {
                        id: data.data.id,
                        name: data.data.name,
                        coverPicture: [],
                        introduce: data.data.introduce.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto" '),
                        instructions: data.data.instructions.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto" '),
                        customerService: data.data.customerService.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto" '),
                    }
                    _this.ImageContentList = []
                    _this.commodityForm.coverPicture = []
                    data.data.coverPicture.forEach(element => {
                        let obj = new Object();
                        obj.url = _this.CommonPath + element.filePath;
                        _this.ImageContentList.push(obj);
                        let imgMap = {
                            fileName: element.fileName,
                            filePath: element.filePath
                        }
                        _this.commodityForm.coverPicture.push(imgMap)
                    });
                    _this.dialogVisible = true
                }, function (err) { console.log(err); });
        },
        LoadCommoditys() {
            var _this = this;
            _this.tableData = [];
            var item = _this.searchForm;
            _this.CommodityDomain.OrgAudit(item.keyWord, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.CommodityTotal = data.data.dataTotal;
                },
                function (err) {
                    console.log(err);
                });
        },
        search() {
            this.PageIndex = 1;
            this.LoadCommoditys();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.LoadCommoditys();
        },
        changeStatus(item) {
            var _this = this;
            _this.CommodityDomain.UporDown(item.id,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.LoadCommoditys();
                },
                function (error) {
                    console.log(error);
                });
        },
        changeCommended(item) {
            var _this = this;
            _this.CommodityDomain.IsCommended(item.id,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.LoadCommoditys();
                },
                function (error) {
                    console.log(error);
                });
        },
        changeHot(item) {
            var _this = this;
            _this.CommodityDomain.IsHot(item.id,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.LoadCommoditys();
                },
                function (error) {
                    console.log(error);
                });
        },
        changeNew(item) {
            var _this = this;
            _this.CommodityDomain.IsNew(item.id,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.LoadCommoditys();
                },
                function (error) {
                    console.log(error);
                });
        },
        toEdit(item, isEdit) {
            var _this = this;
            _this.$router.push(
                {
                    path: '/ShopMall/editCommodity',
                    query: { id: item.id, edit: isEdit }
                }
            );
        },
        addSale(item, isEdit) {
            console.log(item.id)
            var _this = this;
            _this.$router.push(
                {
                    path: '/ShopMall/addSaleCommodity',
                    query: { id: item.id, edit: isEdit }
                }
            );
        },
        SetSpecifications(item) {
            var _this = this;
            _this.CommodityDomain.Detail(item.id,
                function (data) {
                    _this.commodityForm.commoditySpecifications = data.data.commoditySpecifications;
                    let result = JSON.parse(JSON.stringify(_this.commodityForm.commoditySpecifications ? _this.commodityForm.commoditySpecifications : []));
                    result.forEach((element) => {
                        element["edit"] = false;
                    });
                    result[0].edit = true
                    _this.commodityForm.commoditySpecifications = result;
                    _this.commoditySpecifications = _this.commodityForm.commoditySpecifications[0];
                    _this.dialogVisible = true;

                }, function (err) { console.log(err); });
        },
        //规格增删改---------开始
        handleAdd() {
            let row = {
                edit: true,
                name: '',
                guidancePrice: null,
                vip1Price: null,
                vip2Price: null,
                membershipPrice: 0,
                ordinaryPrice: null,
                stockNum: null,
                rank: 0
            }
            this.commodityForm.commoditySpecifications.push(row)
        },
        editData(row) {
            this.commoditySpecifications = row
            var array = this.commodityForm.commoditySpecifications
            for (let index = 0; index < array.length; index++) {
                if (array[index].id == row.id) {
                    this.editIndex = index
                    array[index].edit = true
                } else {
                    array[index].edit = false
                }

            }
        },
        confirmData(row) {
            row.edit = false;
            // console.log(row.edit);
            // console.log(row.price);
            this.$notify({
                title: "Success",
                message: "编辑成功",
                type: "success",
                duration: 2000,
            });
        },
        handleDelete(row, index) {
            this.$notify({
                title: "Success",
                message: "Delete Successfully",
                type: "success",
                duration: 2000,
            });
            this.commodityForm.commoditySpecifications.splice(index, 1);
        },
        //规格增删改---------结束
        saveSpecifications(formName) {
            this.commodityForm.commoditySpecifications[this.editIndex] = this.commoditySpecifications
            var _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.CommodityDomain.SetSpecifications(_this.commodityForm.commoditySpecifications,
                        function (data) {
                            _this.$message({
                                type: 'success',
                                message: '修改成功!'
                            });
                            _this.dialogVisible = false;
                            _this.LoadCommoditys();
                        },
                        function (error) {
                            console.log(error);
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        remove(item) {
            var _this = this;
            this.$confirm('确定删除此条商品信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.CommodityDomain.Remove(item.id,
                    function (data) {
                        _this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        _this.LoadCommoditys();
                    },
                    function (error) {
                        console.log(error);
                    });
            }).catch(() => { });

        },
    }
}
</script>

<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

.messages {
    margin-top: 13px;
    margin-right: 20px;
}

.messages .el-icon-bell {
    font-size: 26px;
}

/*content*/


.table-top {}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.img-div /deep/ .el-upload {
    display: none;
}

.isShare-box div .el-input {
    width: 70px;
}

.v-html img {
    height: 300px;
}
</style>
