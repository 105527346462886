<template>
    <div class="height1" v-loading="loading">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content" >
            <div class="iq-card-body" style="padding-left:100px">
                <el-scrollbar class="left-nav-wrap" style="height:100%;">
                    <el-form class="applyForm" ref="applyForm" label-position="top" :model="applyForm" label-width="150px" :rules="rules">
                        <el-form-item label="店铺名称" prop="merchantName">
                            <el-input class="w-18" v-model="applyForm.merchantName"></el-input>
                        </el-form-item>
                        <el-form-item label="商品类型" prop="mcommodityCategoryIds">
                            <el-checkbox-group v-model="applyForm.mcommodityCategoryIds" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="(item, i) in MerchantCategory" :label="item" :key="i" name='mcommodityCategoryIds'>{{item.name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="法人代表授权书">
                            <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card"
                                :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                                :on-success="handleImageUpload" :limit="5">
                                <i class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip" v-if="tip1">请上传企业或者/个体工商营业执照照片</div>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                        <el-form-item  :label="itemQua.qualificationName" v-for="(itemQua,inexQua) in QualificationList" :key="inexQua">
                            <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card"
                                :on-preview="handlePictureCardPreview" 
                                :on-remove="(file)=>{
                                    return handleRemoveA(file,itemQua)
                                }"
                                :on-success="(res,file)=>{return handleImageUploadA(res,file,itemQua,inexQua)}" :limit="5">
                                <i class="el-icon-plus"></i>
                                 
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="itemQua.dialogImageUrl" alt="">
                            </el-dialog>
                            <div>资质描述：{{itemQua.description}}</div>
                        </el-form-item>
                    
                        <!-- <el-form-item label="法人身份证" class="clearfix">
                            <el-upload class="avatar-uploader" :headers="headers" :action="FileUploadUrl"
                                :show-file-list="false" :on-success="handleIdCardASuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="idcardA" :src="idcardA" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip" v-if="tip2">身份证人像面</div>
                            </el-upload>
                            <el-upload class="avatar-uploader" :headers="headers" :action="FileUploadUrl"
                                :show-file-list="false" :on-success="handleIdCardBSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="idcardB" :src="idcardB" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip" v-if="tip3">身份证国徽面</div>
                            </el-upload>
                        </el-form-item> -->
                        <!-- <el-form-item label="其他资质">
                            <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card"
                                :on-preview="handlePictureCardAPreview" :on-remove="handleRemoveA"
                                :on-success="handleImageUploadA" :limit="5">
                                <i class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip" v-if="tip4">请上传药品经营质量管理规范认证证书执业药师注册证
                                    经营医疗器械的，还须提供《医疗器械经营许可证》和/或《第二类医疗器械经营备案》*最多可上传五张，且单张图片不得操作20M</div>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item> -->
                        <!-- <el-form-item label="店铺所在区域" prop="regionId">
                            <el-cascader placeholder="请选择所在区域" v-model="applyForm.regionId" :options="RegionData"
                                size="medium"
                                :props="{ emitPath: false, multiple: false, checkStrictly: true, lazy: true, lazyLoad: LasyLoadRegion, value: 'id', label: 'regionName', children: 'childrens' }"
                                style="margin-right: 10px;width: 100%;">
                            </el-cascader>
                        </el-form-item> -->
                        <!-- <el-form-item label="详细地址" prop="address">
                            <el-input placeholder="地图选点" v-model="applyForm.address" disabled>
               
                            </el-input>
                        </el-form-item>
                        <el-form-item label="补充地址">
                            <el-input placeholder="补充地址" v-model="applyForm.addressOther">
                            </el-input>
                        </el-form-item> -->
                        <!-- <el-form-item label="">
                            <baidu-map class="bm-view" scroll-wheel-zoom :center="location" :zoom="zoom"
                                ak="C3L8oD0NokcKDAyhtGZgTtwF1R4eqo7S" @ready="mapReady">
                                <bm-view class="map" style="width: 100%; height:100%; flex: 1"></bm-view>
        
                                <bm-control>
                                    <bm-auto-complete :sugStyle="{ zIndex: 999999 }">
                                        <el-input size="small" type="text" id="searchAddres"
                                            v-model="searchAddresKeywords" placeholder="请输入地点" @change="confirmAddress">
                                        </el-input>
                                    </bm-auto-complete>
                                </bm-control>
                           
                                <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
                                <bm-geolocation anchor="BMAP_ANCHOR_TOP_RIGHT" :showAddressBar="true"
                                    :autoLocation="true"></bm-geolocation>
                            </baidu-map>
                        </el-form-item> -->
                        <el-form-item>
                            <el-button type="primary" @click="saveBtn('applyForm')">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-scrollbar>

            </div>
        </div>
        <!--    协议    -->
        <el-dialog :visible.sync="centerDialog" width="850px" :show-close="false" :close-on-click-modal="false"
            :close-on-press-escape="false" center>
            <div class="dialog-wrap">
                <h4>欢迎加入千越医助手</h4>
                <h5>官方客服电话：0395-3393819</h5>
                <div class="dialog-content">
                    <el-scrollbar style="height: 450px">
                        <h4>商家入驻协议</h4>
                        <p>甲方：河南千越医疗科技有限公司</p>
                        <p>乙方：</p>
                        <p>根据中华人民共和国有关法律、法规的相关规定，经友好协商，就乙方入驻千越医助手平台开展商品交易事宜达成如下合作：</p>
                        <p>第一条 前言</p>
                        <p>1.本协议由千越医助手的所有者“河南千越医疗科技有限公司”（下称“甲方”或“千越医助手”）与具备合法经营资质拥有实体经营场所且经营状况良好的经营企业（下称“乙方”或“商家”）共同缔结，具有法律效力。
                        </p>
                        <p>2.本协议仅在表明千越医助手商城与商家二者之间的权利义务关系，对于商家通过千越医助手商城与任何第三方建立的合作关系以及由此产生的权利义务关系等，由商家自行对第三方享有权利、承担义务。
                        </p>
                        <p>3.本协议各条的标题仅为方便阅读而设，无意成为本协议的一部分，也不影响本协议的含义或解释。</p>
                        <p>第二条 定义</p>
                        <p>
                            1.千越医助手商城：指运行在“www.qyyl365.com”域名上，所有者为河南千越医疗科技有限公司的第三方交易平台；或在苹果、安卓等手机应用市场名称为“千越医助手”，所有者为河南千越医疗科技有限公司的APP应用。
                        </p>
                        <p>2.商家：指拥有线下固定经营场所且具有合法经营资质的企业。</p>
                        <p>3.网店：经千越医助手商城审核通过后的具有独立且唯一性ID、以经营许可证上载明的企业名称作为网店名称的网络化虚拟空间，称之为网店，商家在自己的网店内有独立经营管理权利。通过其注册账号登录网店后台对网店实施管理，自主维护网店内商品信息，自主进行交易。
                        </p>
                        <p>
                            4.商家入驻：拟在千越医助手商城独立开设经营网店，必须至申请时起持续具备本协议第五条约定的资格要求；商家入驻，亦称“网店入驻”，依据千越医助手商城入驻流程和要求，经审核，按照本协议约定开设网店的过程。
                        </p>
                        <p>5.零售企业：是指向普通消费者用户以零售方式销售商品的企业。</p>
                        <p>6.千越医助手商城规则：指根据中华人民共和国相关法律及国家政策所制定的，标示在千越医助手商城上或与商家签署相关协议，需商家实时关注的任何规范性文件。</p>

                        <p>7.商品：是指在线销售的药品、医疗器械、保健品、化妆品、食品等类型商品。</p>
                        <p>8.客户：是指通过千越医助手商城向入驻商家购买商品或服务的普通消费者。</p>
                        <p>第三条 甲乙双方身份确定</p>
                        <p>一、甲方基本情况</p>
                        <p>为经营企业及医疗机构之间（BTOB）和经营企业与普通消费者之间(BTOC)提供第三方互联网交易服务的医药电子商务服务公司。</p>
                        <p>互联网药品信息服务资格证书编号：(豫)-经营性-2021-0057；</p>
                        <p>ICP备案号:豫ICP备2021000748号；</p>
                        <p>服务范围：第三方交易服务平台；</p>
                        <p>千越医助手商城网址：www.qyyl365.com。</p>
                        <p>二、乙方基本情况</p>
                        <p>商家申请及开展网店经营活动，须持续地满足以下基本条件：</p>
                        <p>1.商家已依照中华人民共和国法律注册并领取合法有效的营业执照及取得其他经营许可。</p>
                        <p>2.商家系具备合法经营资质拥有实体经营场所且经营状况良好的经营企业。</p>
                        <p>3.商家经营的商品来源合法，资质齐全，不侵犯第三方合法权益。</p>
                        <p>4.商家提交的任何信息均真实、合法、有效，所使用的图片、文字等不侵犯任何第三方合法权益。</p>
                        <p>5.商家应根据法律法规及相关文件要求建立并实施商品（含药品、医疗器械、保健品、化妆品、食品等类型商品）相应的管理制度，包括但不限于质量安全管理、风险控制、药品追溯、储存配送管理、不良反应报告、投诉举报处理等制度。
                        </p>
                        <p>6.商家自愿签署并严格履行本协议。</p>
                        <p>7.千越医助手商城依据法律法规等要求及经营需要可能设定的其他条件。</p>
                        <p>第四条 服务内容</p>
                        <p>一、为商家提供在千越医助手商城开设网店提供技术支持和日常维护，便于商家开展与交易有关的各项业务。</p>
                        <p>二、为入驻商家提供网店界面和在线交易系统。</p>
                        <p>三、商家的经营行为受到千越医助手商城的监督和管理。千越医助手商城根据法律法规、本协议约定以及千越医助手商城规则，有权单方面决定限制商家的经营行为，比如要求商家删除或调整发布的信息等，商家拒绝履行或配合时，千越医助手商城有权根据本协议及千越医助手商城规则采取相应措施；千越医助手商城同时有权将商家违法、违规行为举报到市场监督管理局等相关监管部门。
                        </p>
                        <p>四、根据《中华人民共和国药品管理法》、《处方管理办法》、《互联网医院管理办法》及《药品网络销售监督管理办法》第九条“药品网络零售企业应当与电子处方提供单位签订协议，并严格按照有关规定进行处方审核调配，对已经使用的电子处方进行标记，避免处方重复使用。”的规定；商家须完成同电子处方提供单位协议的签订，同意承接用户在商家所合作的互联网医院进行问诊后所出具的电子处方单。商家须严格遵守国家相关法律法规及政策的规定，确保提供给客户的药品具有合法来源、符合国家药品标准。因药品质量问题致使客户或者其他方受到人身伤害，导致任一方遭到有关部门处罚，由商家承担相应责任。
                        </p>
                        <p>五、商家的经营行为受到客户的监督，客户可以向千越医助手商城、监管部门、媒体等进行投诉进行监督，千越医助手商城知悉该投诉后，将介入调查并向商家出具意见，商家应积极配合并妥善解决。</p>
                        <p>六、商家的经营行为受市场监督管理局等相关机构的监督和管理。千越医助手商城收到相关监管部门监管意见时，有权向监管部门提供相关资料，要求商家予以整改、执行监管部门的意见等；商家怠于执行或迟延执行时，千越医助手商城有权限制商家经营活动，删除或调整商家发布的信息等，由此产生的不利后果由商家自行承担。
                        </p>
                        <p>七、千越医助手商城配备专职客服专员提供技术支持服务，服务时间为工作日9:00～18:00。</p>
                        <p>八、千越医助手商城协助商家解决交易纠纷及处理投诉事务。</p>
                        <p>第五条 乙方应当提交资质文件</p>
                        <p>商家申请及开展网店经营活动，须持续地具备以下资质文件：</p>
                        <p>一、药品零售企业必须提交的资质为；</p>
                        <p>
                            1.《营业执照》正本扫描件及复印件；
                            2.《药品经营许可证》正本扫描件及复印件；
                            3.《组织机构代码证》正本扫描件及复印件；
                            4.《税务登记证》正本扫描件及复印件；
                            5.《开户许可证》正本扫描件及复印件；
                            6.《药品经营质量管理规范认证证书》正本扫描件及复印件(GSP证书)；
                            7.《执业药师注册证》扫描件及复印件；
                            8.《法定代表人授权书》见（样本）原件；
                            9.《执业药师法人授权书》见（样本）原件；
                            10.经营医疗器械的，还须提供《医疗器械经营许可证》和/或《第二类医疗器械经营备案》正本扫描件及复印件；
                            11.经营保健食品的，还须提供《食品经营许可证》正本扫描件及复印件；
                            12.其他依据法律规定经营业务所必需的文件或证明。
                        </p>
                        <p>二、《法定代表人授权书》必须有法人签字或盖法人章及被授权人签字且联系方式信息完整、准确，并加盖公章。</p>
                        <p>三、以上证明企业合法身份的资质及相关资料必须用A4纸复印清晰，并加盖企业公章。如以上证明文件发生任何变更或更新时，乙方须立即通知甲方，并于3个工作日内提供变更或更新版本，同时自行上传更新后的资质及相关资料，如未及时上传造成千越医助手商城及其他任何第三方损失的，商家应承担相应责任。
                        </p>
                        <p>四、药品网络销售企业应当向相应药品监督管理部门报告企业名称、药品经营许可证等法律法规所规定的相关信息；信息发生变化的，应当在10个工作日内报告。</p>
                        <p>五、商家保证向千越医助手商城提供的上述资质文件或其他相关证明真实、合法、准确、有效。基于平台的特殊性，除核实商家身份真实性以及依据千越医助手商城监管规定核实商家相关信息以外，千越医助手商城无义务代替商家对其上传的所有信息、交易行为以及与交易行为有关的其他事项进行事先审查和核实，同时，千越医助手商城对商家信息的核实，仅为形式审核，并不代表千越医助手商城对其真实性和有效性的认可，该资质文件的真实性、合法性、准确性、有效性仍由商家单独承担全部法律责任。
                        </p>
                        <p>六、如商家提供伪造、编造、冒用、过期、失效文件或未如期通知并提交更新文件等情形的，由商家独立承担全部法律责任。若因此导致商家不符合网店入驻条件的，千越医助手商城有权要求商家补充提供相关资料，如未能补充提供的，千越医助手商城有权终止本协议。如商家造成千越医助手商城及其他任何第三方损失的，商家还应足额赔偿。
                        </p>
                        <p>七、商品交易中介服务协议必须有法人签名或盖法人章及委托代理人签名，并加盖公章。</p>
                        <p>八、特别说明：启用多证合一新版营业执照，无需提供《税务登记证》和《组织机构代码证》；《药品经营许可证》与GSP证书合一的，无需另外提供GSP证书。停发《开户许可证》的可提供企业银行账户声明。因国家政策调整多证合一或取消的，可合并提供或不予提供。
                        </p>
                        <p>第六条 关于乙方网店开通的特别约定</p>
                        <p>一、乙方须为资质齐全，经营状况良好且拥有实体经营场所的经营企业。</p>
                        <p>二、乙方须配备专职管理人员对网店发布的信息及时维护或更新。</p>
                        <p>三、乙方须确保在售商品能在客户下单付款后在约定的时间内发货。</p>
                        <p>四、乙方须配备处理交易订单或发货的专职人员，保障交易顺利及时达成。</p>
                        <p>五、乙方须配备专职在线客服人员，保障乙方与客户双方沟通渠道的畅通。</p>
                        <p>六、乙方须配备企业名义办理的固定电话作为咨询电话，方便用户咨询。</p>
                        <p>七、乙方发货地址仅限于经营许可证上批准的注册地址（门店地址）和/或仓库地址。</p>
                        <p>八、乙方须提交与经营许可证书上载明的单位名称一致的银行对公账户。</p>
                        <p>九、乙方同意遵守《商家交易管理规范及处罚实施细则》（见附件一）及通过其他形式发布的各种管理规定。</p>
                        <p>第七条 资金、税费与付款约定</p>
                        <p>一、乙方入驻千越医助手商城开设网店需与甲方签署《千越医助手商城商家入驻协议》。</p>
                        <p>二、乙方可在千越医助手商城展示网店内商品信息，在遵守国家法律法规的前提下开展商品交易服务。</p>
                        <p>三、乙方向客户销售商品同意甲方按交易成功订单商品总金额的【5%】收取技术服务费，且同意甲方在客户支付款项时按笔实时系统自动扣除结算。</p>
                        <p>四、商家网上药店经营过程中，因支付模式所产生的成本费用均由商家承担，具体参见相关规则。</p>
                        <p>五、乙方通过甲方千越医助手商城网站促成的交易所涉及的税费及甲方提取技术服务费所产生的税费甲方概不负责。</p>
                        <p>六、由甲方千越医助手商城网站促成的交易，乙方应严格遵守甲方制定的发货、退货、换货的流程要求，因乙方违规操作或商品在配送中损毁、拒收的，甲方不承担任何赔偿责任完全免责。</p>
                        <p>七、甲、乙双方终止协议后，所有订单状态完结，乙方未清算的货款在三十日内退还。</p>
                        <p>八、发票约定</p>
                        <p>1.甲方收取乙方的各项费用按实际发生金额开具增值税专用发票或增值税普通发票。</p>
                        <p>2.甲方按单笔订单收取的技术服务费，累计至1000元时于次月开具相应发票。开票金额不足1000元时顺延至次月。</p>
                        <p>3.若双方终止协议后技术服务费仍未满1000元的则按实际收取的技术服务费金额向乙方开具发票。</p>
                        <p>九、本协议所涉及的费用一律以人民币元为单位。</p>
                        <p>第八条 结算方式</p>
                        <p>一、微信渠道结算方式</p>
                        <p>步骤一：微信扣除手续费，具体金额以微信实际规定为准。</p>
                        <p>步骤二：千越医助手商城扣除技术服务费、平台物流费和/或处方费用，技术服务费为订单总金额的5%。</p>
                        <p>步骤三：客户订单完成(确认收货)，经过步骤一、二后的剩余金额，商家可自行完成提取。</p>
                        <p>二、支付宝渠道结算方式</p>
                        <p>步骤一：支付宝扣除手续费，具体金额以支付宝实际规定为准。 </p>
                        <p>步骤二：千越医助手商城扣除技术服务费、平台物流费和/或处方费用，技术服务费为订单总金额的5%。 </p>
                        <p>步骤三：客户订单完成(确认收货)，经过步骤一、二后的剩余金额，商家可自行完成提取。</p>
                        <p>三、订单中涉及到优惠，如积分、优惠券等，千越医助手商城会在订单完结时，同步结算给商家，具体以千越医助手商城实际规定为准。</p>
                        <p>第九条 关于物流约定</p>
                        <p>一、千越医助手商城基于遵守《个人信息保护法》、《药品网络销售监督管理办法》相关规定，为达到在物流服务过程中严格保护客户个人信息权益，防止遭受侵害及完善服务过程中质量责任的目的；现就商家开展商品交易产生物流快递事宜如下规定：
                        </p>
                        <p>1.商家在千越医助手商城开展商品交易产生物流快递需求的，所使用的快递服务（即快递服务公司）须采取严格措施就物流服务过程中所涉及客户个人信息进行严格保护，确保信息不发生泄露，侵害。</p>
                        <p>
                            2.快递服务方案如下，商家选择：
                            统一物流：关于《个人信息保护法》及《药品网络销售监督管理办法》所规定，千越医助手商城与快递服务公司达成合作，就快递服务过程中个人信息保护权益事项已完成技术对接；确保信息免遭侵害；为千越医助手商城商家提供快递服务。
                            自选物流：商家满足上述第一款第一点规定的前提下尚可自选快递服务公司；千越医助手商城须就客户个人信息保护措施进行严格审核；不符合要求的，商家经千越医助手商城通知后须及时作出整改措施。整改期间，千越医助手商城有权停止商家经营，直至符合相关要求。
                        </p>
                        <p>
                            二、根据《药品网络销售监督管理办法》：“药品网络零售企业委托配送的，应当对受托企业的质量管理体系进行审核，与受托企业签订质量协议，约定药品质量责任、操作规程等内容，并对受托方进行监督。”规定，商家须同物流服务受托企业完成质量协议的签署。
                            第十条 关于交易纠纷的特别约定
                        </p>
                        <p>一、客户通过《千越医助手商城》网站向乙方购买商品时，乙方必须主动提供随货清单并默认提供具有商家名号发票专用章的正规发票，客户可自行下载；发票上登载的商品明细必须是注明商品正式品名（通用名称）、数量、单价、总价的明细机打发票。不得以商品、药品等笼统性描述。
                        </p>
                        <p>二、客户在收到货物时通过外观目测发现包装破损、变形、水渍，与订购的商品不符等情况的，客户可直接选择拒收，乙方必须无条件同意退换货。</p>
                        <p>三、客户收到的货物经权威质量管理部门检验，确属商品本身存在质量问题，乙方必须无条件同意退货并承担由此产生的全部费用。</p>
                        <p>四、经国家或地方相关监管部门或者药品生产企业要求停售或召回的产品，乙方必须主动联系客户处理相关货物退换事宜并承担由此产生的相关费用。</p>
                        <p>五、客户收到货物时(以发货时间为准)，因商品有效期与订单载明的承诺效期不一致时，乙方必须无条件同意退货并承担由此产生的全部费用。特殊情况以双方约定为准。</p>
                        <p>第十一条 诱导非官方交易处理规则</p>
                        <p>一、诱导非官方交易，是指商家主动诱导千越医助手商城用户或接受用户的要求，尝试或实际通过非千越医助手商城官方渠道进行交易或收付款，或者实施其他提高交易风险、可能损害消费者合法权益的类似行为。
                        </p>
                        <p>二、商家在店铺经营过程中应当使用千越医助手商城提供的各项系统功能及客服工具等为千越医助手商城用户（以下简称“用户”）提供服务，不得通过任何方式诱导非官方交易。千越医助手商城发现商家违反本规则，从事诱导非官方交易行为的，有权依据本协议对商家作出相应的违规处理。
                        </p>
                        <p>三、违规认定</p>
                        <p>1.商家诱导非官方交易包括但不限于下列情形：</p>
                        <p>（1）线上或线下尝试或实际与用户通过非千越医助手商城提供的渠道进行交易或收付款，包括但不限于提供或使用银行账号或第三方支付工具、支付二维码、购物链接、第三方平台店铺或商品信息等。</p>
                        <p>（2）线上或线下尝试或实际与用户交换任何非千越医助手商城的账号，包括但不限于：第三方应用账号、第三方网站账号、电子邮箱、网盘账号、第三方订阅号、公众号信息等。</p>
                        <p>（3）线上或线下引导用户浏览、下载非千越医助手商城提供的、并且非正常交易或服务所必需的网站、内容或应用。</p>
                        <p>（4）商品包装上或包装中附带任何非正常交易或服务所必需的第三方网站或店铺信息等。</p>
                        <p>（5）为通过非千越医助手商城官方渠道进行交易或收付款的目的，线上或线下尝试或实际与用户交换手机号、座机号或其他联系方式。</p>
                        <p>2.商家存在下列情形之一的，构成严重违规：</p>
                        <p>（1）未经千越医助手商城许可或使用未经千越医助手商城认可的服务商向用户提供货到付款服务。</p>
                        <p>（2）诱导非官方交易，且发生支付问题、物流问题、售后问题、产品质量责任、人身伤害责任等，或存在此类风险。</p>
                        <p>（3）诱导非官方交易，且商品本身属于平台规则规定的禁售商品或存在其他违规情形。</p>
                        <p>（4）诱导非官方交易，且平台有理由认为可能严重威胁用户、平台或任何第三方的人身、财产安全或合法权益的其他情形。</p>
                        <p>四、处理规则</p>
                        <p>1．【非严重违规店铺的处理】如商家实施诱导非官方交易行为，但未构成严重违规的，千越医助手商城将按照如下规则处理：</p>
                        <p>（1）千越医助手商城有权对违规店铺及其关联店铺立即采取下列一项或多项措施，并通知商家：</p>
                        <p>
                            ①　对存在交易风险的订单作关闭处理；
                            ②　关闭或限制商家账户权限、店铺功能；
                            ③　限制店铺资金提现；
                            ④　店铺部分或全部商品屏蔽、降权、下架、禁售、删除、移除资源位、禁止上资源位；
                            ⑤　店铺禁止上新、禁止发布商品；
                            ⑥　店铺扣分、罚款。
                        </p>
                        <p>（2）商家应当在收到通知后的3个工作日内，按照千越医助手商城的要求以书面形式承诺不会再次实施诱导非官方交易的行为。</p>
                        <p>（3）如商家逾期未提交书面承诺，则千越医助手商城有权解除服务协议，关闭其网店。</p>
                        <p>2．【严重违规店铺的处理】商家实施诱导非官方交易行为，且构成严重违规的，除上述非严重违规店铺的处理规定的违规处理措施外，千越医助手商城有权立即解除服务协议，关闭其网店。</p>
                        <p>3．【多次违规店铺的处理】商家实施诱导非官方交易行为达两次或以上的，除上述非严重违规店铺的处理规定的违规处理措施外，千越医助手商城有权立即解除服务协议，关闭其网店。同时，千越医助手商城有权自店铺账户资金（包括但不限于货款余额）中扣收罚金。
                        </p>
                        <p>4．商家实施诱导非官方交易行为的，无论是否关闭网店，商家均应当就违规所涉商品或订单可能产生的损害及风险承担全部责任，如因此给用户、平台或任何第三方造成损失的，商家应当赔偿该等损失。商家同意，对于前述责任和损失，千越医助手商城有权自店铺账户资金（包括但不限于货款余额）中扣划相应的款项，用以偿付违约金、赔偿金、相关费用等。
                        </p>
                        <p>第十二条 协议方的权利和义务</p>
                        <p>一、未经对方书面同意，一方不得将本协议项下的权益转让给第三方。</p>
                        <p>二、千越医助手商城有权对平台功能及服务进行更新、升级，以不断提升平台性能和交易效率。如发现商家有损系统安全、稳定操作的，千越医助手商城有权立即停止商家的权限，并立即删除所有有害信息、数据等；商家应对此导致的一切不利后果承担全部法律责任，包括但不限于赔偿千越医助手商城及其关联公司/机构、客户、第三方机构等的损失。
                        </p>
                        <p>三、千越医助手商城对商家在使用千越医助手商城过程中遇到的问题及提出的建议和意见予以积极回复，可依商家对其使用千越医助手商城提供合理的指导和培训。</p>
                        <p>四、千越医助手商城有权单方面根据国家有关法律法规、政策及运营情况，对公示于千越医助手商城平台规则进行变更，变更后的规则将以公告形式告知商家。商家应实时关注公告内容，如不同意该变更，应书面通知千越医助手商城并立即停止使用在千越医助手商城上注册的网店并关闭交易等，如继续使用，则视为商家自愿接受变更后的规则。
                        </p>
                        <p>五、商家应对网店负有管理义务，并保证在进行商品交易过程中不损害千越医助手商城的利益，也不得利用千越医助手商城从事任何违法活动，应遵守千越医助手商城上发布的不违反国家法律法规和商业道德的各项规定，并接受甲方的监督；对其网店中发布的违反国家有关法律、法规规定及千越医助手商城规则的、明显带有歧义，容易产生争议性或误导的信息予以立即删除，如未及时删除，甲方有权直接予以删除，并按照法律法规规定向有关部门或单位提供商家相关数据信息，乙方应承担因此给甲方造成全部损失。
                        </p>
                        <p>六、商家应及时按照千越医助手商城规定履行完毕网店订单，因商家违约给客户造成损失的，由商家自行向客户承担违约责任，若商家行为导致千越医助手商城受到相关行政部门处罚或要求千越医助手商城承担连带责任、补充责任的，千越医助手商城有权向商家进行追偿。若商家在千越医助手商城上与客户产生的交易需签订合同的，由商家自行与客户协商签订相关合同。
                        </p>
                        <p>七、如商家的运营情况不能满足千越医助手商城公布的要求（包括但不限于千越医助手商城规则等），经限期整改调整后，仍无法满足的，千越医助手商城有权解除本协议，停止向商家提供服务。</p>
                        <p>八、为维护市场秩序，稳定商品销售价格，乙方销售商品定价低于平台核验凭证通过的零售价的均须上传采购商品的随货单进行核实，确定乙方销售商品定价是否低于商品采购价；随货单须注明商品正式品名（通用名称）、数量、单价、总计等相关内容；乙方须确保所上传随货单的真实性。
                        </p>
                        <p>九、为最大程度的保障客户权益，满足客户对商品的质量要求，商家有义务对其在千越医助手商城销售的每款商品按照国家标准、行业标准及千越医助手商城发布的各类商品要求进行质量控制（包括但不仅限于商品法律法规符合性、商品安全性、商品功能材质与描述符合性、商品标识标志、商品外观、商品包装等），并依照国家法律法规提供售后三包服务。千越医助手商城有权委托第三方质检机构进行不定期商品抽检（检测项目包括但不限于商家销售商品的性能，质量，材料成分，是否符合国家法律法规要求等各方面），或要求商家对千越医助手商城指定商品提供进货凭证，出厂检验报告或第三方质检机构出具的检测报告等相关产品及批次的质量合格证明文件。
                        </p>
                        <p>如果商家所销售商品抽检不合格或无法向千越医助手商城提供商品及批次质量合格的证明文件，千越医助手商城有权根据所公示的商家规则，规范及标准，并且依据问题的严重程度对商家进行处罚及提出相应的限期整改要求。商家必须配合限期整改及根据千越医助手商城的要求进行指定商品的第三方检测，并完全承担因此产生的所有费用。商家拒绝执行的，千越医助手商城有权采取相关措施，包括但不限于停止商家服务、终止本协议、要求商家承担违约责任、损害赔偿等措施，损害赔偿。如因商家商品质量问题而导致千越医助手商城产生损失（包括但不限于经济或声誉上的损失），千越医助手商城有权要求商家赔偿因此而产生的所有费用，并保留进一步追究商家相应责任的。
                        </p>
                        <p>十、商家保证，其对在千越医助手商城上销售商品的商号、商标、域名、图案标示、标志、标识等享有知识产权，或已获得对销售商品的商号、商标、域名、图案标示、标志、标识等享有知识产权的个人或机构的授权许可，或虽未直接获得对销售商品的商号、商标、域名、图案标示、标志、标识等享有知识产权的个人或机构的授权许可，但其销售商品是由合法途径采购的并对商品是否侵犯他人知识产权尽到合理注意义务，如因商家违反本条约定，导致千越医助手商城被第三方追究法律责任，由此给千越医助手商城造成的全部损失由商家予以承担，包括但不限于诉讼费、公证费、保全费、差旅费及执行费等。
                        </p>
                        <p>十一、商家经营药品的还需配备执业药师。在线销售处方药的，应当遵守国家关于处方药销售的法律、法规和规章。若违反国家相关法律法规，千越医助手商城有权单方面采取包括关闭网店、暂停结算，并要求商家赔偿实际损失。
                        </p>
                        <p>十二、千越医助手商城有权要求商家提供与商家商品、售后服务等相关的信息，以便于客户可以直接向商家客服进行咨询，商家应在收到咨询或诉讼后七日内处理完毕，并将处理结果送至千越医助手商城存档备案，如商家未及时予以解决客户咨询及投诉，千越医助手商城有权对商家采取相应处罚措施。
                        </p>
                        <p>十三、千越医助手商城同意商家根据商家营业执照、药品经营许可证、医疗器械经营相关许可、食品经营许可证等所载明的经营范围及商家申请经营的经营类目，核实及调整商家在千越医助手商城经营的具体商品的种类、数量和类目范围。
                        </p>
                        <p>十四、如因商家商品、发布的信息或提供的售后服务问题而引发客户对千越医助手商城及其关联公司/机构的诉讼，商家应承担因诉讼而产生的全部法律责任，如因此而给千越医助手商城及其关联公司/机构造成损失的，千越医助手商城有权要求商家赔偿千越医助手商城及其关联公司/机构的全部损失。
                        </p>
                        <p>十五、由于商家自身原因造成本协议无法继续履行的，千越医助手商城有权关闭其网店，终止本协议，但未处理完毕订单必须执行完毕，因商家违约给千越医助手商城造成损失的，千越医助手商城保留索赔的权利，有权要求商家赔偿千越医助手商城因此受到的全部损失。
                        </p>
                        <p>十六、因网上交易平台的特殊性，千越医助手商城没有义务对所有商家的交易行为以及交易有关的其他事项进行事先审查，但下列情况除外：</p>
                        <p>1.第三方通知千越医助手商城，认为某个具体商家或具体交易事项可能存在重大问题；</p>
                        <p>2.商家或第三方向千越医助手商城告知交易平台上有违法或不正当行为的；</p>
                        <p>3.千越医助手商城发现某个具体商家或具体交易事项可能存在重大问题。</p>
                        <p>千越医助手商城以普通非专业人士的知识水平标准对相关内容进行判别，可以认为这些内容或行为具有违法或不当性质的，千越医助手商城有权根据不同情况选择删除相关信息、对商家网店采取限制性措施或停止对该商家提供服务。
                        </p>
                        <p>十七、商家知悉并同意，商家违反本协议时，应立即向千越医助手商城承担法律责任，同时，千越医助手商城有权暂缓支付未结算款项；商家进一步知悉并同意，在商家未按时承担法律责任时，千越医助手商城有权直接自暂缓支付的未结算款项中扣除相关款项。
                        </p>
                        <p>十八、双方同意，千越医助手商城只接受商家法定代表人授权的专职管理员的指令，并确认其为商家与千越医助手商城唯一联系人。商家变更其专职管理员时，需重新出具法定代表人的授权委托书送达给千越医助手商城。商家在变更其专职管理员时，同意千越医助手商城在未收到新的法定代表人授权委托书之前，仍以商家原专职管理员为唯一联系人，并确认其为商家唯一专职管理员，以千越医助手商城签收视为送达完成。在商家管理员更换期间，由于商家自身原因造成的数据被丢失、删除、失效等有损于商家利益时，千越医助手商城不承担任何赔偿责任。
                        </p>
                        <p>十九、双方同意，千越医助手商城只接受商家法定代表人授权的执业药师(或药师)的订单审核权限，并确认其为订单审核责任人。商家变更执业药师(或药师)时，需重新开具商家法定代表人的授权委托书送达千越医助手商城，并同意千越医助手商城在未收到商家新开具的法定代表人的授权委托书之前，仍以商家原执业药师(或药师)为订单审核责任人，以千越医助手商城签收视为送达完成。
                        </p>
                        <p>二十、商家专职管理员和执业药师(或药师)所持的法定代表人授权委托书应包含有：通讯联络方式，包括但不限于电话、手机、QQ、电子邮箱等。由于商家自身原因提供的信息失误而造成的及时联络障碍等有损于商家利益时，千越医助手商城不承担任何赔偿责任。
                        </p>
                        <p>二十一、商家允许千越医助手商城就商家在千越医助手商城的数据进行汇总、分析和集中，在千越医助手商城上展示，经商家认可，千越医助手商城对以上数据的处理可以合理使用，但涉及商家商业秘密的除外。
                        </p>
                        <p>第十三条 商家声明及保证</p>
                        <p>一、保证遵守本协议，不从事任何有损千越医助手商城利益的行为。</p>
                        <p>二、保证符合本协议约定的资格要求，保证提交的信息和证明文件真实、准确、合法、有效，并保证在上述资料发生变更时及时通知千越医助手商城，并予以更新。商家应保证入驻时提供的联系人、联系地址、联系电话等信息真实、有效、完整，并对此承担法律责任。
                        </p>
                        <p>三、保证订立本协议和提出入驻申请是商家真实意思表示，保证其具有足够资格订立本协议，其代理人（包括所有操作网店的人员和网店运营所需的全部雇员、职员、专职管理员、执业药师等）已获得充分授权，并对代理人的行为承担法律责任，同时商家保证对其雇员、联系人及其他商家委派的履行本协议的人员的行为承担法律责任。
                        </p>
                        <p>四、商家应当为每一个“网店账号”设定独立的、高安全等级的密码，保证妥善保管“网店账号”及密码，不得擅自转让或授权他人使用，并对利用该账号所进行的一切活动负全部责任。</p>
                        <p>五、商家在网店正常经营期间，须同步保持线下实体药店正常经营。若因商家自身原因线下实体药店不能正常经营处于停业状态的，商家应主动关闭网店经营，否则视为违约，千越医助手商城一经发现将强制关闭网店，并保留追究商家违约责任的权利。
                        </p>
                        <p>六、保证严格按照本协议约定、技术要求使用千越医助手商城管理系统，不从事攻击、破译、反向工程，上传木马、病毒等有损该系统安全和稳定的操作。</p>
                        <p>七、保证按照审核通过的商品类目和品牌经营，不得擅自增加、变更商品类目和品牌。保证按照千越医助手商城规则及国家相关规定设置商品价格、售卖限制、库存数量、商品说明及其他商品信息，并对上述设置独立承担全部法律责任。商家同时保证在经营过程中，按照千越医助手商城相关规则积极对客户的咨询进行回复，并保证在客户提交订单后按要求发货。
                        </p>
                        <p>八、保证拥有在千越医助手商城经营商品的合法销售权，商品来源可溯、合法、质量合格，符合国家相关质量标准，不侵犯任何第三方的合法权利（包括但不限于知识产权、商业秘密等），并对其商品质量及商品合法性独立承担全部法律责任。
                        </p>
                        <p>九、保证在千越医助手商城发布的商品信息真实、准确，符合千越医助手商城规则及国家相关法律法规的规定，与实际出售的商品一致，不含任何夸大或虚假内容，不包含任何未经授权的文字和图片，对描述的准确和相符负有举证责任并承担独立的完全的法律责任。若发布的上述信息变更的，商家应及时在千越医助手商城上予以更新。若因信息未及时变更引起法律后果的，商家独立承担全部责任。
                        </p>
                        <p>十、保证在使用千越医助手商城进行交易的过程中遵守诚实信用原则，不在交易中采取欺诈及虚假宣传、混淆等不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。</p>
                        <p>十一、保证不将从千越医助手商城获取的任何数据用于本协议约定以外的用途，同时保证未经千越医助手商城许可不得擅自获取、使用、传播千越医助手商城的任何资料，包括但不限于交易数据、用户信息、支付信息、其他展示于千越医助手商城的信息等。
                        </p>
                        <p>十二、商家不得隐瞒任何可能对千越医助手商城产生风险的事项，包括但不限于产品出现质量瑕疵、权利纠纷、重大违约、上传资料包含病毒木马等，若商家发生此类影响千越医助手商城商誉、正常经营、安全的事项应立即通知千越医助手商城，并严格执行千越医助手商城对此所作出的处置。
                        </p>
                        <p>十三、未经千越医助手商城另行书面许可，商家不得将本协议项下权利义务全部或部分转移给第三方；商家聘请第三方代运营公司代表商家运营网店的，第三方代运营公司的一切行为均视同为商家亲自实施，由商家承担全部法律后果；商家与第三方代运营公司间的权利义务关系由商家与第三方代运营公司单独解决，不得影响到千越医助手商城及千越医助手商城其他用户的权利。
                        </p>
                        <p>十四、商家应向千越医助手商城支付的所有应付款项，如未按照规定要求完成费用支付的，则千越医助手商城有权暂缓支付未结算货款，并有权自未向商家结算的货款中扣除，如未结算货款不足抵扣的，则千越医助手商城有权自商家及其关联公司待向商家结算的任意一笔货款中直接扣除。
                        </p>
                        <p>十五、如千越医助手商城的损失通过上述方式仍无法弥补，则千越医助手商城有权单方终止向上提供的一切服务，且有权继续向商家追偿。</p>
                        <p>十六、根据《处方管理办法》规定，商家端审核处方的执业药师需完成CA签字认证，以保证处方的合规有效。</p>
                        <p>十七、根据《中华人民共和国电子商务法》第二十条“电子商务经营者应当按照承诺或者与消费者约定的方式、时限向消费者交付商品或者服务，并承担商品运输中的风险和责任。”对于销售冷链商品、阴凉储存等特定要求的商品，商家须保证运输过程中有合理的存储条件，否则，所产生的损失由商家承担。
                        </p>
                        <p>第十四条 责任免除</p>
                        <p>一、因战争、自然灾害、不可抗力等导致甲方服务器不能正常运行。</p>
                        <p>二、因政府行政行为导致甲方不能开放服务器。</p>
                        <p>三、因互联网灾难，互联网通讯提供商原因导致甲方服务器不能正常接入。</p>
                        <p>四、因甲方操作平台及应用软件原因导致甲方服务器临时性不能正常运行。</p>
                        <p>五、因甲方网站遭遇不法攻击导致服务器临时性不能正常运行的。</p>
                        <p>基于以上原因，导致千越医助手商城不能正常运行，甲方不承担任何法律上和其他方式的责任。</p>
                        <p>第十五条 保密条款</p>
                        <p>一、一方对于本协议的签订、内容及在履行本协议期间所获知的其他方的商业秘密负有保密义务。非经相对方书面同意，不得向相对方以外的任何第三方（关联公司除外）泄露、给予或转让该等保密信息。（根据法律法规、证券交易所规则向政府、证券交易所和/或其他监管部门提供、协议方的法律、会计、商业及其他顾问、雇员除外）。
                        </p>
                        <p>二、如相对方提出要求，任何一方均应将载有相对方单独所有的保密信息的任何文件、资料或软件等，在本协议终止后按对方要求归还对方，或予以销毁，或进行其他处置，并且不得继续使用这些保密信息。
                        </p>
                        <p>三、不论是在本协议有效期内，还是在本协议结束以后，未经对方书面同意，甲乙双方均不得向第三者泄露在签订本协议和履行本协议的过程中所获得的另一方的任何商业秘密。双方另有约定的除外。</p>
                        <p>四、本协议所指商业秘密的范围包括但不限于：电子及书面经营数据、客户信息、财务票据信息、本协议的条款以及本协议有关的附件内容、甲乙双方的谈判内容、往来文件等。</p>
                        <p>五、任何一方均应告知并督促其因履行本协议之目的而必须获知本协议内容及因合作而获知对方商业秘密的雇员、代理人、顾问等遵守保密条款，并对其雇员、代理人、顾问等的行为承担责任。</p>
                        <p>六、千越医助手商城依据本协议约定使用商家相关数据时，不受本条的保密限制。</p>
                        <p>第十六条 违约及赔偿责任</p>
                        <p>一、商家的经营导致纠纷或政府部门查处的，商家应按千越医助手商城的要求提供相应的证明材料，必要时，应自行或由千越医助手商城协助或授权千越医助手商城及/或其关联公司/机构处理解决。由此导致的千越医助手商城及/或其关联公司/机构损失（损失包括但不限于诉讼费、律师费、差旅费、公证费、执行费、保全费、赔偿、补偿、行政机关处罚、差旅费等），商家应足额赔偿。
                        </p>
                        <p>二、商家承诺不在千越医助手商城销售假冒商品、不合格商品、过期商品等，否则，千越医助手商城有权将订单相关材料直接移交相关监管部门。同时千越医助手商城有权对商家采取暂停向商家提供服务、暂时关闭商家后台管理账户、暂缓支付未结算款项、终止合作等措施同时千越医助手商城有权对商家采取暂停向商家提供服务、暂时关闭商家后台管理账户、暂缓支付未结算款项、终止合作等措施。商家还应全部赔偿（包括但不限于千越医助手商城对客户的赔偿、补偿、行政部门的处罚、律师费诉讼费、公证费、执行费、保全费、鉴定费、差旅费等）。
                        </p>
                        <p>三、商家不论采取何种方式将千越医助手商城用户吸引到千越医助手商城以外的平台或场所进行交易或绕开指定付款方式进行交易的，以及非法获取千越医助手商城数据、利用千越医助手商城谋取不正当利益或从事非法活动的，千越医助手商城保留向商家继续追究违约责任的权利。
                        </p>
                        <p>四、乙方销售货品价格低于采购价，经甲方通知期限内未完成整改的，甲方有权就该项药品采取下架处理，并且根据严重程度追究违约责任。</p>
                        <p>五、商家发生违反本协议情形时，千越医助手商城除有权按照本条约定要求商家承担违约责任外，还有权依据本协议其他约定解除本协议等措施。</p>
                        <p>六、本条中涉及的违约金及赔偿金，商家应在5日内向千越医助手商城支付，否则千越医助手商城有权自商家未结算货款中直接予以扣除，本协议另有约定的除外。</p>
                        <p>七、由于乙方原因给甲方造成信誉损失的，其损失金额通过有资质的评估机构评估确定。</p>
                        <p>八、乙方提供虚假资质文件的，每份支付违约金壹万元，还应赔偿因此对甲方造成的全部损失。</p>
                        <p>九、如商家的运营情况不能满足千越医助手商城公布的要求（包括但不限于千越医助手商城规则等），经限期整改调整后，仍无法满足的，千越医助手商城有权解除本协议，停止向商家提供服务。</p>
                        <p>十、甲乙双方同意，具体的违约、违规、处罚细则见附件一《商家交易管理规范及处罚实施细则》。</p>
                        <p>十一、乙方违反《商家交易管理规范及处罚实施细则》（见附件一）必须接受处罚，并同意在结算账款时扣除相应处罚金，甲方有义务对乙方违规行为进行取证并告知乙方。</p>
                        <p>第十七条 争议的解决</p>
                        <p>本协议如在履行中发生争议，双方首先应友好协商解决，协商不成的,任何一方均可依法向有管辖权的甲方住所所在地人民法院提起诉讼。</p>
                        <p>第十八条 协议的生效、期限与终止</p>
                        <p>一、协议到期需要继续在千越医助手商城经营网店则需重新签订协议，未继续合作的，协议到期自动关闭乙方交易功能</p>
                        <p>二、本协议未尽事宜，甲乙双方可订立补充协议，补充协议与本协议具有同等效力。补充协议与本协议不一致的以补充协议为准。</p>
                        <p>三、本协议经双方协商一致可提前终止。</p>
                        <p>四、本协议一式二份，甲乙双方各持一份，自最后一方委托代理人签字且盖章之日起生效，有效期限共壹年。</p>
                        <p>甲方(章)：河南千越医疗科技有限公司</p>
                        <p>住所：河南省漯河市郾城区昌建东外滩A2幢2208室</p>
                        <p>法定代表人(签字或章)：</p>
                        <p>委托代理人(签字)：</p>
                        <p>年     月    日  </p>
                        <p>乙方(公章)：</p>
                        <p>住所：</p>
                        <p>法定代表人(签字或章)：</p>
                        <p>委托代理人(签字)：</p>
                        <p>年      月     日</p>
                    </el-scrollbar>
                </div>
                <div class="flex-center">
                    <el-checkbox v-model="agreement">同意商家入驻协议</el-checkbox>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="goBack">返回</el-button>
                <el-button type="primary" @click="ApplyBtn">申请店铺功能</el-button>
            </span>
        </el-dialog>
        <!--    结果反馈    -->
        <el-dialog :visible.sync="resultDialog" width="850px" :show-close="false" :close-on-click-modal="false"
            :close-on-press-escape="false" center>
            <el-result icon="success" title="您已成功提交申请！请耐心等待审核" v-if="merchantInfo.state == 0">
                <template slot="extra">
                    <p>我们将在1-3个工作日后给出结果
                        <br>我们会与企业联系人核实信息，请注意接听来电！
                        <br>谢谢您的配合！
                    </p>
                    <el-button class="btn-bottom" type="primary" @click="goBack">返回</el-button>
                </template>
            </el-result>
            <el-result icon="error" title="您的申请未通过" v-if="merchantInfo.state == -1">
                <template slot="extra">
                    <p>未通过原因：{{ merchantInfo.refusalReason }}</p>
                    <el-button class="btn-bottom" type="primary" @click="resultDialog = false; centerDialog = true">重新审核
                    </el-button>
                </template>
            </el-result>
        </el-dialog>
    </div>
</template>

<script>

import bread from "../../../components/common/breadcrumb";
import { Merchant } from "../../../components/HospitalDomain/ShopMall/Merchant";
import { Region } from '../../../components/HospitalDomain/Region';
import { Organization } from "../../../components/HospitalDomain/Organization";

export default {
    name: "ShopApply",
    computed: {
        headers() {
            return {
                "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
            }
        }
    },
    components: {
        bread
    },
    data() {
        var merchant = new Merchant(this.TokenClient, this.Services.Shopping)
        var region = new Region(this.TokenClient, this.Services.Authorization);
        var organization = new Organization(this.TokenClient, this.Services.Authorization);
        return {
            MerchantDomain: merchant,
            Region: region,
            OrganizationDomain: organization,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            loading: false,
            centerDialog: false,
            agreement: false,
            MerchantCategory: [],
            FileUploadUrl: this.CommonFilePath,
            idcardA: '',
            idcardB: '',
            dialogImageUrl: '',
            ImageContent: [],//图片列表
            ImageContentA: [],//图片列表
            ImageContentB: [],//图片列表
            ImageContentList: [],//图片上传控件加载的数据
            dialogVisible: false,
            applyForm: {
                mcommodityCategoryIds:[]
            },
            resultDialog: false,
            rules: {
                merchantName: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                mcommodityCategoryIds: [
                    { required: true, message: '请至少选择一个', trigger: 'change' }
                ],
                imageUrl: [{ required: true, message: '请上传身份证正反面', trigger: 'change' }],
                dialogImageUrl: [{ required: true, message: '请上传企业或者/个体工商营业执照照片', trigger: 'change' }],
                dialogImageUrlA: [{ required: true, message: '请上传医疗相关的生产或者经营资质 *最多可上传五张，且单张图片不得操作20M/个体工商营业执照照片', trigger: 'change' }],
                regionId: [{ required: true, message: '请选择店铺所在区域', trigger: 'change' },],
                address: [{ required: true, message: '请手动地图选点', trigger: 'change' },]
            },
            tip1: false,
            tip2: false,
            tip3: false,
            tip4: false,
            merchantInfo: {},
            searchAddresKeywords: "",
            // 百度地图参数
            addressInfo: {
                // 地址信息
                longitude: "", // 经度
                latitude: "", // 纬度
                province: "", // 省
                city: "", // 市
                district: "", // 区
                address: "", // 详细地址
            },
            location: { lng: 116.403961, lat: 39.915112 },// 设置中心点坐标lng: 116.403961, lat: 39.915112;
            zoom: 14,
            map: {},
            RegionData: [],
            QualificationList:[],
            commodityCategoryNames:[],
            commodityCategoryIds:[]
        }
    },
    mounted() {
        this.InitialRegion();
        // this.getOrgDetail();
        this.getMerchantCategory();
        this.loading = true;
        this.getMerchantSet();
    },
    methods: {
        handleCheckedCitiesChange(value){
            var _this = this;
            _this.commodityCategoryIds = []
            _this.commodityCategoryNames =  []
            console.log(value)
            for (let i=0; i<value.length;i++) {
                _this.commodityCategoryIds.push(value[i].id)
                _this.commodityCategoryNames.push(value[i].name)
            }
            _this.MerchantDomain.getQualificationCategory( _this.commodityCategoryIds.join("、"),function (data) {
                _this.QualificationList = data.data;
            }, function (err) { 
                if (err.status == '404') {
                    _this.QualificationList = []
                }
                
                
                console.log(err); });
        },
        // 搜索框的搜索事件
        confirmAddress(e) {
            let that = this
            that.map.clearOverlays(); // 移除地图上的标注
            var local = new BMap.LocalSearch(that.map, {
                //智能搜索
                onSearchComplete: function (results) {
                    let poi = results.getPoi(0); //获取第一个智能搜索的结果
                    var searchpt = poi.point; // 获取坐标
                    that.map.centerAndZoom(searchpt, 19);
                    that.map.addOverlay(new BMap.Marker(searchpt)); //添加标注
                    that.geocAddress(searchpt); // 逆地址解析
                },
            });
            // 搜索词
            local.search(e);
        },
        mapReady({ BMap, map }) {
            let that = this
            that.map = map
            map.clearOverlays(); // 移除地图上的标注
            // 定位当前位置偏差较大
            if (that.addressInfo.longitude && that.addressInfo.latitude) {
                var searchpt = {
                    lng: that.addressInfo.longitude,
                    lat: that.addressInfo.latitude
                };
                map.centerAndZoom(new BMap.Point(that.addressInfo.longitude, that.addressInfo.latitude), 15);
                var marker = new BMap.Marker(searchpt); // 创建标注
                map.addOverlay(marker); // 将标注添加到地图中
            } else {
                var geolocation = new BMap.Geolocation();
                geolocation.getCurrentPosition(function (r) {
                    if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                        map.centerAndZoom(new BMap.Point(r.point.lng, r.point.lat), 15);
                    }
                }, { enableHighAccuracy: true })
            }
            // 添加地图点击事件
            map.addEventListener("click", function (e) {
                var clickpt = e.point; // 点击的坐标
                console.log(clickpt)
                map.clearOverlays(); // 移除地图上的标注
                var marker = new BMap.Marker(clickpt); // 创建标注
                map.addOverlay(marker); // 将标注添加到地图中
                // 逆地址解析
                that.geocAddress(clickpt);
            });
        },
        /** 逆向解析地址 point */
        geocAddress(point) {
            let that = this;
            var geoc = new BMap.Geocoder();
            geoc.getLocation(point, function (e) {
                console.log('e', point)
                console.log('e', e)
                // 设置基本信息
                var addressInfo = e.addressComponents;
                that.addressInfo.longitude = point.lng;
                that.addressInfo.latitude = point.lat;
                that.addressInfo.province = addressInfo.province;
                that.addressInfo.city = addressInfo.city;
                that.addressInfo.district = addressInfo.district;
                let address = addressInfo.street + addressInfo.streetNumber;
                let addressDetial = ''
                if (e.surroundingPois.length > 0) {
                    address = address + e.surroundingPois[0].title;
                }
                addressDetial = address;
                that.addressInfo.address = address;
                that.applyForm.longitude = that.addressInfo.longitude;
                that.applyForm.latitude = that.addressInfo.latitude;
                if (e.surroundingPois.length > 0) {
                    address = address + e.surroundingPois[0].title;
                }
                that.applyForm.address = address;
                that.applyForm.addressDetial = e.address + addressDetial;
                console.log(that.applyForm.addressDetial)
                console.log('addressInfo', that.addressInfo)
            });
        },
        InitialRegion() {
            var _this = this;
            _this.Region.Regions(null,
                function (data) { _this.RegionData = data.data; },
                function (err) { console.log(err); });
        },
        LasyLoadRegion(node, resolve) {
            var _this = this;
            _this.Region.Regions(node.value,
                function (data) {
                    for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                        data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 5;
                    }
                    resolve(data.data);
                },
                function (err) {
                    resolve([]);
                    console.log(err);
                });
        },
        // ？？？
        getOrgDetail() {
            var _this = this;
            _this.OrganizationDomain.OrgDetail(_this.organizationId, function (data) {
                _this.applyForm.categoryId = data.data.categoryId
                _this.applyForm.categoryName = data.data.category.name
                _this.applyForm.typeId = data.data.typeId
                _this.applyForm.typeName = data.data.type.name
                console.log(_this.applyForm)
            }, function (error) {
                console.log(error);
            })
        },
        getMerchantSet() {
            var _this = this;
            _this.MerchantDomain.MerchantState(function (data) {
                if (data.code == '200') {
                    _this.loading = true;
                    _this.centerDialog = false;
                    _this.merchantInfo = data.data
                    if (_this.merchantInfo.state == 1) {
                        _this.$alert('店铺已申请成功', '提示', {
                            confirmButtonText: '我知道了',
                            callback: action => {
                                _this.$router.go(-1);
                            }
                        });
                    } else if (_this.merchantInfo.state == 0) {
                        // 待审核
                        _this.resultDialog = true
                        _this.loading = false;
                    } else if (_this.merchantInfo.state == -1) {
                        // 拒绝
                        _this.resultDialog = true
                        _this.loading = false;
                    }

                }

            }, function (err) {
                _this.loading = false;
                if (err.status != "403") {
                    _this.centerDialog = true;
                }

            });
        },
        ApplyBtn() {
            if (!this.agreement) {
                this.$alert('请同意商家入驻协议', '提示', {
                    confirmButtonText: '确定',
                });
            } else {
                this.centerDialog = false;
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        /*toIndex(){
            this.$router.go
        },*/
        getMerchantCategory() {
            var _this = this;
            _this.MerchantDomain.MerchantCategory(function (data) {
                _this.MerchantCategory = data.data;
            }, function (err) { console.log(err); });
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            var filterArr = this.ImageContent
            for (let i = 0; i < filterArr.length; i++) {
                if (filterArr[i].files[0].fileName.indexOf(file.response[0].fileName) > -1) {
                    // 判断 id 为 4 的对象是否存在，
                    filterArr.splice(i, 1) // 存在即删除
                }
            }
            console.log(this.ImageContent)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            console.log(this.dialogImageUrl)
            this.dialogVisible = true;
        },
        handleImageUpload(response, file, fileList) {
            var _this = this;
                var imgarr = {
                    "filePath": response[0].completeFilePath,
                    "fileName": response[0].fileName,
                    "type":0
                }
                _this.ImageContent.push(imgarr);

            console.log(_this.ImageContent)
        },
        handlePictureCardAPreview(file) {
            this.dialogImageUrl = file.url;
            console.log(this.dialogImageUrl)
            this.dialogVisible = true;
        },
        handleImageUploadA(res, file,item, index) {
            var _this = this;
            console.log(res)
            var responseList = {}
            item.applyFiles = item.applyFiles ? item.applyFiles : []
            responseList.fileName = res[0].fileName
            responseList.type = 0
            responseList.filePath = res[0].completeFilePath
            item.applyFiles.push(responseList)
            console.log(item)
        },
        handleRemoveA(file,item) {
            for (let i = 0; i < item.applyFiles.length; i++) {
                if (item.applyFiles[i].fileName.indexOf(file.response[0].fileName) != -1) {
                    // 判断 id 为 4 的对象是否存在，
                    item.applyFiles.splice(i, 1) // 存在即删除
                }
            }
            console.log(item)
        },
        handleIdCardASuccess(res, file) {
            this.idcardA = URL.createObjectURL(file.raw);
            var imgarr = {
                "files": [{
                    "filePath": file.response[0].completeFilePath,
                    "fileName": file.response[0].fileName
                }],
                "type": 1
            }
            this.ImageContentB.push(imgarr)
        },
        handleIdCardBSuccess(res, file) {
            this.idcardB = URL.createObjectURL(file.raw);
            var imgarr = {
                "files": [{
                    "filePath": file.response[0].completeFilePath,
                    "fileName": file.response[0].fileName,
                    "type":0
                }],
                "type": 0,
                "qualificationName":'法人代表授权书'

            }
            this.ImageContentB.push(imgarr)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传图片只能是 JPG/PNG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        saveBtn(formName) {
            var _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    var params = []
                    for(let i=0; i<_this.QualificationList.length; i++) {
                        params.push({
                            qualificationName: _this.QualificationList[i].qualificationName,
                            files: _this.QualificationList[i].applyFiles ? _this.QualificationList[i].applyFiles : [],
                             type: 0
                        })
                    }
                    _this.applyForm.merchantApplyFiles = params
                    _this.applyForm.merchantApplyFiles.push({
                            type: 0,
                            qualificationName:'法人代表授权书',
                            files:_this.ImageContent
                    })
                    console.log(_this.commodityCategoryNames)
                    console.log(_this.commodityCategoryIds)
                    _this.applyForm.commodityCategoryNames = _this.commodityCategoryNames.join("、")
                    _this.applyForm.commodityCategoryIds = _this.commodityCategoryIds.join("、")
                    console.log(_this.applyForm)
                    _this.MerchantDomain.GetExistsMerchantName(_this.applyForm.merchantName, function (data) {

                        _this.MerchantDomain.TeamPerformanceApply(_this.applyForm, function (data) {
                            _this.resultDialog = true
                            _this.merchantInfo.state = 0
                        }, function (err) { console.log(err); });

                    }, function (err) { 
                        _this.$message.error('店铺名称重复');
                        console.log(err);

                    });

          
                } 
                // else {
                //     console.log('error submit!!');
                //     return false;
                // }
            });
        }
    }
}
</script>

<style scoped>
.applyForm {
    width: 80%;
}

.applyForm .el-upload__tip {
    color: red;
}

.applyForm .el-upload {
    float: left;
}

.bm-view {
    display: block;
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}

.BMap_noprint.anchorTL {
    width: 22%;
    top: 10px !important;
    left: 10px !important;
}
</style>
<style>
.avatar-uploader {
    float: left;
    margin-right: 50px;
}

.avatar-uploader .el-upload {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;

    vertical-align: top;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 146px;
    text-align: center;
}

.avatar {
    width: 148px;
    height: 148px;
    display: block;
}

.btn-bottom {
    margin: 20px 0;
}
</style>
