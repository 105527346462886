<template>
    <div class="height1" v-loading="loading">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="$router.go(-1)" content="资质变更">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body" style="padding-left:100px">
                <el-scrollbar class="left-nav-wrap" style="height:100%;">
                    <el-form class="applyForm" ref="applyFormref" label-position="top" :model="applyForm"
                        label-width="150px">
                        <el-form-item label="店铺名称" prop="merchantName">
                            <el-input class="w-18" v-model="applyForm.merchantName" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="商家类型" prop="mcommodityCategoryIds">
                            <el-checkbox-group v-model="applyForm.mcommodityCategoryIds"
                                @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="(item, i) in MerchantCategory" :label="item.id" :key="i"
                                    name='mcommodityCategoryIds'>{{ item.name }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="法人代表授权书">
                            <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card"
                                :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                                :on-success="handleImageUpload" :limit="5" :file-list="fileList">
                                <i class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip" v-if="tip1">请上传企业或者/个体工商营业执照照片</div>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                        <el-form-item :label="itemQua.qualificationName" v-for="(itemQua, inexQua) in QualificationList"
                            :key="inexQua">
                            <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card"
                                :on-preview="handlePictureCardPreview" :on-remove="(file) => {
                                    return handleRemoveA(file, itemQua)
                                }" :file-list="itemQua.fileList"
                                :on-success="(res, file) => { return handleImageUploadA(res, file, itemQua, inexQua) }" :limit="5">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="itemQua.dialogImageUrl" alt="">
                            </el-dialog>
                            <div>资质描述：{{ itemQua.description }}</div>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="saveBtn('applyFormref')">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-scrollbar>

            </div>
        </div>
    </div>
</template>

<script>

import bread from "../../../components/common/breadcrumb"
import { Merchant } from "../../../components/HospitalDomain/ShopMall/Merchant"
import { Region } from '../../../components/HospitalDomain/Region'
import { Organization } from "../../../components/HospitalDomain/Organization"

export default {
    name: "ShopApply",
    computed: {
        headers () {
            return {
                "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
            }
        }
    },
    components: {
        bread
    },
    data () {
        var merchant = new Merchant(this.TokenClient, this.Services.Shopping)
        var region = new Region(this.TokenClient, this.Services.Authorization)
        var organization = new Organization(this.TokenClient, this.Services.Authorization)
        return {
            MerchantDomain: merchant,
            Region: region,
            OrganizationDomain: organization,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            loading: false,
            centerDialog: false,
            agreement: false,
            MerchantCategory: [],
            FileUploadUrl: this.CommonFilePath,
            idcardA: '',
            idcardB: '',
            dialogImageUrl: '',
            ImageContent: [],//图片列表
            ImageContentA: [],//图片列表
            ImageContentB: [],//图片列表
            ImageContentList: [],//图片上传控件加载的数据
            dialogVisible: false,
            applyForm: {
                merchantName:'',
                mcommodityCategoryIds: []
            },
            resultDialog: false,
            rules: {
                merchantName: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                mcommodityCategoryIds: [
                    { required: true, message: '请至少选择一个', trigger: 'change' }
                ],
                imageUrl: [{ required: true, message: '请上传身份证正反面', trigger: 'change' }],
                dialogImageUrl: [{ required: true, message: '请上传企业或者/个体工商营业执照照片', trigger: 'change' }],
                dialogImageUrlA: [{ required: true, message: '请上传医疗相关的生产或者经营资质 *最多可上传五张，且单张图片不得操作20M/个体工商营业执照照片', trigger: 'change' }],
                regionId: [{ required: true, message: '请选择店铺所在区域', trigger: 'change' },],
                address: [{ required: true, message: '请手动地图选点', trigger: 'change' },]
            },
            tip1: false,
            tip2: false,
            tip3: false,
            tip4: false,
            merchantInfo: {},
            searchAddresKeywords: "",
            // 百度地图参数
            addressInfo: {
                // 地址信息
                longitude: "", // 经度
                latitude: "", // 纬度
                province: "", // 省
                city: "", // 市
                district: "", // 区
                address: "", // 详细地址
            },
            location: { lng: 116.403961, lat: 39.915112 },// 设置中心点坐标lng: 116.403961, lat: 39.915112;
            zoom: 14,
            map: {},
            RegionData: [],
            QualificationList: [],
            commodityCategoryNames: [],
            commodityCategoryIds: [],
            fileList: [],
            ShopData: []
        }
    },
    mounted () {
        // this.InitialRegion();
        // // this.getOrgDetail();
        this.getMerchantCategory()
        // this.loading = true;
        // this.getMerchantSet();
        this.getShopDetail()
    },
    methods: {
        getShopDetail () {
            var _this = this
            _this.MerchantDomain.GetShopQualificationData(_this.organizationId, function (data) {
                _this.ShopData = data.data
                _this.applyForm.merchantName = data.data.merchantName
                if (data.data.commodityCategoryIds) {
                    _this.applyForm.mcommodityCategoryIds = data.data.commodityCategoryIds.split('、')
                    for (let i = 0; i < _this.applyForm.mcommodityCategoryIds.length; i++) {
                        _this.applyForm.mcommodityCategoryIds[i] = Number(_this.applyForm.mcommodityCategoryIds[i])
                    }
                    _this.commodityCategoryIds = _this.applyForm.mcommodityCategoryIds
                    _this.commodityCategoryNames = data.data.commodityCategoryNames ? data.data.commodityCategoryNames.split('、') : []
                    _this.commodityCategoryNames=[...new Set(_this.commodityCategoryNames)]
                    for (let j = 0; j < data.data.merchantApplyFiles.length; j++) {
                        if (data.data.merchantApplyFiles[j].qualificationName == '法人代表授权书') {
                            for (let k = 0; k < data.data.merchantApplyFiles[j].files.length; k++) {
                                _this.fileList.push({ url: _this.Services.Shopping + data.data.merchantApplyFiles[j].files[k].photoThumbnail })
                                _this.ImageContent.push({
                                    "filePath": data.data.merchantApplyFiles[j].files[k].filePath,
                                    "fileName": data.data.merchantApplyFiles[j].files[k].fileName,
                                    "type": 0
                                })
                            }
                        }
                    }
                    _this.MerchantDomain.getQualificationCategory(data.data.commodityCategoryIds, function (data) {
                        _this.QualificationList = data.data
                        for (let m = 0; m < _this.QualificationList.length; m++) {
                            for (let n = 0; n < _this.ShopData.merchantApplyFiles.length; n++) {
                                if (_this.QualificationList[m].qualificationName == _this.ShopData.merchantApplyFiles[n].qualificationName) {
                                    let photoList = []
                                    let applyFilesList = []
                                    for (let x = 0; x < _this.ShopData.merchantApplyFiles[n].files.length; x++) {
                                        photoList.push({ url: _this.Services.Shopping + _this.ShopData.merchantApplyFiles[n].files[x].photoThumbnail })
                                        applyFilesList.push({
                                            "filePath": _this.ShopData.merchantApplyFiles[n].files[x].filePath,
                                            "fileName": _this.ShopData.merchantApplyFiles[n].files[x].fileName,
                                            "type": 0
                                        })
                                    }
                                    _this.QualificationList[m].fileList = photoList
                                    _this.QualificationList[m].applyFiles = applyFilesList
                                }
                            }

                        }
                    }, function (err) {
                        if (err.status == '404') {
                            _this.QualificationList = []
                        }
                        console.log(err)
                    })
                }

            }, function (err) { console.log(err) })
        },
        handleCheckedCitiesChange (value) {
            var _this = this
            _this.commodityCategoryIds = value
            _this.MerchantDomain.getQualificationCategory(_this.commodityCategoryIds.join("、"), function (data) {
                _this.QualificationList = data.data
                for (let m = 0; m < _this.QualificationList.length; m++) {
                    for (let n = 0; n < _this.ShopData.merchantApplyFiles.length; n++) {
                        if (_this.QualificationList[m].qualificationName == _this.ShopData.merchantApplyFiles[n].qualificationName) {
                            let photoList = []
                            let applyFilesList = []
                            for (let x = 0; x < _this.ShopData.merchantApplyFiles[n].files.length; x++) {
                                photoList.push({ url: _this.Services.Shopping + _this.ShopData.merchantApplyFiles[n].files[x].photoThumbnail })
                                applyFilesList.push({
                                    "filePath": _this.ShopData.merchantApplyFiles[n].files[x].filePath,
                                    "fileName": _this.ShopData.merchantApplyFiles[n].files[x].fileName,
                                    "type": 0
                                })
                            }
                            _this.QualificationList[m].fileList = photoList
                            _this.QualificationList[m].applyFiles = applyFilesList
                        }
                    }

                }
            }, function (err) {
                if (err.status == '404') {
                    _this.QualificationList = []
                }
                console.log(err)
            })
        },
        // 搜索框的搜索事件
        confirmAddress (e) {
            let that = this
            that.map.clearOverlays() // 移除地图上的标注
            var local = new BMap.LocalSearch(that.map, {
                //智能搜索
                onSearchComplete: function (results) {
                    let poi = results.getPoi(0) //获取第一个智能搜索的结果
                    var searchpt = poi.point // 获取坐标
                    that.map.centerAndZoom(searchpt, 19)
                    that.map.addOverlay(new BMap.Marker(searchpt)) //添加标注
                    that.geocAddress(searchpt) // 逆地址解析
                },
            })
            // 搜索词
            local.search(e)
        },
        mapReady ({ BMap, map }) {
            let that = this
            that.map = map
            map.clearOverlays() // 移除地图上的标注
            // 定位当前位置偏差较大
            if (that.addressInfo.longitude && that.addressInfo.latitude) {
                var searchpt = {
                    lng: that.addressInfo.longitude,
                    lat: that.addressInfo.latitude
                }
                map.centerAndZoom(new BMap.Point(that.addressInfo.longitude, that.addressInfo.latitude), 15)
                var marker = new BMap.Marker(searchpt) // 创建标注
                map.addOverlay(marker) // 将标注添加到地图中
            } else {
                var geolocation = new BMap.Geolocation()
                geolocation.getCurrentPosition(function (r) {
                    if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                        map.centerAndZoom(new BMap.Point(r.point.lng, r.point.lat), 15)
                    }
                }, { enableHighAccuracy: true })
            }
            // 添加地图点击事件
            map.addEventListener("click", function (e) {
                var clickpt = e.point // 点击的坐标
                console.log(clickpt)
                map.clearOverlays() // 移除地图上的标注
                var marker = new BMap.Marker(clickpt) // 创建标注
                map.addOverlay(marker) // 将标注添加到地图中
                // 逆地址解析
                that.geocAddress(clickpt)
            })
        },
        /** 逆向解析地址 point */
        geocAddress (point) {
            let that = this
            var geoc = new BMap.Geocoder()
            geoc.getLocation(point, function (e) {
                console.log('e', point)
                console.log('e', e)
                // 设置基本信息
                var addressInfo = e.addressComponents
                that.addressInfo.longitude = point.lng
                that.addressInfo.latitude = point.lat
                that.addressInfo.province = addressInfo.province
                that.addressInfo.city = addressInfo.city
                that.addressInfo.district = addressInfo.district
                let address = addressInfo.street + addressInfo.streetNumber
                let addressDetial = ''
                if (e.surroundingPois.length > 0) {
                    address = address + e.surroundingPois[0].title
                }
                addressDetial = address
                that.addressInfo.address = address
                that.applyForm.longitude = that.addressInfo.longitude
                that.applyForm.latitude = that.addressInfo.latitude
                if (e.surroundingPois.length > 0) {
                    address = address + e.surroundingPois[0].title
                }
                that.applyForm.address = address
                that.applyForm.addressDetial = e.address + addressDetial
                console.log(that.applyForm.addressDetial)
                console.log('addressInfo', that.addressInfo)
            })
        },
        InitialRegion () {
            var _this = this
            _this.Region.Regions(null,
                function (data) { _this.RegionData = data.data },
                function (err) { console.log(err) })
        },
        LasyLoadRegion (node, resolve) {
            var _this = this
            _this.Region.Regions(node.value,
                function (data) {
                    for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                        data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 5
                    }
                    resolve(data.data)
                },
                function (err) {
                    resolve([])
                    console.log(err)
                })
        },
        // ？？？
        getOrgDetail () {
            var _this = this
            _this.OrganizationDomain.OrgDetail(_this.organizationId, function (data) {
                _this.applyForm.categoryId = data.data.categoryId
                _this.applyForm.categoryName = data.data.category.name
                _this.applyForm.typeId = data.data.typeId
                _this.applyForm.typeName = data.data.type.name
            }, function (error) {
                console.log(error)
            })
        },
        getMerchantSet () {
            var _this = this
            _this.MerchantDomain.MerchantState(function (data) {
                if (data.code == '200') {
                    _this.loading = true
                    _this.centerDialog = false
                    _this.merchantInfo = data.data
                    if (_this.merchantInfo.state == 1) {
                        _this.$alert('店铺已申请成功', '提示', {
                            confirmButtonText: '我知道了',
                            callback: action => {
                                _this.$router.go(-1)
                            }
                        })
                    } else if (_this.merchantInfo.state == 0) {
                        // 待审核
                        _this.resultDialog = true
                        _this.loading = false
                    } else if (_this.merchantInfo.state == -1) {
                        // 拒绝
                        _this.resultDialog = true
                        _this.loading = false
                    }

                }

            }, function (err) {
                _this.loading = false
                if (err.status != "403") {
                    _this.centerDialog = true
                }

            })
        },
        ApplyBtn () {
            if (!this.agreement) {
                this.$alert('请同意商家入驻协议', '提示', {
                    confirmButtonText: '确定',
                })
            } else {
                this.centerDialog = false
            }
        },
        goBack () {
            this.$router.go(-1)
        },
        getMerchantCategory () {
            var _this = this
            _this.MerchantDomain.MerchantCategory(function (data) {
                _this.MerchantCategory = data.data
            }, function (err) { console.log(err) })
        },
        handleRemove (file, fileList) {
            console.log(file, fileList)
            var filterArr = this.ImageContent
            for (let i = 0; i < filterArr.length; i++) {
                if (filterArr[i].files[0].fileName.indexOf(file.response[0].fileName) > -1) {
                    // 判断 id 为 4 的对象是否存在，
                    filterArr.splice(i, 1) // 存在即删除
                }
            }
            console.log(this.ImageContent)
        },
        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url
            console.log(this.dialogImageUrl)
            this.dialogVisible = true
        },
        handleImageUpload (response, file, fileList) {
            var _this = this
            var imgarr = {
                "filePath": response[0].completeFilePath,
                "fileName": response[0].fileName,
                "type": 0
            }
            _this.ImageContent.push(imgarr)
        },
        handlePictureCardAPreview (file) {
            this.dialogImageUrl = file.url
            console.log(this.dialogImageUrl)
            this.dialogVisible = true
        },
        handleImageUploadA (res, file, item, index) {
            var _this = this
            var responseList = {}
            item.applyFiles = item.applyFiles ? item.applyFiles : []
            responseList.fileName = res[0].fileName
            responseList.type = 0
            responseList.filePath = res[0].completeFilePath
            item.applyFiles.push(responseList)
        },
        handleRemoveA (file, item) {
            for (let i = 0; i < item.applyFiles.length; i++) {
                if (item.applyFiles[i].fileName.indexOf(file.response[0].fileName) != -1) {
                    // 判断 id 为 4 的对象是否存在，
                    item.applyFiles.splice(i, 1) // 存在即删除
                }
            }
            console.log(item)
        },
        handleIdCardASuccess (res, file) {
            this.idcardA = URL.createObjectURL(file.raw)
            var imgarr = {
                "files": [{
                    "filePath": file.response[0].completeFilePath,
                    "fileName": file.response[0].fileName
                }],
                "type": 1
            }
            this.ImageContentB.push(imgarr)
        },
        handleIdCardBSuccess (res, file) {
            this.idcardB = URL.createObjectURL(file.raw)
            var imgarr = {
                "files": [{
                    "filePath": file.response[0].completeFilePath,
                    "fileName": file.response[0].fileName,
                    "type": 0
                }],
                "type": 0,
                "qualificationName": '法人代表授权书'

            }
            this.ImageContentB.push(imgarr)
        },
        beforeAvatarUpload (file) {
            const isJPG = file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg"
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },
        saveBtn (formName) {
            var _this = this
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    var params = []
                    _this.commodityCategoryNames=[]
                    for (let j = 0; j < _this.MerchantCategory.length; j++) {
                        for (let k = 0; k < _this.commodityCategoryIds.length; k++) {
                            if (_this.MerchantCategory[j].id == _this.commodityCategoryIds[k]) {
                                _this.commodityCategoryNames.push(_this.MerchantCategory[j].name)
                            }
                        }
                    }

                    for (let i = 0; i < _this.QualificationList.length; i++) {
                        params.push({
                            qualificationName: _this.QualificationList[i].qualificationName,
                            files: _this.QualificationList[i].applyFiles,
                            type: 0
                        })
                    }
                    _this.applyForm.merchantApplyFiles = params
                    _this.applyForm.merchantApplyFiles.push({
                        type: 0,
                        qualificationName: '法人代表授权书',
                        files: _this.ImageContent
                    })
                    console.log(_this.commodityCategoryNames)
                    console.log(_this.commodityCategoryIds)
                    _this.applyForm.commodityCategoryNames = _this.commodityCategoryNames.join("、")
                    _this.applyForm.commodityCategoryIds = _this.commodityCategoryIds.join("、")
                    console.log(_this.applyForm)
                    _this.MerchantDomain.TeamPerformanceApply(_this.applyForm, function (data) {
                        _this.$message({
                            message: '已提交，请等待审核',
                            type: 'success'
                        })
                        _this.$router.go(-1)
                        _this.resultDialog = true
                        _this.merchantInfo.state = 0
                    }, function (err) { console.log(err) })
                }
            })
        }
    }
}
</script>

<style scoped>
.applyForm {
    width: 80%;
}

.applyForm .el-upload__tip {
    color: red;
}

.applyForm .el-upload {
    float: left;
}

.bm-view {
    display: block;
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}

.BMap_noprint.anchorTL {
    width: 22%;
    top: 10px !important;
    left: 10px !important;
}
</style>
<style>
.avatar-uploader {
    float: left;
    margin-right: 50px;
}

.avatar-uploader .el-upload {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;

    vertical-align: top;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 146px;
    text-align: center;
}

.avatar {
    width: 148px;
    height: 148px;
    display: block;
}

.btn-bottom {
    margin: 20px 0;
}
</style>
