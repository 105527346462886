<template>
    <div class="">
        <!--    头部    -->
        <div class="iq-top-navbar">
            <el-page-header @back="$router.go(-1)" content="店铺设置">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <el-form ref="MerchantData" :model="MerchantData" label-width="150px" :rules="rules" class="from-box">
                    <el-card class="box-card" shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>基本信息</span>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-up" circle
                                v-if="!isDown1" @click="isDown1 = true"></el-button>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-down" circle
                                v-if="isDown1" @click="isDown1 = false"></el-button>
                            <el-button v-if="part1" class="btn" size="mini" type="primary" icon="el-icon-edit" circle
                                @click="editBtn('part1')">
                            </el-button>
                            <el-button v-else class="btn" size="mini" type="success" icon="el-icon-check" circle
                                @click="saveBtn('MerchantData', 'part1')">
                            </el-button>
                        </div>
                        <template v-if="!isDown1">
                            <el-form-item label="店铺ID">
                                <el-input class="w-18" v-model="MerchantData.shopNumber" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="店铺名称" prop="shopName">
                                <el-input class="w-18" v-model="MerchantData.shopName" :disabled="part1"></el-input>
                            </el-form-item>
                            <el-form-item label="客服电话" prop="customerPhone">
                                <el-input class="w-18" v-model="MerchantData.customerPhone" :disabled="part1">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="店铺形象照片" prop="customerPhone">
                                <el-upload :headers="headers" :action="FileUploadUrl" class="avatar-uploader"
                                    :show-file-list="false" :on-success="handleAvatarSuccess" :disabled="part1" accept=".png,.jpg,.jpeg,.gif">
                                    <img v-if="MerchantData.cover" :src="MerchantData.cover" class="avatar">
                                    <i v-else-if="!MerchantData.cover && !part1"
                                        class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                            <!-- <el-form-item label="店铺所在区域" prop="regionId">
                                <el-cascader
                                    :placeholder="MerchantData.region ? MerchantData.region.fullName : '请选择所在区域'"
                                    v-model="MerchantData.regionId" :options="RegionData" size="medium"
                                    :props="{ emitPath: false, multiple: false, checkStrictly: true, lazy: true, lazyLoad: LasyLoadRegion, value: 'id', label: 'regionName', children: 'childrens' }"
                                    style="margin-right: 10px;width: 100%;" :disabled="part1">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item label="详细地址" prop="address">
                                <el-input placeholder="地图选点" v-model="MerchantData.address" disabled>
                                    <template slot="prepend"> {{ addressInfo.province }} {{ addressInfo.city }}{{ addressInfo.district }}</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="补充地址">
                                <el-input placeholder="补充地址" v-model="MerchantData.addressOther" :disabled="part1">
                                    <template slot="prepend"> {{ addressInfo.province }} {{ addressInfo.city }}{{ addressInfo.district }}</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <baidu-map class="bm-view" scroll-wheel-zoom :center="location" :zoom="zoom"
                                    ak="C3L8oD0NokcKDAyhtGZgTtwF1R4eqo7S" @ready="mapReady">
                                    <bm-view class="map" style="width: 100%; height:100%; flex: 1"></bm-view>
                              
                                    <bm-control>
                                        <bm-auto-complete :sugStyle="{ zIndex: 999999 }">
                                            <el-input size="small" type="text" id="searchAddres"
                                                v-model="searchAddresKeywords" placeholder="请输入地点"
                                                @change="confirmAddress">
                                            </el-input>
                                        </bm-auto-complete>
                                    </bm-control>
                                  
                                    <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
                                    <bm-geolocation anchor="BMAP_ANCHOR_TOP_RIGHT" :showAddressBar="true"
                                        :autoLocation="true"></bm-geolocation>
                                </baidu-map>
                            </el-form-item> -->
                        </template>
                    </el-card>
                    <!-- <el-card class="box-card" shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>分销设置</span>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-up" circle
                                v-if="!isDown2" @click="isDown2 = true"></el-button>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-down" circle
                                v-if="isDown2" @click="isDown2 = false"></el-button>
                            <el-button v-if="part2" class="btn" size="mini" type="primary" icon="el-icon-edit" circle
                                @click="editBtn('part2')">
                            </el-button>
                            <el-button v-else class="btn" size="mini" type="success" icon="el-icon-check" circle
                                @click="saveBtn('MerchantData', 'part2')">
                            </el-button>
                        </div>
                        <template v-if="!isDown2">
                            <el-form-item label="是否启动两级分销" prop="isDistribution">
                                <el-switch v-model="MerchantData.isDistribution" :disabled="part2">
                                </el-switch>
                            </el-form-item>
                            <el-form-item label="" v-if="MerchantData.isDistribution">
                                <table class="_table">
                                    <tr>
                                        <td>会员等级</td>
                                        <td>一级分销%(直接上级)</td>
                                        <td>二级分销%</td>
                                    </tr>
                                    <tr>
                                        <td>普通会员获得分销</td>
                                        <td>
                                            <el-form-item label="" prop="commonFirst">
                                                <el-input type="number" v-model="MerchantData.commonFirst" size="small"
                                                    :disabled="part2">
                                                </el-input>
                                            </el-form-item>
                                        </td>
                                        <td>
                                            <el-form-item label="" prop="commonSecond">
                                                <el-input type="number" v-model="MerchantData.commonSecond" size="small"
                                                    :disabled="part2">
                                                </el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>VIP1获得分销</td>
                                        <td>
                                            <el-form-item label="" prop="vip1First">
                                                <el-input type="number" v-model="MerchantData.vip1First" size="small"
                                                    :disabled="part2">
                                                </el-input>
                                            </el-form-item>
                                        </td>
                                        <td>
                                            <el-form-item label="" prop="vip1Second">
                                                <el-input type="number" v-model="MerchantData.vip1Second" size="small"
                                                    :disabled="part2">
                                                </el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>VIP2获得分销</td>
                                        <td>
                                            <el-form-item label="" prop="vip2First">
                                                <el-input type="number" v-model="MerchantData.vip2First" size="small"
                                                    :disabled="part2">
                                                </el-input>
                                            </el-form-item>
                                        </td>
                                        <td>
                                            <el-form-item label="" prop="vip2Second">
                                                <el-input type="number" v-model="MerchantData.vip2Second" size="small"
                                                    :disabled="part2">
                                                </el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                </table>
                            </el-form-item>
                        </template>
                    </el-card> -->
                    <!-- <el-card class="box-card" shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>分享设置</span>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-up" circle
                                v-if="!isDown3" @click="isDown3 = true"></el-button>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-down" circle
                                v-if="isDown3" @click="isDown3 = false"></el-button>
                            <el-button v-if="part3" class="btn" size="mini" type="primary" icon="el-icon-edit" circle
                                @click="editBtn('part3')">
                            </el-button>
                            <el-button v-else class="btn" size="mini" type="success" icon="el-icon-check" circle
                                @click="saveBtn('MerchantData', 'part3')">
                            </el-button>
                        </div>
                        <template v-if="!isDown3">
                            <el-form-item label="启用分享佣金功能" prop="isShareDistribution">
                                <el-switch v-model="MerchantData.isShareDistribution" :disabled="part3">
                                </el-switch>
                            </el-form-item>
                        </template>
                    </el-card>
                    <el-card class="box-card" shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>分红设置</span>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-up" circle
                                v-if="!isDown5" @click="isDown5 = true"></el-button>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-down" circle
                                v-if="isDown5" @click="isDown5 = false"></el-button>
                            <el-button v-if="part5" class="btn" size="mini" type="primary" icon="el-icon-edit" circle
                                @click="editBtn('part5')">
                            </el-button>
                            <el-button v-else class="btn" size="mini" type="success" icon="el-icon-check" circle
                                @click="saveBtn('MerchantData', 'part5')">
                            </el-button>
                        </div>
                        <template v-if="!isDown5">
                            <el-form-item label="分红模式设置">
                                <el-radio-group v-model="MerchantData.publicShareModel" @change="changePublicShareModel"
                                    :disabled="part5">
                                    <el-radio :label="0">统一分红</el-radio>
                                    <el-radio :label="1">团队分红</el-radio>
                                    <el-radio :label="2">交易用户分红</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="团队分红" v-if="MerchantData.publicShareModel == 1">
                                <el-checkbox-group v-model="publicShareType" :disabled="part5">
                                    <el-checkbox label="0">机构代分</el-checkbox>
                                    <el-checkbox label="1">团长分红</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="会员分红倍数" prop="shareOutBonus">
                                <div class="isShare-box">
                                    <div>
                                        <el-input-number :precision="2" v-model="MerchantData.shareOutBonus"
                                            controls-position="right" :min="1" :disabled="part5" />
                                        倍（最小1）。
                                    </div>
                                </div>
                            </el-form-item>
                        </template>
                    </el-card>
                    <el-card class="box-card" shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>团队绩效奖励设置</span>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-up" circle
                                v-if="!isDown6" @click="isDown6 = true"></el-button>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-down" circle
                                v-if="isDown6" @click="isDown6 = false"></el-button>
                            <el-button v-if="part6" class="btn" size="mini" type="primary" icon="el-icon-edit" circle
                                @click="editBtn('part6')">
                            </el-button>
                            <el-button v-else class="btn" size="mini" type="success" icon="el-icon-check" circle
                                @click="saveBtn('teamPerformance', 'part6')">
                            </el-button>
                        </div>
                        <template v-if="!isDown6">
                            <el-form ref="teamPerformance" :model="teamPerformance" :rules="rules">
                                <el-form-item label="上次修改时间" v-if="lastChangePerformanceRewardTime">
                                    {{ lastChangePerformanceRewardTime | timefilters }}<span
                                        style="color:red;font-size: 12px;">（一个自然年内仅可设置一次，请谨慎操作!）</span>
                                </el-form-item>
                                <el-form-item label="启用团队绩效奖励" prop="isTeamPerformanceReward">
                                    <el-switch v-model="isTeamPerformanceReward" :disabled="part6"
                                        @change="changeTeamPerformanceReward">
                                    </el-switch>
                                </el-form-item>
                                <el-form-item label="绩效周期" v-if="isTeamPerformanceReward">
                                    <el-radio-group v-model="teamPerformance.performanceRewardCycle"
                                        @change="changePerformanceRewardCycle" :disabled="part6">
                                        <el-radio :label="1">按月统计</el-radio>
                                        <el-radio :label="2">按季度统计</el-radio>
                                        <el-radio :label="3">按年统计</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="奖励比例" v-if="isTeamPerformanceReward">
                                    <div style="color:red;font-size: 12px;">（起始值小于等于消费金额小于结束值）</div>
                                    <el-table class="tab" :data="teamPerformance.performanceRewardScale" border
                                        style="width: 100%">
                                        <el-table-column prop="startValue" align="center" label="起始值（元）">
                                            <template slot-scope="scope">
                                                <el-form-item label="">
                                                    <el-input v-model="scope.row.startValue" size="small" disabled
                                                        placeholder="起始值（元"></el-input>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="endValue" align="center" label="结束值（元）">
                                            <template slot-scope="scope">
                                                <el-form-item label=""
                                                    :prop="'performanceRewardScale[' + scope.$index + '].endValue'"
                                                    :rules='rules.endValue'>
                                                    <el-input v-model="scope.row.endValue" size="small"
                                                        :disabled="part6" placeholder="结束值（元）"></el-input>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="scopeRatio" align="center" label="比例（%）">
                                            <template slot-scope="scope">
                                                <el-form-item label=""
                                                    :prop="'performanceRewardScale[' + scope.$index + '].scopeRatio'"
                                                    :rules='rules.scopeRatio'>
                                                    <el-input v-model="scope.row.scopeRatio" size="small"
                                                        :disabled="part6" placeholder="比例（%）"></el-input>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column width="150" align="center" v-if="!part6">
                                            <template slot-scope="scope">
                                                <el-button type="danger" size="mini" plain
                                                    @click="handleDelete(scope.row, scope.$index)">
                                                    <i class="el-icon-delete" aria-hidden="true"></i>
                                                </el-button>
                                            </template>
                                            <template slot="header">
                                                <el-button size="mini" @click="handleAdd()" type="primary" plain round>
                                                    添加区间比例
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-card> -->
                    <el-card class="box-card" shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>积分换购设置</span>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-up" circle
                                v-if="!isDown7" @click="isDown7 = true"></el-button>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-down" circle
                                v-if="isDown7" @click="isDown7 = false"></el-button>
                            <el-button v-if="part7" class="btn" size="mini" type="primary" icon="el-icon-edit" circle
                                @click="editBtn('part7')">
                            </el-button>
                            <el-button v-else class="btn" size="mini" type="success" icon="el-icon-check" circle
                                @click="saveBtn('MerchantData', 'part7')">
                            </el-button>
                        </div>
                        <template v-if="!isDown7">
                            <el-form-item label="启用积分换购" prop="isShareDistribution">
                                <el-switch v-model="MerchantData.huangou" :disabled="part7">
                                </el-switch>
                            </el-form-item>
                        </template>
                    </el-card>
                    <el-card class="box-card" shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>收益入账时间设置</span>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-up" circle
                                v-if="!isDown4" @click="isDown4 = true"></el-button>
                            <el-button class="btn" size="mini" type="info" icon="el-icon-arrow-down" circle
                                v-if="isDown4" @click="isDown4 = false"></el-button>
                            <el-button v-if="part4" class="btn" size="mini" type="primary" icon="el-icon-edit" circle
                                @click="editBtn('part4')">
                            </el-button>
                            <el-button v-else class="btn" size="mini" type="success" icon="el-icon-check" circle
                                @click="saveBtn('MerchantData', 'part4')">
                            </el-button>
                        </div>
                        <template v-if="!isDown4">
                            <el-form-item label="订单确认收货" prop="recordingDuration">
                                <div class="isShare-box">
                                    <div>
                                        <el-input-number :precision="0" v-model="MerchantData.recordingDuration"
                                            controls-position="right" :min="0" :disabled="part4" />
                                        天，收益入账。
                                    </div>
                                </div>
                            </el-form-item>
                        </template>
                    </el-card>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Merchant } from "../../../components/HospitalDomain/ShopMall/Merchant";
import { Region } from '../../../components/HospitalDomain/Region';

export default {
    name: "Settings",
    computed: {
        headers() {
            return {
                "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
            }
        }
    },
    components: {
        bread
    },
    data() {
        var region = new Region(this.TokenClient, this.Services.Authorization);
        var merchant2 = new Merchant(this.TokenClient, this.Services.Authorization)
        var merchant = new Merchant(this.TokenClient, this.Services.Shopping)
        var tag = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
        // 绩效比例验证
        var scopeRatioTest = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入比例'));
            }
            if (!tag.test(value)) {
                return callback(new Error('小数点后保留两位'));
            }
            if (value > 100) {
                return callback(new Error('比例不能超过100'));
            }
            callback();
        }
        return {
            Region: region,
            MerchantDomain: merchant,
            MerchantDomain2: merchant2,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            MerchantData: {
                open: false,
                share: false,
                dividend: false,
                shareData: [
                    { name: '普通会员获得分销', one: 1, two: 2 },
                    { name: 'VIP1获得分销', one: 1, two: 2 },
                    { name: 'VIP2获得分销', one: 1, two: 2 }
                ],
                recordingDuration: 0,
                publicShareModel: 0,//0统一分红，1团队分红,2交易用户分红
                publicShareType: '',
                shareOutBonus: 1,
                regionId: '',
                address: "",
                addressOther: ""
            },
            publicShareType: ['0'],
            edit: false,
            imageUrl: '',
            FileUploadUrl: this.CommonFilePath,
            srcList: [],
            part1: true,
            part2: true,
            part3: true,
            part4: true,
            part5: true,
            part6: true,
            part7: true,
            isDown1: false,
            isDown2: false,
            isDown3: false,
            isDown4: false,
            isDown5: false,
            isDown6: false,
            isDown7: false,
            isTeamPerformanceReward: true,
            teamPerformance: {
                performanceRewardCycle: 1,
                performanceRewardScale: [
                    {
                        rank: 1,
                        startValue: 0,
                        endValue: null,
                        scopeRatio: 0
                    }
                ]
            },
            lastChangePerformanceRewardTime: null,
            rules: {},
            searchAddresKeywords: "",
            // 百度地图参数
            addressInfo: {
                // 地址信息
                longitude: "", // 经度
                latitude: "", // 纬度
                province: "", // 省
                city: "", // 市
                district: "", // 区
                address: "", // 详细地址
            },
            location: { lng: 116.403961, lat: 39.915112 },// 设置中心点坐标lng: 116.403961, lat: 39.915112;
            zoom: 14,
            map: {},
            RegionData: [],
        }
    },
    mounted() {
        this.InitialRegion();
        this.getDetail();
    },
    methods: {
        // 搜索框的搜索事件
        confirmAddress(e) {
            let that = this
            that.map.clearOverlays(); // 移除地图上的标注
            var local = new BMap.LocalSearch(that.map, {
                //智能搜索
                onSearchComplete: function (results) {
                    let poi = results.getPoi(0); //获取第一个智能搜索的结果
                    var searchpt = poi.point; // 获取坐标
                    that.map.centerAndZoom(searchpt, 19);
                    that.map.addOverlay(new BMap.Marker(searchpt)); //添加标注
                    that.geocAddress(searchpt); // 逆地址解析
                },
            });
            // 搜索词
            local.search(e);
        },
        mapReady({ BMap, map }) {
            let that = this
            that.map = map
            map.clearOverlays(); // 移除地图上的标注
            // 定位当前位置偏差较大
            if (that.addressInfo.longitude && that.addressInfo.latitude) {
                var searchpt = {
                    lng: that.addressInfo.longitude,
                    lat: that.addressInfo.latitude
                };
                map.centerAndZoom(new BMap.Point(that.addressInfo.longitude, that.addressInfo.latitude), 15);
                var marker = new BMap.Marker(searchpt); // 创建标注
                map.addOverlay(marker); // 将标注添加到地图中
            } else {
                var geolocation = new BMap.Geolocation();
                geolocation.getCurrentPosition(function (r) {
                    if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                        map.centerAndZoom(new BMap.Point(r.point.lng, r.point.lat), 15);
                    }
                }, { enableHighAccuracy: true })
            }
            // 添加地图点击事件
            map.addEventListener("click", function (e) {
                var clickpt = e.point; // 点击的坐标
                console.log(clickpt)
                map.clearOverlays(); // 移除地图上的标注
                var marker = new BMap.Marker(clickpt); // 创建标注
                map.addOverlay(marker); // 将标注添加到地图中
                // 逆地址解析
                that.geocAddress(clickpt);
            });

        },
        /** 逆向解析地址 point */
        geocAddress(point) {
            let that = this;
            if (!that.part1) {
                var geoc = new BMap.Geocoder();
                geoc.getLocation(point, function (e) {
                    console.log('e', point)
                    console.log('e', e)
                    // 设置基本信息
                    var addressInfo = e.addressComponents;
                    that.addressInfo.longitude = point.lng;
                    that.addressInfo.latitude = point.lat;
                    that.addressInfo.province = addressInfo.province;
                    that.addressInfo.city = addressInfo.city;
                    that.addressInfo.district = addressInfo.district;
                    let address = addressInfo.street + addressInfo.streetNumber;
                    let addressDetial = ''
                    if (e.surroundingPois.length > 0) {
                        address = address + e.surroundingPois[0].title;
                    }
                    addressDetial = address;
                    that.addressInfo.address = address;
                    that.MerchantData.longitude = that.addressInfo.longitude;
                    that.MerchantData.latitude = that.addressInfo.latitude;
                    if (e.surroundingPois.length > 0) {
                        address = address + e.surroundingPois[0].title;
                    }
                    that.MerchantData.address = address;
                    that.MerchantData.addressDetial = e.address + addressDetial;
                    console.log(that.MerchantData.addressDetial)
                    console.log('addressInfo', that.addressInfo)
                });
            }

        },
        InitialRegions(regionId, success, error) {
            var _this = this;
            console.log(regionId)
            _this.Region.RegionsDetail(regionId,
                function (data) {
                    _this.MerchantData.region = data.data
                },
                function (err) {
                    console.log(err);
                    if (error) { error(err); }
                });
        },
        InitialRegion() {
            var _this = this;
            _this.Region.Regions(null,
                function (data) { _this.RegionData = data.data; },
                function (err) { console.log(err); });
        },
        LasyLoadRegion(node, resolve) {
            var _this = this;
            _this.Region.Regions(node.value,
                function (data) {
                    for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                        data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 5;
                    }
                    resolve(data.data);
                },
                function (err) {
                    resolve([]);
                    console.log(err);
                });
        },
        editBtn(partName) {
            // 保证只有一个card在编辑
            if (this.part1 && this.part2 && this.part3 && this.part4 && this.part5 && this.part6 && this.part7) {
                switch (partName) {
                    case 'part1':
                        this.part1 = false;
                        this.rules = {
                            regionId: [
                                { required: true, message: '请选择地址', trigger: 'change' }
                            ],
                            address: [
                                { required: true, message: '请地图选点', trigger: 'blur' }
                            ],
                        }
                        break;
                    case 'part2':
                        this.part2 = false;
                        this.rules = {
                            commonFirst: [
                                { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                                { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                            ],
                            commonSecond: [
                                { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                                { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                            ],
                            vip1First: [
                                { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                                { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                            ],
                            vip1Second: [
                                { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                                { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                            ],
                            vip2First: [
                                { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                                { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                            ],
                            vip2Second: [
                                { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                                { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                            ]
                        }
                        break;
                    case 'part3':
                        this.part3 = false;
                        this.rules = {}
                        break;
                    case 'part4':
                        this.part4 = false;
                        this.rules = {
                            recordingDuration: [
                                { pattern: /(^[1-9]\d{0,1}$)/, message: "请输入1-99的整数", trigger: "blur" },
                                { required: true, message: '请输入天数', trigger: 'blur' },
                            ]
                        }
                        break;
                    case 'part5':
                        this.part5 = false;
                        this.rules = {
                            shareOutBonus: [
                                { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                                { required: true, message: '请输入分红倍数', trigger: 'blur' },
                            ]
                        }
                        break;
                    case 'part6':
                        this.rules = {
                            endValue: [
                                { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                            ],
                            scopeRatio: [
                                // { validator: scopeRatioTest, trigger: 'blur' }
                                { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                                { required: true, message: '请输入比例', trigger: 'blur' },
                            ],

                        }
                        var str = '一个自然年内仅可设置一次，请谨慎操作!'
                        if (this.lastChangePerformanceRewardTime && this.getDateYearSubNew() == 0) {
                            str = '一个自然年内仅可设置一次!'
                        } else {
                            this.part6 = false
                        }
                        console.log(this.part6)
                        this.$confirm(str, '提示', {
                            confirmButtonText: '我知道了',
                            showCancelButton: false,
                            type: 'warning'
                        })
                        break;
                    case 'part7':
                        this.part7 = false;
                        this.rules = {}
                        break;
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '请检查是否有未提交内容'
                })
            }
        },
        /**
         * 上次编辑日期相差年数
         */
        getDateYearSubNew() {
            // debugger;
            var sDate = new Date(Date.parse(this.lastChangePerformanceRewardTime.replace(/-/g, "/")));
            var eDate = new Date();
            var str;

            //获得各自的年、月、日
            var sY = sDate.getFullYear();
            var sM = sDate.getMonth() + 1;
            var sD = sDate.getDate();
            var eY = eDate.getFullYear();
            var eM = eDate.getMonth() + 1;
            var eD = eDate.getDate();

            var yL = eY - sY;
            var mL = eM - sM;
            var dL = eD - sD;

            if (dL < 0) {
                mL--;
            }
            if (mL < 0) {
                yL--;
            }
            if (yL > 0) {
                str = yL;
            } else {
                str = 0;
            }
            return str
        },
        handleAdd() {
            var len = this.teamPerformance.performanceRewardScale.length
            var startValue = this.teamPerformance.performanceRewardScale[len - 1].startValue
            var endValue = this.teamPerformance.performanceRewardScale[len - 1].endValue
            var scopeRatio = this.teamPerformance.performanceRewardScale[len - 1].scopeRatio
            if (startValue >= endValue) {
                this.$message({
                    type: 'error',
                    message: '结束值必须大于起始值'
                })
                return
            }
            if (scopeRatio > 100) {
                this.$message({
                    type: 'error',
                    message: '比例不能超过100'
                });
                return
            }
            // 有结束值并且比例正确，添加下一行数据；否则提示
            if (endValue && (scopeRatio == 0 || scopeRatio)) {
                let row = {
                    startValue: endValue * 1,
                    endValue: null,
                    scopeRatio: 0,
                    rank: 1,
                }
                this.teamPerformance.performanceRewardScale.push(row)
            } else {
                this.$message({
                    type: 'error',
                    message: '请检查是否填写正确'
                })
                return
            }

        },
        /**
         * 删除数据
         * @param {*} row 
         * @param {*} index 
         */
        handleDelete(row, index) {
            console.log(row)
            if (this.teamPerformance.performanceRewardScale.length == 1) {
                this.$message({
                    type: 'error',
                    message: '起始行不能删除'
                })
                return
            }
            // 保证区间闭合（0-null）
            var len = this.teamPerformance.performanceRewardScale.length
            this.teamPerformance.performanceRewardScale[len - 2].endValue = null
            this.teamPerformance.performanceRewardScale.splice(index, 1);
            this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success",
                duration: 2000,
            });
        },
        GetTeamPerformance() {
            var _this = this;
            _this.MerchantDomain2.GetTeamPerformance(function (data) {
                _this.isTeamPerformanceReward = data.data.isTeamPerformanceReward
                _this.teamPerformance.performanceRewardCycle = data.data.performanceRewardCycle == 0 ? 1 : data.data.performanceRewardCycle
                _this.teamPerformance.performanceRewardScale = []
                _this.lastChangePerformanceRewardTime = data.data.lastChangePerformanceRewardTime
                if (data.data.performanceRewardScale && data.data.performanceRewardScale.length > 0) {
                    data.data.performanceRewardScale.forEach((item) => {
                        var row = {
                            startValue: item.startValue,
                            endValue: item.endValue,
                            scopeRatio: item.scopeRatio,
                            rank: item.rank,
                        }
                        _this.teamPerformance.performanceRewardScale.push(row)
                    })
                } else {
                    _this.teamPerformance.performanceRewardScale = [
                        {
                            startValue: 0,
                            endValue: null,
                            scopeRatio: 0,
                            rank: 1,
                        }
                    ]
                }
            }, function (err) { console.log(err); });
        },
        GetShareMultiplying() {
            var _this = this;
            _this.MerchantDomain2.GetShareMultiplying(function (data) {
                _this.MerchantData.shareOutBonus = data.data.shareMultiplying

            }, function (err) { console.log(err); });
        },
        changeTeamPerformanceReward(e) {
            if (e) {
                this.teamPerformance.performanceRewardCycle = 1
            } else {
                this.teamPerformance.performanceRewardCycle = 0
                if (!this.lastChangePerformanceRewardTime) {
                    this.teamPerformance.performanceRewardScale = [
                        {
                            startValue: 0,
                            endValue: null,
                            scopeRatio: 0,
                            rank: 1,
                        }
                    ]
                } else {
                    this.teamPerformance.performanceRewardScale = null
                }
            }
            console.log(this.teamPerformance.performanceRewardScale)
        },
        changePerformanceRewardCycle(e) {
            this.MerchantData.performanceRewardCycle = e
        },
        changePublicShareModel(e) {
            this.publicShareModel = e
        },
        getDetail() {
            var _this = this;
            _this.MerchantDomain.MerchantDetail(_this.organizationId, function (data) {
                _this.MerchantData = data.data;
                _this.MerchantData.address = _this.MerchantData.addressDetial
                _this.InitialRegions(_this.MerchantData.regionId)
                _this.GetShareMultiplying()
                _this.GetTeamPerformance()
                // 0机构代分 1团长分红 
                if (_this.MerchantData.publicShareType == 0 || _this.MerchantData.publicShareType == 1) {
                    _this.publicShareType = [_this.MerchantData.publicShareType + '']
                }
                // 2机构代分和团长分红
                if (_this.MerchantData.publicShareType == 2) {
                    _this.publicShareType = ['0', '1']
                }
                _this.MerchantData.cover = data.data.cover ? data.data.cover.trim() : ''
                _this.srcList.push(_this.MerchantData.cover)

            }, function (err) { console.log(err); });
        },
        handleAvatarSuccess(res, file) {
            this.MerchantData.cover = `${res[0].fullUrl}`;
            this.MerchantData.cover = this.MerchantData.cover.trim()
        },
        saveBtn(formName, partName) {
            console.log(partName)
            console.log(this.rules)
            var _this = this;
            this.$refs[formName].validate((valid) => {
                console.log(valid)
                if (valid) {
                    if (partName == 'part2') {
                        var num1 = _this.MerchantData.commonFirst * 1 + _this.MerchantData.commonSecond * 1
                        var num2 = _this.MerchantData.vip1First * 1 + _this.MerchantData.vip1Second * 1
                        var num3 = _this.MerchantData.vip2First * 1 + _this.MerchantData.vip2Second * 1
                        if (num1 > 100 || num2 > 100 || num3 > 100) {
                            _this.$message({
                                type: 'error',
                                message: '同一等级分销比例总和不能超过100'
                            })
                            return
                        }
                    }

                    if (partName == 'part5') {
                        // 统一分红 机构和团长都可分红
                        if (_this.MerchantData.publicShareModel == 0) {
                            this.MerchantData.publicShareType = 2
                        }
                        // 团队分红
                        if (_this.MerchantData.publicShareModel == 1) {
                            if (_this.publicShareType.length == 0) {
                                _this.$message({
                                    type: 'error',
                                    message: '请选择团队分红设置'
                                })
                                return
                            }
                            _this.MerchantData.publicShareType = _this.publicShareType.length == 1 ? _this.publicShareType[0] : 2
                        }
                    }
                    var map = {}
                    // 设置绩效
                    if (partName == 'part6') {
                        var isFlag = true
                        if (_this.isTeamPerformanceReward) {
                            var array = _this.teamPerformance.performanceRewardScale
                            for (let index = 0; index < array.length; index++) {
                                if (array[index].startValue >= array[index].endValue && array[index].endValue > 0) {
                                    _this.$message({
                                        type: 'error',
                                        message: '起始值不能大于结束值'
                                    });
                                    isFlag = false
                                    break
                                }
                                if (array[index].scopeRatio > 100) {
                                    _this.$message({
                                        type: 'error',
                                        message: '比例不能超过100'
                                    });
                                    isFlag = false
                                    break
                                }
                            }
                            // 保证区间闭合（0-null），最后一天数据结束值=null
                            var len = array.length
                            array[len - 1].endValue = null
                            var map = {
                                isTeamPerformanceReward: _this.isTeamPerformanceReward,
                                performanceRewardCycle: _this.teamPerformance.performanceRewardCycle,
                                performanceRewardScale: _this.teamPerformance.performanceRewardScale
                            }
                        } else {
                            var map = {
                                isTeamPerformanceReward: _this.isTeamPerformanceReward,
                            }
                        }
                        if (!isFlag) {
                            return
                        }
                    }
                    _this.saveData(partName, map)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        saveData(partName, map) {
            var _this = this
            if (_this.MerchantData.addressOther) {
                _this.MerchantData.address = _this.MerchantData.address + _this.MerchantData.addressOther
            }
            _this.MerchantDomain.MerchantSet(_this.MerchantData, function (data) {
                // 设置分红
                if (partName == 'part5') {
                    _this.saveShareOutBonus()
                    return
                }
                // 设置绩效
                if (partName == 'part6') {
                    _this.saveTeamPerformance(map)
                    return
                }
                _this.part1 = true;
                _this.part2 = true;
                _this.part3 = true;
                _this.part4 = true;
                _this.part7 = true;
                _this.$message({
                    type: 'success',
                    message: '设置成功!'
                });
            })
        },
        saveShareOutBonus() {
            var _this = this;
            _this.MerchantDomain2.ShareMultiplying(_this.MerchantData.shareOutBonus, function (data) {
                _this.part5 = true;
                _this.$message({
                    type: 'success',
                    message: '设置成功!'
                });
                _this.getDetail()
            }, function (err) { console.log(err); });
        },
        saveTeamPerformance(map) {
            var _this = this;
            _this.MerchantDomain2.TeamPerformanceSet(map, function (data) {
                _this.part6 = true;
                _this.$message({
                    type: 'success',
                    message: '设置成功!'
                });
                _this.getDetail()
            }, function (err) {
                console.log(err);
                _this.$message({
                    type: 'error',
                    message: '设置失败!'
                });
                _this.getDetail()
            });
        }

    }
}
</script>

<style scoped>
.el-descriptions {
    width: 650px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #089BAB;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.iq-card-body .el-form {
    max-width: 880px;
    width: 80%;
}

._table {
    width: 100%;
    border-collapse: collapse;
    border: 0px;
}

._table thead tr {
    font-size: 13px;
    color: #2e3b45;
    text-align: center;
    background-color: rgba(230, 255, 250, 0.92);
    font-weight: bold;
}

._table td {
    line-height: 30px;
    text-align: center;
    padding: 4px 10px 3px 10px;
    height: 18px;
    border: 1px solid #EBEEF5;
}

._table /deep/ .el-form-item__error {
    top: 88%;
}

.box-card {
    margin-bottom: 10px;
}

.btn {
    float: right;
    margin-left: 5px;
}

.tab /deep/ .el-form-item__content {
    height: 55px;
}

.tab /deep/ .el-form-item__content .el-form-item__error {
    top: 40px;
}

.bm-view {
    display: block;
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}

.BMap_noprint.anchorTL {
    width: 22%;
    top: 10px !important;
    left: 10px !important;
}
</style>
