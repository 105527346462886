<template>
  <div>
    <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 80px)" :default-sort="{ order: 'descending' }" ref="multipleTable" @selection-change="handleSelectionChange" :header-cell-style="{ background: '#F5F7FC' }">
      <el-table-column type="index" label="编号" width="80"></el-table-column>
      <el-table-column prop="primarySymptomName" label="测试症状"> </el-table-column>
      <el-table-column prop="symptoms" label="症状清单" align="center"> </el-table-column>
      <el-table-column sortable prop="diagnosisTime" label="测试时间" align="center">
        <template slot-scope="data">
          {{ data.row.diagnosisTime | timefilters }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
  </div>
</template>

<script>
import { SelfTest } from '../../../components/TestRecord/SelfTest'

export default {
  name: '',
  props:['answerPhone'],
  data() {
    var selfTest = new SelfTest(this.TokenClient, this.Services.Disease)
    return {
      selfTestDomain: selfTest,
      searchForm: {
        startTime: '',
        endTime: '',
        keyword: '',
        datepicker: '',
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      userId: this.$route.query.id,
      tableData: [],
      activeName: '0',
      // menuList: [],
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      dialogVisible: false,
      questionnaireDetermines: [],
      // userList: [],
      exportTypes: [],
      selectData: [],
      exportNum: '',
      exportTasks: [],
      exportDialog: false,
      // answerPhone: this.$route.query.answerPhone,
    }
  },
  mounted() {
    // this.getQuestionnaire()
    // this.getUserList()
    this.getList()
    // this.exportList();
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    toDownload(row) {
      window.open(`${this.CommonPath}/${row.path}`, '_blank')
    },
    handleSelectionChange(val) {
      this.selectData = []
      this.selectData = val
    },
    exportFile() {
      var _this = this
      if (_this.selectData.length == 0) {
        _this.$message({
          type: 'error',
          message: '请选择要导出的报告',
        })
        return
      }
      var ids = []
      this.selectData.forEach((element) => {
        ids.push(element.id)
      })
      _this.selfTestDomain.exportFile(
        'PDF',
        ids,
        (res) => {
          _this.$message({
            type: 'success',
            message: '导出中请稍后',
          })
          _this.exportList()
        },
        (error) => {
          console.log('请求失败!请刷新重试:', error)
        }
      )
    },
    exportList() {
      var _this = this
      _this.selfTestDomain.exportTasks(
        (res) => {
          _this.exportTasks = res.data
          var currentList = _this.exportTasks.filter((i) => i.statu == 0)
          _this.exportNum = currentList.length == 0 ? '' : currentList.length
        },
        (error) => {
          console.log('请求失败!请刷新重试:', error)
        }
      )
    },
    // getUserList() {
    //     var _this = this
    //     _this.selfTestDomain.MyselfAnswerInfo(_this.userId,
    //         (res) => {
    //             _this.userList = res.data
    //         },
    //         (error) => {
    //             console.log("请求失败!请刷新重试:", error);
    //         })
    // },
    toDetail(item) {
      this.dialogVisible = true
      // this.questionnaireDetermines = item.questionnaireDetermines.sort((a, b) => b.scores - a.scores);
      this.questionnaireDetermines = item.questionnaireDetermines
    },
    // getQuestionnaire() {
    //     this.selfTestDomain.Questionnaire(
    //         (res) => {
    //             this.menuList = []
    //             var map = {
    //                 name: '全部',
    //                 id: '0'
    //             }
    //             this.menuList = res.data
    //             this.menuList.unshift(map)
    //         },
    //         (error) => {
    //             console.log("请求失败!请刷新重试:", error);
    //         }
    //     );
    // },
    getList() {
      var _this = this
      _this.selfTestDomain.GetCustomerRecord(
        _this.answerPhone,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    handleClick(tab, event) {
      this.tableData = []
      this.PageIndex = 1
      this.getList()
    },
    search() {
      this.PageIndex = 1
      this.getList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.getList()
    },
    async fetchData() {
      var _this = this
      return await _this.getAllData()
    },

    DetailBtn(row) {
      this.$router.push({
        name: 'SalesDetail',
        query: {
          commodityId: row.id,
        },
      })
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
/* .box {
    position: fixed;
    right: 482px;
    top: 154px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    animation: move 1s forwards ease-out;
    z-index: 22;
}

@keyframes move {
    0% {
        transform: translate(0, 0)
    }

    25% {
        transform: translate(22.5px, -5px)
    }

    50% {
        transform: translate(45px, -10px)
    }


    75% {
        transform: translate(67.5px, -5px)
    }

    100% {
        transform: translate(90px, 0)
    }

} */

/*content*/
.center-content {
  height: 100%;
  padding: 120px 15px 0;
}

.iq-card-body {
  height: 100%;
  padding: 15px;
  border-radius: 15px;
  background: #ffffff;
}

.table-top {
  margin-bottom: -10px;
}

.iq-search-bar {
  display: inline-block;
  margin-right: 20px;
}

.iq-search-bar .searchbox {
  /* width: 480px; */
  position: relative;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #ceebee;
  line-height: 1.8;
}

.table-img {
  position: relative;
  width: 200px;
  height: 120px;
  overflow: hidden;
}

.table-img img {
  width: 100%;
  height: auto;
}

.table-img p {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}

.table-info h4 {
  margin-bottom: 25px;
}

.table-info .el-row {
  margin-bottom: 15px;
}

.iq-search-bar .searchbox .el-input {
  width: 300px;
}

.menu /deep/ .el-tabs {
  height: calc(100% - 50px);
}

.menu /deep/ .el-tabs__content {
  height: calc(100% - 55px);
}

.menu /deep/ .el-tab-pane {
  height: 100%;
}
</style>
