<template>
  <div class="height1">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="$router.go(-1)" content="大屏日志"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.account" type="text" size="small" placeholder="账号" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.userName" type="text" size="small" placeholder="名称" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.message" type="text" size="small" placeholder="消息" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" style="width: 100%" height="calc(100% - 90px)">
          <el-table-column label="编号" type="index"></el-table-column>
          <el-table-column prop="datetime" label="时间" width="150px" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.datetime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作人" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
              <p>{{ scope.row.account }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="message" label="返回信息" align="center" sortable showOverflowTooltip></el-table-column>
          <el-table-column prop="clientID" label="客户端名称" align="center">
            <template slot-scope="scope">
              <p v-if="clientList.length > 0 && scope.row.clientID">{{ clientList.filter((i) => i.key == scope.row.clientID)[0].name }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="ipAddress" label="IP" align="center"> </el-table-column>
          <el-table-column prop="os" label="操作系统" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.os == 'Unknow' ? '未知' : scope.row.os }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="browser" label="浏览器" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.browser == 'Unknow' ? '未知' : scope.row.browser }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="device" label="设备型号" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.device == 'Unknow' ? '未知' : scope.row.device }}</p>
            </template>
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
                <p style="overflow: auto">{{ scope.row.message }}</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { Hardware } from '../../../components/HospitalDomain/Hardware'
import { Client } from '../../../components/HospitalDomain/Client'
export default {
  name: "MerchantAudit",
  data() {
    var hardware = new Hardware(this.TokenClient, this.Services.Authorization);
    var client = new Client(this.TokenClient, this.Services.Authorization)
    return {
      HardwareDomain: hardware,
      Client: client,
      id: this.$route.query.id,
      fileUploadUrl: this.CommonFilePath,
      searchForm: {
        statrTime: "",
        endTime: "",
        account: "",
        userName: "",
        message: "",
        datepicker: "",
      },
      tableData: [],
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      clientList: [],
    };
  },
  mounted() {
    this.GetList();
    this.LoadClient()
  },
  methods: {
    LoadClient() {
      var _this = this
      _this.Client.Clients(
        '',
        0,
        0,
        function (data) {
          _this.clientList = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    search() {
      this.PageIndex = 1;
      this.GetList();
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex;
      this.GetList();
    },
    GetList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }

      _this.HardwareDomain.GetHardwareLog(
        _this.id,
        item.account,
        item.userName,
        item.message,
        item.startTime,
        item.endTime,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.DataTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">

.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
.table-top {
  margin-bottom: -10px;
}

.iq-search-bar {
  display: inline-block;
  margin-right: 20px;
}

.iq-search-bar .searchbox {
  /* width: 480px; */
  position: relative;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #ceebee;
  line-height: 1.8;
}

</style>
