<template>
    <div style="height: 100%">
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <el-page-header @back="goBack" :content="id?'编辑积分商品':'新增积分商品'">
                </el-page-header>
                <el-steps :active="steps" simple style="margin-top: 20px">
                    <el-step title="基本信息" icon="el-icon-tickets"></el-step>
                    <el-step title="商品设置" icon="el-icon-setting"></el-step>
                    <el-step title="商品介绍" icon="el-icon-edit"></el-step>
                    <el-step title="售后服务" icon="el-icon-edit"></el-step>
                    <el-step title="完成" icon="el-icon-circle-check"></el-step>
                </el-steps>
                <div class="from-box">
                    <el-scrollbar class="form-item" v-show="steps == 1" style="height: 100%;">
                        <el-form ref="firstForm" :model="commodityForm" label-width="150px" :rules="rules">
                            <!--<el-form-item label="商品编号">
                                <el-input class="w-18" v-model="commodityForm.code" disabled></el-input>
                            </el-form-item>-->
                            <el-form-item label="商品名称" prop="name">
                                <el-input type="textarea" v-model="commodityForm.name" maxlength="50" show-word-limit>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="商品封面" prop="coverPicture">
                                <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card" :limit="5" multiple
                                    :on-preview="handleImagePreview" :on-remove="handleImageRemove"
                                    :on-success="handleImageUpload" :file-list="ImageContentList">
                                    <i class="el-icon-plus"></i>
                                    <div class="el-upload__tip" slot="tip">最多上传5张，单张大小不超过20M且图片比例为4:3</div>
                                </el-upload>
                                <el-dialog :visible.sync="Image.dialogVisible">
                                    <img width="100%" :src="Image.dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                            <el-form-item label="商品分类" prop="categoryId">
                                <el-cascader v-model="commodityForm.categoryId" :options="commodifyCategory"
                                    :props="{ value: 'id', label: 'name', children: 'children', emitPath: false }"
                                    @change="handleChange"></el-cascader>
                            </el-form-item>
                            <el-form-item v-for="(item, index) in commodityForm.commodityParameters" :label="item.key"
                                :key="item.key" :prop="'commodityParameters.' + index + '.value'"
                                :rules="{ required: true, message: '规格设置不能为空', trigger: 'blur' }">
                                <el-input class="w-18" v-model="item.value"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="nextStep('firstForm')">下一步</el-button>
                            </el-form-item>
                        </el-form>
                    </el-scrollbar>
                    <el-scrollbar class="form-item" v-show="steps == 2" style="height: 100%;">
                        <el-form ref="secondForm" :model="commodityForm" label-width="150px" :rules="rules">
                            <el-form-item label="商品规格管理" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-table class="tab" :data="commodityForm.commoditySpecifications" border
                                    style="width: 100%">
                                    <el-table-column prop="name" align="center" label="规格名称">
                                        <template slot-scope="scope">
                                            <el-form-item label=""
                                                :prop="'commoditySpecifications[' + scope.$index + '].name'"
                                                :rules='rules.namegg'>
                                                <el-input v-model="scope.row.name" size="small" placeholder="规格名称">
                                                </el-input>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ordinaryPrice" align="center" label="价格(元)">
                                        <template slot-scope="scope">
                                            <el-form-item label=""
                                                :prop="'commoditySpecifications[' + scope.$index + '].ordinaryPrice'"
                                                :rules='rules.ordinaryPrice'>
                                                <el-input v-model="scope.row.ordinaryPrice" size="small"
                                                    placeholder="价格(元)"></el-input>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="integral" align="center" label="积分">
                                        <template slot-scope="scope">
                                            <el-form-item label=""
                                                :prop="'commoditySpecifications[' + scope.$index + '].integral'"
                                                :rules='rules.integral'>
                                                <el-input v-model="scope.row.integral" size="small" placeholder="积分">
                                                </el-input>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="stockNum" align="center" label="库存数量">
                                        <template slot-scope="scope">
                                            <el-form-item label=""
                                                :prop="'commoditySpecifications[' + scope.$index + '].stockNum'"
                                                :rules='rules.stockNum'>
                                                <el-input v-model="scope.row.stockNum" size="small" placeholder="库存数量">
                                                </el-input>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="rank" align="center" label="排序">
                                        <template slot-scope="scope">
                                            <el-form-item label=""
                                                :prop="'commoditySpecifications[' + scope.$index + '].rank'"
                                                :rules='rules.rank'>
                                                <el-input v-model="scope.row.rank" size="small" placeholder="排序">
                                                </el-input>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="150" align="center">
                                        <template slot-scope="scope">
                                            <el-button type="danger" size="mini" plain
                                                @click="handleDelete(scope.row, scope.$index)">
                                                <i class="el-icon-delete" aria-hidden="true"></i>
                                            </el-button>
                                        </template>
                                        <template slot="header">
                                            <!-- 通过js添加行数据的按钮 -->
                                            <el-button size="mini" @click="handleAdd()" type="primary" plain round>添加规格
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div style="color:red" v-if="errStr">{{ errStr }}</div>
                            </el-form-item>
                            <el-form-item label="是否限购">
                                <el-radio-group v-model="commodityForm.limitType" @change="checkLimitNum">
                                    <el-radio :label="0" style="margin-top: 15px;">不限购</el-radio>
                                    <br>
                                    <el-radio :label="1" style="margin: 10px 10px 0px 0px;">周期限购：每个会员，整个活动周期限购 <el-input
                                            type="number" style="width: 70px" v-model="commodityForm.limitNum"
                                            size="small" v-if="commodityForm.limitType == 1" @blur="checkLimitNum">
                                        </el-input> 份</el-radio>
                                    <br>
                                    <el-radio :label="2" style="margin: 10px 10px 10px 0px;">每日限购：每个会员，每日限购 <el-input
                                            type="number" style="width: 70px" v-model="commodityForm.limitNum"
                                            size="small" v-if="commodityForm.limitType == 2" @blur="checkLimitNum">
                                        </el-input> 份</el-radio>
                                    <br>
                                    <el-radio :label="3">每月限购：每个会员，每月限购 <el-input type="number" style="width: 70px"
                                            v-model="commodityForm.limitNum" size="small"
                                            v-if="commodityForm.limitType == 3" @blur="checkLimitNum"></el-input> 份
                                        <span style="color: #ff0000">*适用于长期活动</span>
                                    </el-radio>
                                </el-radio-group>
                                <div style="color:red" v-if="errorLimitNum">{{ errorLimitNum }}</div>
                            </el-form-item>
                            <el-form-item label="支付等待" prop="cancellationOrder">
                                <div class="isShare-box">
                                    <div>订单 <el-input type="number" v-model="commodityForm.cancellationOrder"
                                            size="small"></el-input>
                                        分钟未支付，自动取消订单</div>
                                </div>
                            </el-form-item>
                            <el-form-item label="" prop="completionOrder">
                                <div class="isShare-box">
                                    <div>订单 <el-input type="number" v-model="commodityForm.completionOrder"
                                            size="small"></el-input>
                                        天，自动确认收货</div>
                                </div>
                            </el-form-item>
                            <el-form-item label="是否需要发货">
                                <el-switch v-model="commodityForm.isSend" active-color="#13ce66"
                                    inactive-color="#999999">
                                </el-switch>
                            </el-form-item>
                            <el-form-item label="快递费用" v-if="commodityForm.isSend == true">
                                <el-radio-group v-model="commodityForm.freightType" @change="checkMoney">
                                    <el-radio :label="2" style="margin-top: 15px;">免运费</el-radio>
                                    <br>
                                    <el-radio :label="1" style="margin: 20px 10px 20px 0;">固定运费</el-radio>
                                    <el-input type="number" style="width: 70px" v-model="commodityForm.freightMoney"
                                        :disabled="commodityForm.freightType == 1 ? false : true" size="small"
                                        @blur="checkMoney"></el-input> 元
                                    <br>
                                    <el-radio :label="3">运费模板</el-radio>
                                    <el-select v-model="commodityForm.freightTemplateId"
                                        :disabled="commodityForm.freightType == 3 ? false : true" size="small"
                                        placeholder="请选择">
                                        <el-option v-for="(item, i) in freightTemplate" :key="i"
                                            :label="item.templateName" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-radio-group>
                                <div style="color:red" v-if="errorStr">{{ errorStr }}</div>
                            </el-form-item>
                            <el-form-item label="是否开具发票">
                                <el-switch v-model="commodityForm.isInvoice" active-color="#13ce66"
                                    inactive-color="#999999">
                                </el-switch>
                            </el-form-item>
                            <el-form-item label="是否可退单">
                                <el-radio-group v-model="commodityForm.chargebackType" @change="checkChargebackMoney">
                                    <el-radio :label="1">不可退单</el-radio>
                                    <!-- <el-radio :label="2">随时可退</el-radio>
                                    <el-radio :label="3">支付完成24小时可退</el-radio> -->
                                </el-radio-group>
                                <div class="isShare-box"
                                    v-if="commodityForm.chargebackType == '2' || commodityForm.chargebackType == '3'">
                                    <div>退单时，需支付手续费 <el-input v-model="commodityForm.chargebackMoney" size="small"
                                            type="number" @blur="checkChargebackMoney">
                                        </el-input> 元，输入0元，为退单无需手续费</div>
                                </div>
                                <div style="color:red" v-if="errorChargebackMoney">{{ errorChargebackMoney }}</div>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="prevStep">上一步</el-button>
                                <el-button @click="nextStep('secondForm')">下一步</el-button>
                            </el-form-item>
                        </el-form>
                    </el-scrollbar>
                    <el-scrollbar class="form-item" v-show="steps == 3" style="height: 100%;">
                        <el-form ref="thirdForm" :model="commodityForm" label-width="150px" :rules="rules">
                                <el-form-item label="购买须知">
                                    <vue-tinymce v-model="commodityForm.instructions" ref="richTextEditor"
                                        :setting="setting" />
                                </el-form-item>
                                <el-form-item label="商品介绍" prop="introduce">
                                    <vue-tinymce v-model="commodityForm.introduce" ref="richTextEditor"
                                        :setting="setting" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="prevStep">上一步</el-button>
                                    <el-button @click="nextStep('thirdForm')">下一步</el-button>
                                </el-form-item>
                            </el-form>
                        </el-scrollbar>
                        <el-scrollbar class="form-item" v-show="steps == 4" style="height: 100%;">
                            <el-form ref="fourthForm" :model="commodityForm" label-width="150px" :rules="rules">
                                <el-form-item label="售后服务">
                                <vue-tinymce v-model="commodityForm.customerService" ref="richTextEditor"
                                    :setting="setting" />
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="prevStep">上一步</el-button>
                                <el-button @click="saveCommodity()">下一步</el-button>
                            </el-form-item>
                        </el-form>
                    </el-scrollbar>
                    <el-scrollbar class="form-item" v-show="steps == 5" style="height: 100%;">
                        <div>
                            <el-result icon="success" title="商品提交成功，请耐心等待后台审核!">
                                <template slot="extra">
                                    <el-button type="primary" size="medium" @click="goBack()">返回</el-button>
                                </template>
                            </el-result>
                        </div>
                    </el-scrollbar>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Commodity } from "../../../components/HospitalDomain/ShopMall/commodity";
import { CommodityCategory } from "../../../components/HospitalDomain/ShopMall/CommodityCategory";
import { Region } from "../../../components/HospitalDomain/Region";
import { Videos } from "../../../components/HospitalDomain/Videos/Videos";
import { Merchant } from "../../../components/HospitalDomain/ShopMall/Merchant";
import '../../../utils/aliyun-upload-sdk-1.5.3.min.js'
import '../../../utils/es6-promise.min.js'
import OSS from '../../../utils/aliyun-oss-sdk-6.17.1.min.js'
window.OSS = OSS;
var loading = null;
export default {
    name: "addCommodity",
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + JSON.parse(sessionStorage.getItem(this.ClientId + "Token")).access_token,
      };
    },
  },
    data() {
        var commodityCategory = new CommodityCategory(this.TokenClient, this.Services.Shopping);
        var commodity = new Commodity(this.TokenClient, this.Services.Shopping);
        var region = new Region(this.TokenClient, this.Services.Authorization);
        var regMoney = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        var videos = new Videos(this.TokenClient, this.Services.Healthy)
        var merchant = new Merchant(this.TokenClient, this.Services.Shopping)
        return {
            MerchantDomain: merchant,
            loading: false,
            VideosDomain: videos,
            regMoney: regMoney,
            errStr: "",
            errorLimitNum: "",
            errorStr: "",
            errorChargebackMoney: "",
            CommodityDomain: commodity,
            CommodityCategoryDomain: commodityCategory,
            Region: region,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            steps: 1,
            merchantInfo: {},
            id: this.$route.query.id, //列表页id
            commodityForm: {
                type: 3,
                attribute: 2,
                name: '',
                categoryId: null,
                commodityParameters: [],
                coverPicture: '',
                isShare: false,
                memberShare: 0,
                ordinaryShare: 0,
                commoditySpecifications: [
                    {
                        name: '',
                        // guidancePrice: null,
                        // membershipPrice: 0,
                        ordinaryPrice: null,
                        // vip1Price: null,
                        // vip2Price: null,
                        stockNum: null,
                        isIntegral: true,
                        integral: null,
                        rank: 0
                    }
                ],
                limitType: 0,
                limitNum: 1,
                cancellationOrder: '30',
                completionOrder: '14',
                isSend: false,
                freightType: 2,
                freightMoney: '0',
                freightTemplateId: null,
                isInvoice: false,
                chargebackType: 1,
                chargebackMoney: 0,

                introduce: '',
                instructions: '',
                customerService: '',
            },
            commodifyCategory: [],
            freightTemplate: [],
            RegionData: [],
            Image: {
                //单击图片上传控件中的图片时，放大显示
                dialogVisible: false,
                dialogImageUrl: '',
            },
            ImageContent: [],//图片列表
            ImageContentList: [],//图片上传控件加载的数据
            FileUploadUrl: this.CommonFilePath,
            originId: null,
            RichTextContent: '',
            RichTextContentTimeoutHandle: undefined,
            successCode: '',
            resVideo: '',    //上传视频返回的url
            uploaded: false,//有没有上传完成
            setting: {
                menubar: false,
                cleanup: true,
                toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
                toolbar_drawer: "sliding",
                quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
                plugins: "link image media table lists fullscreen quickbars autoresize",
                language: 'zh_CN',
                max_height: 600, // 编辑区域的最大高
                min_height: 350, //编辑区域的最小高度
                images_upload_handler: (blobInfo, success, failure) => {
                    let formdata = new FormData()
                    // formdata.set('file', blobInfo.blob())
                    // 二进制文件，文件名装入form传到后台处理
                    formdata.append('file', blobInfo.blob(), blobInfo.filename())
                    console.log(this.FileBaseAddr)
                    console.log(formdata)
                    axios({
                        method: 'POST',
            headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer" + " " + JSON.parse(sessionStorage.getItem(this.ClientId + "Token")).access_token },
                        // 这里是你的上传地址
                        url: this.CommonFilePath,
                        data: formdata,
                    }).then((res) => {
                        if (res.data[0].isSuccess == true) {
                            // 这里返回的是你图片的地址
                            success(this.CommonPath + res.data[0].completeFilePath)
                        }
                        else {
                            failure('图片上传失败!')
                        }
                    })
                },
                //上传视频配置
                file_picker_types: "media",
                file_picker_callback: (callback, value, meta) => {
                    console.log(meta.filetype)
                    if (meta.filetype == 'media') {
                        let input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "video/mp4,video/AVI,video/mov,video/FLV,video/rmvb,video/mtv,video/mpeg");
                        let that = this;
                        input.click();
                        input.onchange = function (e) {
                            let file = this.files[0];
                            loading = that.$loading({
                                lock: true,
                                // text: '正在上传视频...',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.4)',
                            });
                            let ext = file.name.split(".")[1];
                            if (
                                ext !== "mp4" &&
                                ext !== "AVI" &&
                                ext !== "mov" &&
                                ext !== "FLV" &&
                                ext !== "rmvb" &&
                                ext !== "wmv" &&
                                ext !== "mtv" &&
                                ext !== "mpg" &&
                                ext !== "mpeg"

                            ) {
                                that.$message.error({ message: '上传资源只能是 AVI/mov/rmvb/FLV/mp4/wmv/mtv/mpg/mpeg 格式的视频!', duration: 5000, customClass: 'zZindex' });
                                loading.close();
                                return false;
                            }
                            let isLt20M = file.size / 1024 / 1024 < 20;
                            console.log(file.size / 1024 / 1024)
                            if (!isLt20M) {
                                that.$message.error('上传视频大小不能超过20MB哦!');
                                loading.close();
                                return false;
                            }
                            that.resVideo = ''
                            that.uploaded = false
                            that.uploader = that.createUploader()
                            let userData = '{"Vod":{}}'
                            that.uploader.addFile(file, null, null, null, userData)
                            that.uploader.startUpload()
                            if (that.uploaded) {
                                callback(that.resVideo) //将url显示在弹框输入框中
                                loading.close();
                                that.uploaded = false
                            } else {
                                setTimeout(() => {
                                    //设置几秒后再去取数据
                                    callback(that.resVideo)
                                    loading.close();
                                    that.uploaded = false
                                }, 5000)
                            }
                        };

                    }
                },
                video_template_callback: data => {
                    console.log(data)
                    return `<video width="745" height="420" controls="controls" src=${data.source} />`
                },
            },
            productGroupRules: {
                productGroupNum: [{ required: true, message: '请填写商品数量', trigger: 'blur' }],
                productGroupPrice: [{ required: true, message: '请填写优惠价格', trigger: 'blur' }]
            },
            rules: {
                name: [
                    { required: true, message: '请输入商品名称', trigger: 'blur' },
                ],
                coverPicture: [
                    { required: true, type: 'array', message: '请上传商品封面', trigger: 'blur' },
                ],
                memberShare: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入正确的金额', trigger: 'blur' },
                ],
                ordinaryShare: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入正确的金额', trigger: 'blur' },
                ],
                namegg: [
                    { required: true, message: '请输入规格名称', trigger: 'blur' },
                ],
                doctorPrice: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入商品价格', trigger: 'blur' },
                ],
                membershipPrice: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入商品会员价', trigger: 'blur' },
                ],
                ordinaryPrice: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入商品非会员价', trigger: 'blur' },
                ],
                integral: [
                    { pattern: /^[1-9]\d*$/, message: "请输入正整数", trigger: "blur" },
                    { required: true, message: '请输入积分', trigger: 'blur' },
                ],
                stockNum: [
                    { pattern: /^[1-9]\d*$/, message: "请输入正整数", trigger: "blur" },
                    { required: true, message: '请输入库存量', trigger: 'blur' },
                ],
                rank: [
                    { pattern: /^[0-9]\d*$/, message: "请输入整数", trigger: "blur" },
                    { required: true, message: '请输入排序', trigger: 'blur' },
                ],
                commonFirst: [
                    { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                    { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                ],
                commonSecond: [
                    { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                    { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                ],
                vip1First: [
                    { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                    { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                ],
                vip1Second: [
                    { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                    { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                ],
                vip2First: [
                    { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                    { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                ],
                vip2Second: [
                    { pattern: /(^[0-9]\d{0,1}$)/, message: "请输入0-99的整数", trigger: "blur" },
                    { required: true, message: '请输入0-99的整数', trigger: 'blur' },
                ],
                publicShareMoney: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入正确的金额', trigger: 'blur' },
                ],
                categoryId: [
                    { required: true, message: '请选择商品分类', trigger: 'change' },
                ],
                cancellationOrder: [
                    { pattern: /^[1-9]\d*$/, message: "请输入大于0，小于10000的正整数", trigger: "blur" },
                    { required: true, message: '请输入正确的分钟数', trigger: 'blur' },
                ],
                completionOrder: [
                    { pattern: /^[1-9]\d*$/, message: "请输入大于0，小于10000的正整数", trigger: "blur" },
                    { required: true, message: '请输入正确的天数', trigger: 'blur' },
                ],
                originId: [
                    { type: 'array', required: true, message: "请选择产地", trigger: "change" }
                ],
                introduce: [
                    { required: true, message: '请输入商品介绍', trigger: 'change' },
                ],
                // instructions: [
                //     { required: true, message: '请输入购买须知', trigger: 'change' },
                // ],
            },
        }
    },
    mounted() {
        if (this.id) {
            this.LoadFrom();
        } else {
            this.loading = true;
            this.getMerchantSet()
            let data = JSON.parse(JSON.stringify(this.commodityForm.commoditySpecifications ? this.commodityForm.commoditySpecifications : []));
            data.forEach((element) => {
                element["edit"] = true;
            });
            this.commodityForm.commoditySpecifications = data;
        }
        this.LoadCategory();
        this.InitialRegion();
        this.LoadFreightTemplate();

    },
    methods: {
        LoadFrom() {
            var _this = this;
            _this.CommodityDomain.Detail(_this.id,
                function (data) {
                    _this.commodityForm = {
                        id: data.data.id,
                        type: 3,
                        attribute: 2,
                        name: data.data.name,
                        categoryId: data.data.categoryId,
                        commodityParameters: data.data.commodityParameters,
                        isHot: data.data.isHot,
                        isCommended: data.data.isCommended,
                        isShare: data.data.isShare,
                        memberShare: data.data.memberShare,
                        ordinaryShare: data.data.ordinaryShare,
                        commoditySpecifications: data.data.commoditySpecifications,
                        commonFirst: data.data.commonFirst,
                        commonSecond: data.data.commonSecond,
                        vip1First: data.data.vip1First,
                        vip1Second: data.data.vip1Second,
                        vip2First: data.data.vip2First,
                        vip2Second: data.data.vip2Second,
                        isPublicShare: data.data.isPublicShare,
                        publicShareMoney: data.data.publicShareMoney,
                        limitType: data.data.limitType,
                        limitNum: data.data.limitNum,
                        cancellationOrder: data.data.cancellationOrder,
                        completionOrder: data.data.completionOrder,
                        isSend: data.data.isSend,
                        freightType: data.data.freightType,
                        freightMoney: data.data.freightMoney,
                        freightTemplateId: data.data.freightTemplateId,
                        isInvoice: data.data.isInvoice,
                        chargebackType: data.data.chargebackType,
                        chargebackMoney: data.data.chargebackMoney,
                        coverPicture: [],
                        introduce: data.data.introduce,
                        instructions: data.data.instructions,
                        customerService: data.data.customerService,
                    }
                    let dataArry = JSON.parse(JSON.stringify(_this.commodityForm.commoditySpecifications ? _this.commodityForm.commoditySpecifications : []));
                    dataArry.forEach((element) => {
                        element["edit"] = true;
                    });
                    _this.commodityForm.commoditySpecifications = dataArry;
                    data.data.coverPicture.forEach(element => {
                        let obj = new Object();
                        obj.url = _this.CommonPath + element.filePath;
                        _this.ImageContentList.push(obj);
                        let imgMap = {
                            fileName: element.fileName,
                            filePath: element.filePath
                        }
                        _this.commodityForm.coverPicture.push(imgMap)
                    });
                }, function (err) { console.log(err); });
        },
        getMerchantSet() {
            var _this = this;
            _this.MerchantDomain.MerchantState(function (data) {
                if (data.code == '200') {
                    _this.loading = true;
                    _this.merchantInfo = data.data
                    if (_this.merchantInfo.state != 1) {
                        _this.loading = false;
                        _this.$alert('店铺功能未开通！', '提示', {
                            confirmButtonText: '我知道了',
                            callback: action => {
                                _this.$router.go(-1);
                            }
                        });
                    } else {
                        _this.loading = false;
                    }

                }

            }, function (err) {
                console.log(err)
                _this.loading = false;
                if (err.status != "403") {
                    _this.centerDialog = true;
                }
                if (err.status == "404") {
                    _this.centerDialog = true;
                    _this.$alert('店铺功能未开通！', '提示', {
                        confirmButtonText: '我知道了',
                        callback: action => {
                            _this.$router.go(-1);
                        }
                    });
                }

            });
        },
        createUploader() {
            let _this = this;
            var uploader = new AliyunUpload.Vod({
                userId: "1922508017966810",
                //上传到点播的地域，默认值为'cn-shanghai'，//eu-central-1，ap-southeast-1，更多信息请参见存储说明
                region: "cn-shanghai",
                //开始上传
                addFileSuccess: function (uploadInfo) {
                    console.log(uploadInfo)
                },
                onUploadstarted: function (uploadInfo) {
                    var that = this
                    let params = {
                        title: '视频名称',
                        fileName: uploadInfo.file.name
                    }
                    _this.VideosDomain.GetVideoCertificate(params, function (data) {
                        uploader.setUploadAuthAndAddress(uploadInfo, data.data.uploadAuth, data.data.uploadAddress, data.data.videoId);
                        _this.videoId = data.data.videoId
                    }, function (error) {
                        _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                        loading.close();
                    })
                    // }
                },
                //文件上传成功
                onUploadSucceed: function (uploadInfo) {
                    _this.resVideo = "http://video.qyyl365.com/" + uploadInfo.object
                },
                //文件上传失败
                onUploadFailed: function (uploadInfo, code, message) {
                    _this.uploaded = false
                    loading.close();
                    uploader.stopUpload();
                },
                //文件上传进度，单位：字节
                onUploadProgress: function (uploadInfo, totalSize, loadedPercent) {
                    // _this.percentage = parseInt((loadedPercent * 100).toFixed(2))
                },
                //上传凭证过期
                onUploadTokenExpired: function (uploadInfo) {
                    console.log("1111111")
                    // loading.close();
                },
                //全部文件上传结束
                onUploadEnd: function (uploadInfo) {
                    _this.uploaded = true
                }
            })
            return uploader;
        },
        checkChargebackMoney() {
            this.errorChargebackMoney = ''
            if (this.commodityForm.chargebackType > 1) {
                if (!this.regMoney.test(this.commodityForm.chargebackMoney)) {
                    this.errorChargebackMoney = "请输入正确的金额(小数点后保留两位)"
                    this.commodityForm.chargebackMoney = 1
                    return
                }
            }
        },
        checkLimitNum() {
            this.errorLimitNum = ''
            var reg = /^[1-9]\d*$/;
            if (this.commodityForm.limitType > 0) {
                if (!reg.test(this.commodityForm.limitNum)) {
                    this.errorLimitNum = "请输入正确的限购数量(大于0，小于10000的正整数)"
                    this.commodityForm.limitNum = 1
                    return
                }
            }
        },
        checkMoney() {
            this.errorStr = ''
            if (this.commodityForm.freightType == 1) {
                if (!this.regMoney.test(this.commodityForm.freightMoney)) {
                    this.errorStr = "请输入正确的金额(小数点后保留两位)"
                    this.commodityForm.freightMoney = 0
                    return
                }
            }
        },
        goBack() {
            this.$router.push('/ShopMall/integralCommodity');
            // this.$router.go(-1);
        },
        InitialRegion() {
            var _this = this;
            _this.Region.Regions(null,
                function (data) { _this.RegionData = data.data; },
                function (err) { console.log(err); });
        },
        LasyLoadRegion(node, resolve) {
            var _this = this;
            _this.Region.Regions(node.value,
                function (data) {
                    for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                        data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 3;
                    }
                    resolve(data.data);
                },
                function (err) {
                    resolve([]);
                    console.log(err);
                });
        },
        LoadCategory(success, error) {
            var _this = this;
            _this.CommodityCategoryDomain.Categories(
                function (data) {
                    _this.commodifyCategory = data.data;
                },
                function (err) {
                    console.log(err);
                });
        },
        LoadFreightTemplate() {
            var _this = this;
            _this.CommodityDomain.FreightTemplate(_this.organizationId,
                function (data) {
                    _this.freightTemplate = data.data;
                    // 设置默认选项
                    _this.commodityForm.freightTemplateId = _this.freightTemplate[0].id
                },
                function (err) {
                    console.log(err);
                });
        },
        //规格增删改---------开始
        handleAdd() {
            let row = {
                edit: true,
                name: '',
                // guidancePrice: null,
                // membershipPrice: 0,
                ordinaryPrice: null,
                // vip1Price: null,
                // vip2Price: null,
                stockNum: null,
                isIntegral: true,
                integral: null,
                rank: 0
            }
            this.commodityForm.commoditySpecifications.push(row)
        },
        editData(row) {
            row.edit = true;
            // console.log(row.edit);
            // console.log(row.price);
        },
        confirmData(row) {
            row.edit = false;
            // console.log(row.edit);
            // console.log(row.price);
            this.$notify({
                title: "Success",
                message: "编辑成功",
                type: "success",
                duration: 2000,
            });
        },
        handleDelete(row, index) {
            this.$notify({
                title: "Success",
                message: "Delete Successfully",
                type: "success",
                duration: 2000,
            });
            this.commodityForm.commoditySpecifications.splice(index, 1);
        },
        //规格增删改---------结束
        handleChange(value) {
            var _this = this;
            this.$refs.firstForm.clearValidate('value');
            _this.commodityForm.categoryId = value
            _this.CommodityCategoryDomain.CommonParameter(value,
                function (data) {
                    _this.commodityForm.commodityParameters = [];
                    data.data.forEach((item, i) => {
                        _this.commodityForm.commodityParameters.push({
                            id: 0,
                            parameterId: item.id,
                            key: item.key,
                            value: item.value
                        })
                    });
                },
                function (err) {
                    console.log(err);
                });
        },
        handleImageUpload(response, file, fileList) {
            var _this = this;
            response.forEach(function (file) {
                var imgarr = {
                    "filePath": file.completeFilePath,
                    "fileName": file.fileName
                }

                _this.ImageContent.push(imgarr);
            });
            _this.$refs['firstForm'].clearValidate(['coverPicture'])
            _this.commodityForm.coverPicture = _this.ImageContent;
        },
        handleImagePreview(file) {
            this.Image.dialogImageUrl = file.url;
            this.Image.dialogVisible = true;
        },
        handleImageRemove(file, fileList) {
            var _this = this;
            let filePath = ""
            if (file.response) {
                filePath = file.response[0].completeFilePath;
            } else {
                filePath = file.url;
            }
            //2.从pics数组中找到图片对应的索引值
            //形参‘x’，是数组pic里的每一项
            const i = _this.ImageContent.findIndex(x =>
                x.filePath == filePath);
            //调用数组的splice方法，把图片的信息对象从pics数组中移除
            _this.ImageContent.splice(i, 1);
            _this.commodityForm.coverPicture = _this.ImageContent;
        },
        nextStep(formName) {
            this.errStr = ""
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (formName == "secondForm") {
                        let canSteps = 0
                        let arry = this.commodityForm.commoditySpecifications
                        if (arry.length == 0) {
                            this.errStr = "请完善商品规格信息"
                            return
                        }
                        this.errStr = ""
                        if (this.steps++ > 3) this.steps = 1;
                    } else {
                        if (this.steps++ > 3) this.steps = 1;
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        prevStep() {
            if (this.steps-- < 1) this.steps = 1;
        },
        saveCommodity() {
            var _this = this;
            var item = this.commodityForm
            if (_this.id) {
                _this.CommodityDomain.EditCommodity(_this.commodityForm,
                    function (data) {
                        _this.steps++;
                        _this.successCode = data.data
                    }, function (err) {

                    })
            } else {
                _this.CommodityDomain.AddCommodity(item,
                    function (data) {
                        _this.steps++;
                        _this.successCode = data.data
                    }, function (err) {

                    })
            }
        }
    }
}
</script>

<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

.messages {
    margin-top: 13px;
    margin-right: 20px;
}

.messages .el-icon-bell {
    font-size: 26px;
}

/*content*/
.center-content {
    padding: 15px;
    height: 100%;
}


.from-box {
    height: calc(100% - 100px);
    overflow-x: hidden;
    width: 100%;
}

.from-box .el-form {
    max-width: 1200px;
    width: 80%;
}

.form-item {
    padding-top: 30px;

}

.form-item .el-form .el-cascader {
    width: 100%;
}

.isShare-box>div {
    display: inline-block;
    /* margin: 0 20px 0 0; */
}

.isShare-box>div .el-input {
    width: 70px;
}

.tab /deep/ .el-form-item__content {
    height: 55px;
}

.tab /deep/ .el-form-item__content .el-form-item__error {
    top: 40px;
}

._table {
    width: 60%;
    border-collapse: collapse;
    border: 0px;
}

._table thead tr {
    font-size: 13px;
    color: #2e3b45;
    text-align: center;
    background-color: rgba(230, 255, 250, 0.92);
    font-weight: bold;
}

._table td {
    line-height: 30px;
    text-align: center;
    padding: 4px 10px 3px 10px;
    height: 18px;
    border: 1px solid #EBEEF5;
}

._table /deep/ .el-form-item__error {
    top: 88%;
}
</style>
