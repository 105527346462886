//扩展js
//扩展分组
export function GroupBy(array, fn) {
	const groups = {}
	array.forEach(function(item) {
		const group = JSON.stringify(fn(item))
		//这里利用对象的key值唯一性的，创建数组
		groups[group] = groups[group] || []
		groups[group].push(item)
	})
	//最后再利用map循环处理分组出来
	return Object.keys(groups).map(function(group) {
		return groups[group]
	})
}

//获取键值对下的length
export function getPropertyCount(o) {
	var n,
		count = 0
	for (n in o) {
		if (o.hasOwnProperty(n)) {
			count++
		}
	}
	return count
}

//收藏、浏览记录 名称转换
export function converName(name) {
	var result = ''
	switch (name) {
		case 'disease':
			result = '疾病'
			break
		case 'drug':
			result = '药品'
			break
		case 'food':
			result = '食疗药膳'
			break
		case 'herbal':
			result = '中药方剂'
			break
		case 'material':
			result = '中药'
			break
		case 'acupoint':
			result = '穴位'
			break
		case 'meridian':
			result = '经络'
			break
		case 'manipulation':
			result = '推拿手法'
			break
		case 'compatibility':
			result = '配伍'
			break
		case 'healthy':
			result = '健康饮食'
			break
	}
	return result
}

export function TimeFormatDate (val) {
	if (val == null || val == "") {
		return "-"
	} else {
		let d = new Date(val) //val 为表格内取到的后台时间
		let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
		let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate()
		let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours()
		let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()
		let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds()
		let times = d.getFullYear() + "-" + month + "-" + day
		return times
	}
}