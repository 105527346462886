let ExpertAppointment = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;
  /**
   *预约记录
   * @param {*} type 类型 1：总后台 2：合作商 3:机构
   * @param {*} status 预约状态 -1 全部 0:未审核 1：审核通过 2：审核拒绝
   * @param {*} keyWord
   * @param {*} pageIndex
   * @param {*} success
   * @param {*} error
   */
  this.GetAppointmentList = function (type, status, keyWord, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/ExpertAppointment/GetAppointmentList/${type}/${status}`;
    if (keyWord) {
      url += `/${keyWord}`;
    } else {
      url += `/%20`;
    }
    if (pageIndex) {
      url += `/${pageIndex}`;
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  
  this.GetAppointmentAuditList = function (type, status, keyWord, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/ExpertAppointment/GetAppointmentAuditList/${type}/${status}`;
    if (keyWord) {
      url += `/${keyWord}`;
    } else {
      url += `/%20`;
    }
    if (pageIndex) {
      url += `/${pageIndex}`;
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 预约审核
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.AuditAppointment = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/ExpertAppointment/AuditAppointment";
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 专家预约详情
   * @param {*} userId
   * @param {*} success
   * @param {*} error
   */
  this.GetExpertAppointmentDetail = function (type,userId, success, error) {
    var url = ServiceBaseAddress + `/api/ExpertAppointment/GetExpertAppointmentDetail/${type}/${userId}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  this.SaveAppointment = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/ExpertAppointment/SaveAppointment"
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
};

export { ExpertAppointment };
