<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" content="辨证论治记录">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body menu">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form action="#" class="searchbox" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                                </el-date-picker>
                            </el-form-item>
                            <!-- <el-form-item label="测试人">
                                <el-select v-model="searchForm.customerPhone" placeholder="请选择测试人" size="small"
                                    style="margin-right: 10px" clearable>
                                    <el-option v-for="item in userList" :key="item.id"
                                        :label="`${item.answerUserName}/${item.answerPhone}`" :value="item.answerPhone">
                                    </el-option>
                                </el-select>
                            </el-form-item> -->
                            <!-- <el-form-item label="">
                                <el-input v-model="searchForm.keyword" type="text" size="small" placeholder="输入关键词搜索..."
                                    @keyup.enter.native="search" clearable></el-input>
                            </el-form-item> -->
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search" plain>搜索</el-button>
                                <!-- <el-button type="primary" size="small" @click="exportFile" plain>导出报告</el-button>
                                <el-badge :value="exportNum" class="item" style="margin-left:10px">
                                    <el-button type="primary" size="small" @click="exportDialog = true; exportList();"
                                        plain>导出列表</el-button>
                                </el-badge> -->
                                <div class="box"></div>
                            </el-form-item>
                            <!-- <el-form-item>
                                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields"
                                    worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前页数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                                </download-excel>
                            </el-form-item> -->
                        </el-form>
                    </div>
                </div>
                <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane v-for="(menu, index) in menuList" :key="index" :label="menu.name"
                        :name="`${menu.id.toString()}`"> -->
                        <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 80px)"
                            :default-sort="{ order: 'descending' }" ref="multipleTable"
                            @selection-change="handleSelectionChange" :header-cell-style="{background:'#F5F7FC'}">
                            <el-table-column type="selection" width="55">
                            </el-table-column>
                            <el-table-column prop="primarySymptomName" label="测试症状">
                            </el-table-column>
                            <el-table-column prop="symptoms" label="症状清单" align="center">
                            </el-table-column>
                            <el-table-column sortable prop="diagnosisTime" label="测试时间" align="center">
                                <template slot-scope="data">
                                    {{ data.row.diagnosisTime | timefilters }}
                                </template>
                            </el-table-column>
                            <!-- <el-table-column label="操作" width="150px" align="center">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" plain
                                        @click="toDetail(scope.row)">详情得分</el-button>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                            :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                    <!-- </el-tab-pane>

                </el-tabs> -->
            </div>
        </div>
        <el-dialog title="得分详情" :visible.sync="dialogVisible" width="1200px">
            <el-table :data="questionnaireDetermines" style="width: 100%" height="500px"
                :default-sort="{ order: 'descending' }" :header-cell-style="{background:'#F5F7FC'}">
                <el-table-column type='index' label="编号" width="80"></el-table-column>
                <el-table-column prop="name" align="center" label="名称">
                </el-table-column>
                <el-table-column prop="category" align="center" label="分类">
                </el-table-column>
                <el-table-column sortable prop="scores" align="center" label="得分">
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="导出详情" :visible.sync="exportDialog" width="1200px">
            <div class="flex-end" style="width: 100%;">
                <el-button type="primary" size="small" @click="exportList" plain>刷新数据</el-button>
            </div>
            <el-table :data="exportTasks" style="width: 100%" height="500px" :default-sort="{ order: 'descending' }" :header-cell-style="{background:'#F5F7FC'}">
                <el-table-column type='index' label="编号" width="80"></el-table-column>
                <el-table-column prop="type" align="center" label="文件类型">
                </el-table-column>
                <el-table-column sortable prop="statu" align="center" label="导出状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.statu == 1" type="success">导出成功</el-tag>
                        <el-tag v-if="scope.row.statu == 0">进行中/排队中</el-tag>
                        <el-tag v-if="scope.row.statu == -1" type="danger">导出失败</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column sortable prop="path" align="center" label="导出文件路径">
                </el-table-column> -->
                <el-table-column sortable prop="operationTime" label="提交时间" align="center">
                    <template slot-scope="data">
                        {{ data.row.operationTime | timefilters }}
                    </template>
                </el-table-column>
                <el-table-column prop="completeTime" label="完成时间" align="center">
                    <template slot-scope="data">
                        {{ data.row.completeTime | timefilters }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150px" align="center">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.statu == 1" size="mini" type="primary" plain
                            @click="toDownload(scope.row)">下载</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { SelfTest } from "../../../components/TestRecord/SelfTest"
import bread from "../../../components/common/breadcrumb";

export default {
    name: "",
    components: {
        bread
    },
    data() {
        var selfTest = new SelfTest(this.TokenClient, this.Services.Disease)
        return {
            selfTestDomain: selfTest,
            searchForm: {
                startTime: '',
                endTime: '',
                keyword: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            userId: this.$route.query.id,
            tableData: [],
            activeName: '0',
            // menuList: [],
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            dialogVisible: false,
            questionnaireDetermines: [],
            // userList: [],
            exportTypes: [],
            selectData: [],
            exportNum: '',
            exportTasks: [],
            exportDialog: false,
        }
    },
    mounted() {
        // this.getQuestionnaire()
        // this.getUserList()
        this.getList()
        // this.exportList()
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        toDownload(row) {
            window.open(`${this.CommonPath}/${row.path}`, '_blank');
        },
        handleSelectionChange(val) {
            this.selectData = []
            this.selectData = val;
        },
        exportFile() {
            var _this = this
            if (_this.selectData.length == 0) {
                _this.$message({
                    type: 'error',
                    message: '请选择要导出的报告'
                });
                return
            }
            var ids = []
            this.selectData.forEach(element => {
                ids.push(element.id)
            });
            // _this.selfTestDomain.exportTypes(
            //     (res) => {
            //         _this.exportTypes=res.data.data
            //     },
            //     (error) => {
            //         console.log("请求失败!请刷新重试:", error);
            //     })
            _this.selfTestDomain.exportFile('PDF', ids,
                (res) => {
                    _this.$message({
                        type: 'success',
                        message: "导出中请稍后"
                    });
                    _this.exportList()
                },
                (error) => {
                    console.log("请求失败!请刷新重试:", error);
                })
        },
        exportList() {
            var _this = this
            _this.selfTestDomain.exportTasks(
                function (data) {
                    _this.exportTasks = res.data
                    var currentList = _this.exportTasks.filter(i => i.statu == 0)
                    _this.exportNum = currentList.length == 0 ? '' : currentList.length
                },
                function (error) {
                    console.log("请求失败!请刷新重试:", error);
                })
        },
        // getUserList() {
        //     var _this = this
        //     _this.selfTestDomain.MyselfAnswerInfo(_this.userId,
        //         (res) => {
        //             _this.userList = res.data
        //         },
        //         (error) => {
        //             console.log("请求失败!请刷新重试:", error);
        //         })
        // },
        toDetail(item) {
            this.dialogVisible = true
            // this.questionnaireDetermines = item.questionnaireDetermines.sort((a, b) => b.scores - a.scores);
            this.questionnaireDetermines = item.questionnaireDetermines;
        },
        // getQuestionnaire() {
        //     this.selfTestDomain.Questionnaire(
        //         (res) => {
        //             this.menuList = []
        //             var map = {
        //                 name: '全部',
        //                 id: '0'
        //             }
        //             this.menuList = res.data
        //             this.menuList.unshift(map)
        //         },
        //         (error) => {
        //             console.log("请求失败!请刷新重试:", error);
        //         }
        //     );
        // },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.selfTestDomain.getUserSelfDiagnosisRecord(_this.userId, item.startTime, item.endTime,item.keyword, _this.PageIndex, 
                function (data) {
                    _this.tableData = data.data.results
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                }, function (err) {
                    console.log(err)
                });

        },
        handleClick(tab, event) {
            this.tableData = []
            this.PageIndex = 1;
            this.getList();
        },
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },

        DetailBtn(row) {
            this.$router.push({
                name: "SalesDetail",
                query: {
                    commodityId: row.id
                }
            })
        }
    }
}
</script>

<style scoped>
.el-page-header {
    line-height: 73px;
    padding-left: 20px;
}
/* .box {
    position: fixed;
    right: 482px;
    top: 154px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    animation: move 1s forwards ease-out;
    z-index: 22;
}

@keyframes move {
    0% {
        transform: translate(0, 0)
    }

    25% {
        transform: translate(22.5px, -5px)
    }

    50% {
        transform: translate(45px, -10px)
    }


    75% {
        transform: translate(67.5px, -5px)
    }

    100% {
        transform: translate(90px, 0)
    }

} */

/*content*/


.table-top {
    margin-bottom: -10px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    /* width: 480px; */
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.table-img {
    position: relative;
    width: 200px;
    height: 120px;
    overflow: hidden;
}

.table-img img {
    width: 100%;
    height: auto;
}

.table-img p {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: rgba(0, 0, 0, .5);
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}

.table-info h4 {
    margin-bottom: 25px;
}

.table-info .el-row {
    margin-bottom: 15px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

.menu /deep/ .el-tabs {
    height: calc(100% - 50px);
}

.menu /deep/ .el-tabs__content {
    height: calc(100% - 55px);
}

.menu /deep/ .el-tab-pane {
    height: 100%;
}
</style>
